// ContactElements.js

import styled, { keyframes } from "styled-components";

const borderAnimation = keyframes`

  0% {
    border-color: #007bff;
    transform: scale(1);
  }
  25% {
    border-color: #28a745;
    border-radius: 30px;
  }
  50% {
    border-color: #ffc107;
    border-radius: 20px;
  }
  75% {
    border-color: #dc3545;
    border-radius: 20px;
  }
  100% {
    border-color: #007bff;
    border-radius: 20px;
  }
`;

export const StyledContactPage = styled.div`
  margin-left: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center horizontally */
  padding: 2rem;
  background-color: #fff;
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch; /* Ensures items stretch to full width */
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 2px solid #007bff;
  animation: ${borderAnimation} 5s infinite linear;

  h1 {
    margin-bottom: 1.5rem;
    color: #007bff;
  }

  p {
    font-weight: bold;
  }

  p.error {
    color: red;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  padding-right: 1%;
  text-align: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 500px;
  order: 1; /* Push to the left on larger screens */
  animation: ${borderAnimation} 5s infinite linear; /* Apply animation */
`;

export const ContactImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 2px solid #007bff;
  animation: ${borderAnimation} 5s infinite linear;

  h1 {
    margin-bottom: 1.5rem;
    color: #007bff;
  }

  p {
    font-weight: bold;
  }

  p.error {
    color: red;
  }

  @media (min-width: 768px) {
    width: calc(50% - 1rem);
    order: 2; /* Push to the right on larger screens */
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Label = styled.label`
  text-align: left;
  font-weight: bold;
  color: #555;
`;

export const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

export const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

export const Button = styled.button`
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;

  p {
    font-size: 1.2rem;
    font-weight: bold;
    color: green;
  }
`;

export const CustomerSupportInfo = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;

  p {
    margin: 0.5rem 0;
  }
`;


import { styled } from "styled-components";
import { PrimaryBtn } from "../commonComponents";

export const HomeContainer = styled.div`
  width: 60%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  /* padding-top: 2rem; */
  column-gap: 2rem;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    row-gap: 2rem;
    padding: 0;
  }
`;

export const HeroHeading = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  text-transform: capitalize;
  margin-bottom: 16px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    row-gap: 2rem;
  }
`;

export const HeroSubHeading = styled.h3`
  color: ${(props) => props.theme.colors.heading};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const SearchInput = styled.input`
  color: #606060;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 14px 28px;
  width: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
  border: none;

  &:focus {
    outline: 1px solid;
    outline-color: ${(props) => props.theme.colors.primary};
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 14px 8px;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 600px;
  margin-top: 4rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const SearchBtn = styled(PrimaryBtn)`
  padding: 10px 26px;
  position: absolute;
  right: 12px;
  margin: 4px 0;
  font-size: 18px;
`;

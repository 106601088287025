import { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const isLogged = localStorage.getItem('token');
  const [auth, setAuth] = useState({
    isLogged: !!isLogged,
    isAuthModalOpen: false,
  });

  const updateAuth = (newData) => {
    setAuth(newData);
  };

  return (
    <AuthContext.Provider value={{ auth, updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

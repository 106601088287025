import React from 'react';
import {
  CustomOutlinedBtn,
  Price,
  SlideBox,
  SlideImage,
  SlideImageContainer,
  SliderHeading,
} from '../VideoConsultationElements';

function SessionCard({ slide, handleDepartmentSelection }) {
  return (
    <SlideBox>
      <SlideImageContainer>
        <SlideImage src={slide.image} alt={slide.department} />
      </SlideImageContainer>
      <SliderHeading>{slide.department}</SliderHeading>
      <Price>&#8377;{slide.price}</Price>
      <CustomOutlinedBtn
        onClick={() => {
          handleDepartmentSelection(slide.id);
        }}
      >
        Consult Now
      </CustomOutlinedBtn>
    </SlideBox>
  );
}

export default SessionCard;

import React from "react";
import { Table, Th, Td, Button } from "./DoctorListElement";
const DoctorList = ({ doctors, onDeleteDoctor, onApproveDoctor }) => {
  return (
    <div className="doctor-list">
      <h2>Manage Doctors</h2>
      <Table>
        <thead>
          <tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th>Approval Status</Th>
            <Th>ID Card</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {doctors.map((doctor) => (
            <tr key={doctor.id}>
              <Td>{doctor.id}</Td>
              <Td>{doctor.name}</Td>
              <Td>{doctor.approved ? "Approved" : "Not Approved"}</Td>
              <Td>
                <a
                  href={doctor.idCardUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View ID Card
                </a>
              </Td>
              <Td>
                <Button onClick={() => onDeleteDoctor(doctor.id)}>
                  Delete
                </Button>
                {!doctor.approved && (
                  <Button onClick={() => onApproveDoctor(doctor.id)}>
                    Approve
                  </Button>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DoctorList;

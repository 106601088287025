import React, { useState, useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import videoConsultationImage from '../../images/v2/banner-online-consult.png'
import Footer from '../Footer/Footer';

import {
  imageStyles,
  StyledHeading,
  StyledParagraph,
  Question,
  QuestionContent,
  DropdownIcon,
  Answer,
  FAQContainer

} from "./AboususpagesElements";
import {ImageContainer , StyledButton} from "../NewOurServices/NewServicesElements.js"


const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

function VideoConsultationPage() {
  return (
    <div>
       <Helmet>
        <title>Online Doctor Consultation - Consult Top Doctors Online 24/7</title>
        <meta name="description" content="Online doctor consultation with highly qualified doctors across 1500+ specialties with Healthko. Ask top doctor’s online for medical issues." />
      </Helmet>
       <ImageContainer>
      <img src={videoConsultationImage} alt="Video Consultation" />
      <div className="text-overlay">
      <p className="subtitle">Home is where health starts</p>
        <h1>Book Online Doctor Consultation</h1>
        <p>Private audio + Video consultation · Starts at just ₹99</p>
        <StyledButton>Consult Now</StyledButton>
      </div>
    </ImageContainer>
      <StyledHeading>About Online Doctor Consultation</StyledHeading>
      <StyledParagraph>
      HealthKo assists in offering the access to the top qualified doctors through online doctor consultations, with the help of AI technology, in the easiest and simplest way of healthcare. You can easily connect with a qualified medical professional through a simple click of a button from your own home. The AI technology we have developed makes our platform more stable and easy to use thus providing a one-to-one healthcare experience.
      </StyledParagraph>
      <StyledParagraph>The HealthKo approach is one of the main advantages that is based on the fact that it gives a chance to the individuals in remote areas to get a good healthcare service which is not available in traditional medical care. Regardless of the fact that you are in a crowded city or in a remote village, you can get expert medical advice and treatment without having to travel long distances or to stay in long queues for your appointments.</StyledParagraph>
      <StyledParagraph>Besides, HealthKo utilises the strongest encryption protocols and the most secure data security measures to shield the sensitive medical information, thus, the patients privacy and security are given priority. The promise to privacy can be the sign of the fact that people can get medical help in relaxation, knowing that their personal health information is handled with professionalism and with confidentiality.</StyledParagraph>
      <StyledParagraph>HealthKo also provides the customers with the complete healthcare facts which enables them to ask doctors online, book the appointments at hospitals or clinics and that if it's to book a healthcare professional at home. A person can get all healthcare services at his home and with his family. This whole technique of the person's health and well-being is the key to the individual to make some necessary changes to improve his or her health.</StyledParagraph>
      <StyledParagraph>HealthKo is a platform that is made to allow faster collaboration and sharing of understanding among healthcare professionals, thus making it possible for multidisciplinary groups to work together remotely and give the patients with tricky clinical needs comprehensive care. Through this, people will surely get appropriate health plans that are coherent and that are supported by concrete evidence - plans made to their particular circumstances.</StyledParagraph>
      <StyledParagraph>Although we keep on seeing that the technology is ever changing and advancing, HealthKo never stops being committed to making use of inventions to improve the accessibility, affordability, and great healthcare. Through online consultations between sufferers and doctors with AI, we connect the gaps that exist between the two and consequently, we enable people to take charge of their health and stay in their exceptional lives.</StyledParagraph>
      <StyledHeading>Frequently asked questions</StyledHeading>
      <FAQContainer>
      <DropdownItem
        question="What is online doctor consultation?"
        answer="Online doctor consultation is a process of obtaining medical advice and treatment via online platforms like phone calls or video calls."
      />
      <DropdownItem
        question="How do I begin an online consultation with doctors on Healthko?"
        answer={
          <>
           Booking an online doctor consultation on HealthKo is very easy. Just follow these four simple steps:
              
            <ol>
              <li>Contact us by visiting our website or downloading the HealthKo app for online consultation.</li>
              <li>Select your health concern.</li>
              <li>Connect with a doctor in under 2 minutes.</li>
              <li>Discuss your queries with the doctor via audio or video call.</li>
              <li>Receive a valid online doctor prescription.</li>
            </ol>
          </>
        }
      />
      <DropdownItem
        question="How quickly can I connect with a doctor for online Consultation?"
        answer="Healthko ensures easy and quick access for online doctor consultation. You can consult doctor online within 2 minutes of initiating the consultation process."
      />
      <DropdownItem
        question="Can I ask questions during the online consultation?"
        answer="Absolutely! Healthko encourages patients to ask questions and discuss their concerns with the doctor during the online consultation via audio or video call. This ensures a thorough understanding of your health condition and treatment plan."
      />
      <DropdownItem
        question="Will I receive a valid prescription after online medical consultation?"
        answer="Yes, upon completion of online medical consultation, you will receive a valid online doctor prescription from doctors. This prescription can be used to obtain necessary medications from pharmacies."
      />
      <DropdownItem
        question="Is my online medical consultation secure?"
        answer="Healthko prioritises the privacy and security of your information. All online medical consultations are conducted through encrypted channels and are strictly confidential to protect the data."
      />
      </FAQContainer>
      
       <Footer />
      
    </div>
  );
}

export default VideoConsultationPage;

import styled from 'styled-components';
import leftBlueIcon from '../../images/v1/slider/left-blue.svg';
import leftWhiteIcon from '../../images/v1/slider/left-white.svg';
import rightBlueIcon from '../../images/v1/slider/right-blue.svg';
import rightWhiteIcon from '../../images/v1/slider/right-white.svg';

export const ReactSliderContainer = styled.div`
  position: relative;
`;

export const SliderBtn = styled.div`
  position: absolute;
  border-radius: 50%;
  left: -3rem;
  top: calc(50% - 20px);
  padding: 0.2rem;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${leftWhiteIcon});

  &:hover {
    background-image: url(${leftBlueIcon});
  }
`;

export const SliderBtnNext = styled(SliderBtn)`
  right: -3rem;
  left: unset;

  background-image: url(${rightWhiteIcon});

  &:hover {
    background-image: url(${rightBlueIcon});
  }
`;

export const SliderBox = styled.div`
  margin: 0 auto;
  width: 100%;
  color: ${(props) => props.theme.colors.heading};
`;

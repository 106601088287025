import React, { useEffect, useRef, useState } from 'react';
import {
  generateNextNDays,
  getCurrentDay,
  getMonth,
} from '../../util/timeFunctions';
import { SlideContainer } from './DateSlotsElements';
import { multipleSliderSettings } from '../../constants/constants';
import ReactSlider from '../ReactSlider/ReactSlider';
import { SliderConatiner } from '../commonComponents';
const settings = {
  ...multipleSliderSettings,
  slidesToShow: 10,
  slidesToScroll: 2,
  infinite: false,
  speed: 500,
};

const Slide = (props) => {
  const dateArr = props?.date.split(' ');

  let style = {};
  if (props.selected)
    style = {
      color: '#fff',
      backgroundColor: '#009FFD',
    };

  return (
    <SlideContainer style={style}>
      {dateArr[0]} {dateArr[2]}
    </SlideContainer>
  );
};

const DateSlider = ({
  setSelectedMonth,
  setSelectedDate,
  selectedDate,
  setSelectedDay,
}) => {
  const [slides, setSlides] = useState([]);
  const [timer, setTimer] = useState(null);
  const monthRef = useRef(1);

  const handleNextSlide = () => {
    const slidesArray = [...slides];
    const newSlides = generateNextNDays(slidesArray[slidesArray.length - 1], 5);
    setSlides([...slidesArray, ...newSlides]);
    setSelectedMonth(getMonth(new Date(slidesArray[monthRef.current])));
    monthRef.current = monthRef.current += 2;
  };

  useEffect(() => {
    if (slides.length === 0) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const dates = generateNextNDays(new Date(yesterday), 20);
      setSlides(dates);
      setSelectedMonth(getMonth(today));
      setSelectedDate(dates[0]);
      setSelectedDay(getCurrentDay(today));
    }
    return () => {
      setSlides([]);
    };
  }, []);

  const handleBtnClick = () => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      handleNextSlide();
    }, 600);
    setTimer(newTimer);
  };

  const onPrev = () => {
    if (monthRef.current < 2) return;
    const slidesArray = [...slides];
    monthRef.current = monthRef.current -= 2;
    setSelectedMonth(getMonth(new Date(slidesArray[monthRef.current])));
    setSelectedDay(getCurrentDay(new Date(slidesArray[monthRef.current])));
  };

  const handleSlideCLick = (date) => {
    setSelectedMonth(getMonth(new Date(date)));
    setSelectedDate(date);
    setSelectedDay(getCurrentDay(new Date(date)));
  };

  return (
    <div>
      <SliderConatiner>
        <ReactSlider
          onNext={handleBtnClick}
          onPrev={onPrev}
          settings={settings}
        >
          {slides.map((slide) => {
            return (
              <div key={slide} onClick={() => handleSlideCLick(slide)}>
                <Slide date={slide} selected={selectedDate === slide} />
              </div>
            );
          })}
        </ReactSlider>
      </SliderConatiner>
    </div>
  );
};

export default DateSlider;

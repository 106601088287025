import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import {NavLink  } from 'react-router-dom';
import { Link} from 'react-router-dom';


export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.heading};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  text-transform: capitalize;
  padding-bottom: 5px;
  cursor: pointer;
  
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  
  &.active {
    border-bottom: 3px solid;
    border-color: ${(props) => props.theme.colors.primary};
   
  }
`;
export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.heading};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  text-transform: capitalize;
  padding-bottom: 5px;
  cursor: pointer;
  
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  
  &.active {
    border-bottom: 3px solid;
    border-color: ${(props) => props.theme.colors.primary};
  
  }
`;



export const Nav = styled.nav`
  background: #fff;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 08s all ease;
  }
`;

export const BlogsLink = styled.div`
  color: ${(props) => props.theme.colors.heading};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  padding-bottom: 5px;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;


export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    width: 55%;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 1rem;
  z-index: 1;
  width: 100%;
  max-width: 1334px;

  @media screen and (max-width: 768px) {
    height: 80px;
  }
`;

export const NavLogo = styled(LinkRouter)`
  color: #000000;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000000;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 52px;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 900px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinks = styled(LinkScroll)`
  color: ${(props) => props.theme.colors.heading};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  &.active {
    border-bottom: 3px solid;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export const NavBtnContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NotificationContainer = styled.div`
  position: relative;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
  font-size: 34px;
`;

export const Notification = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  padding: 2px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  border-radius: 4px;
`;

export const UserProfileContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const UserProfile = styled.div`
  border-radius: 100%;
  text-align: center;
  color: #fff;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 14px;
  cursor: pointer;
`;

export const UserName = styled.p`
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const UserLoggedIn = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  position: relative;

  @media screen and (max-width: 768px) {
    column-gap: 1rem;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  background-color: #6eb9fa;
  height: fit-content;
  width: MIN(349px, 100%);
  right: 0;
  top: 150%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 14px 1.5rem;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  padding: 10px 0;
`;
export const Item = styled.p`
  color: ${(props) => props.theme.colors.heading};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const MenuIcon = styled.img`
  object-fit: contain;
  width: 1.4rem;
  height: 1.4rem;
`;
export const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  width: 100%;
  z-index: 10;
`;

export const DropdownItem = styled.div`
  align-items: center;
  padding: 1px;
  h3 {
    
    font-size: 20px; 
   
  }
  img{
    width: 250px;
  }
`;
export const StyledIcon = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 2px;
  color: #000; // Customize the color as needed
  font-size:15px; // Customize the size as needed
`;
// export const GooglePlayIconContainer = styled.div`
//   display: flex;
//   align-items: center; 
//   justify-content: center; 
//   cursor: pointer;
// `;

// export const GooglePlayIconImage = styled.img`
//   width: 15%; 
//   padding-right: 20px;
//   padding-bottom: 20px;
// `;

// export const IosStoreIconContainer = styled.div`
//   display: flex;
//   align-items: center; 
//   justify-content: center; 
//   cursor: pointer;
// `;

// export const IosStoreIconImage = styled.img`
//   width: 15%; 
//   padding-right: 20px;
//   padding-bottom: 20px;
// `;

export const IconRow = styled.div`
  display: flex;
  justify-content: center; 
`;

export const IconContainer = styled.div`
  margin-right: 10px; 
`;

// Styled components for Google Play Store icon
export const GooglePlayIconContainer = styled.div`
  cursor: pointer;
`;

export const GooglePlayIconImage = styled.img`
  width: 100px;
`;

// Styled components for iOS App Store icon
export const IosStoreIconContainer = styled.div`
  cursor: pointer;
`;

export const IosStoreIconImage = styled.img`
  width: 100px;
  
`;

import React from "react";
import { Container, Paragraph, SectionHeading } from "../commonComponents";
import {
  CustomContainer,
  CustomPrimaryBtn,
  SectionSubHeading,
  SlideBody,
  SlideContainer,
  SliderSection,
  CustomImage
} from "./OurServicesElements";
import { sliderSettings, slides } from "../../constants/constants";
import ReactSlider from "../ReactSlider/ReactSlider";
import { Link } from "react-router-dom";

function Slides() {
  return (
    <ReactSlider settings={sliderSettings}>
      {slides.map((slide, idx) => {
        return (
          <SlideContainer key={idx}>
            <SlideBody>
              {/* <SectionSubHeading>{slide.title}</SectionSubHeading> */}
              {/* <Paragraph>{slide.body}</Paragraph> */}
             
              <Link to={slide.link}>
              {slide.image && <CustomImage  src={slide.image} alt={slide.title} />}
                {/* <CustomPrimaryBtn>Read More</CustomPrimaryBtn> */}
              </Link>
            </SlideBody>
          </SlideContainer>
        );
      })}
    </ReactSlider>
  );
}

export default function OurServices() {
  return (
    <Container id="about">
      <CustomContainer>
        <SectionHeading>About Us</SectionHeading>
        <SliderSection>
          <Slides />
        </SliderSection>
      </CustomContainer>
    </Container>
  );
}

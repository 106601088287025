import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs3 from "../../images/blogsimage/HealthKoBlogs3.jpg";

const PostPage3 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect (() => {
    document.title = ' Constipation: Causes, Symptoms, and Effective Treatments';
    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };
  
    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };
  
    setLinkTag('blogs', 'https://www.healthko.in/Constipation-Causes-Symptoms-and–Treatments');

    setMetaTag('description', 'Discover the causes, symptoms, and effective treatments for constipation. Learn how dietary and lifestyle changes can help manage and prevent this common issue.');

  }, []);
  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>Constipation: Causes, Symptoms, and Effective Treatments</h1>
        <p>Constipation is one of the most prevalent gastrointestinal diseases that can occur in adults as well as in children. Knowing the cause, signs, and how to cure or prevent this condition can go a long way in dealing with it.</p>
        <img src={HealthKoBlogs3} alt="Constipation: Causes, Symptoms, and Treatments" />
      
        <h2>What is Constipation?</h2>
        <p>Constipation is the situation where one is unable to pass stool or do so rarely. Specifically, bowel movements less than three in a week are considered as constipation. Today constipation is one of the most widely spread diseases affecting millions of people all over the world. It is more common in females, patients of an older age group, and individuals with any <a href="https://en.wikipedia.org/wiki/Comorbidity"target="_blank" rel="noopener noreferrer">comorbidities</a>.</p>

        <h2>Causes of Constipation</h2>
        <h2>●  Dietary Factors: </h2>
        <p> Some of the common causes of constipation include fibre but not limited to include low fibre diet. Fibre also tends to contribute to bulk within the stools and aids in their passage through the intestines. Through research, it was also realised that lack of water also results in difficulty in defecation since the faeces usually become hard and dry.</p>
        <h2>●  Lifestyle Factors: </h2>
        <p>To some extent, excess sitting can hinder bowel movements and result in constipation. Exercise also helps to promote normal functioning of the intestines because it is a proven fact that regular movement enhances the intestinal movement. Constipating also arises from the ability to postpone defecation for a long time, in other words, ignoring this urge.</p>
        <h2>●  Medical Conditions:</h2>
        <p>Some diseases, for instance  <a href="https://www.niddk.nih.gov/health-information/digestive-diseases/irritable-bowel-syndrome"target="_blank" rel="noopener noreferrer">Irritable bowel syndrome (IBS)</a>, diabetes, thyroids etc., lead to constipation. Different medicines like pain killers, anti- inflammatory drugs and  <a href="https://www.medicalnewstoday.com/articles/248320#types"target="_blank" rel="noopener noreferrer">antidepressants</a> can also cause constipation.</p>

        <h2>Symptoms of Constipation</h2>
        <h2>●  Common Symptoms:</h2>
        <p>Some of the more recognized signs of  <a href="https://www.mayoclinic.org/diseases-conditions/colon-cancer/symptoms-causes/syc-20353669"target="_blank" rel="noopener noreferrer"> colon cancer </a>consist of less than three bowel movements per week, hard or lumpy stools, constipation or the act of straining during bowel movements. This is also accompanied by an uncomfortable feeling that the bowels have not been emptied fully.</p>
        <h2>●  Severe Symptoms:</h2>
        <p> Moderate symptoms may include breathing difficulty, chest pains, and blurred vision. There are consequences of constipation such as piles, cracks around the anus, and fecaloma respectively.</p>
        <h2>Treatment for Constipation</h2>
        <h2>●  Dietary Changes: </h2>
        <p>Those who had constipation noted that with the consumption of more fibres in the form of fruits, vegetables, whole grain foods and legumes could help to effectively treat this ailment. Like stated earlier, water is very necessary to aid the passage of fibre and also ensures that one drinks enough water as well.</p>
        <h2>●  Lifestyle Modifications:</h2>
        <p>Well, if people engage themselves in physical exercise on a regular basis, then this actually has a colonic impact in terms of making bowel movements regular. It also means maintaining ablution regularity as well as a bathroom schedule and regularity of defecation, read: DO NOT reject the need to defecate.</p>
        <h2>●  Medications: </h2>
        <p>Self care measures like using fibre supplements,osmotic laxatives, stimulant laxatives can be effective. If not prescribed under the guidance of a healthcare provider, they should not be taken.</p>
        <h2>When to See a Doctor</h2>
        <p>If constipation does not improve after the above methods have been used, then it is advisable to seek medical advice. In some cases, symptoms may persist, and this might be a sign the patient has an ailment that needs the attention of a doctor.</p>
        <h2>Preventing Constipation</h2>
        <h2>●  Long-term Dietary Habits:</h2>
        <p>Reform through dietary changes is one way in which fibrosis can be prevented in the long-term, and integral to this is the consumption of  <a href="https://www.niddk.nih.gov/health-information/digestive-diseases/constipation/eating-diet-nutrition"target="_blank" rel="noopener noreferrer">high fibre diets</a>.. Supplementation of diet with preferably different types of fibre containing foods will help maintain a healthy digestion.</p>
        <h2>●  Regular Exercise:</h2>
        <p>Physical activity keeps bowel movements in order, thus should be considered by everyone. This can be simple things as walking; little adjustments can make a huge and positive impact.</p>
        <h2>●  Hydration:</h2>
        <p>Especially water must be taken sufficiently, as it is vital for the human body. Staying well-hydrated makes the bowel movements to be soft hence be easier to push through the rectum.</p>
        <h1>Conclusion,</h1>
        <p>In fact constipation is fully curable through aspects such as dieting and taking necessary changes in consumer routines. On the other hand, if the parameters indicating the state of the body remain slightly different for an extended time, it is essential to  <a href="https://www.healthko.in/Online-Doctor-Consultation"target="_blank" rel="noopener noreferrer">consult a doctor</a>.. The purpose of this article is thus to give a detailed account of constipation with the view of enabling a person to have the right knowledge in preventing or handling the situation in the right manner whenever it happens.</p>
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage3;

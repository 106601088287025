import styled from "styled-components";

export const BlogPostStyles = styled.div`
  margin-bottom: 40px;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 25px;

  h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 15px;
    color: #333;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
  }

  p {
    font-size: 16px;
    line-height: 1.8;
    color: #444;
    margin-bottom: 25px;
  }

  h2 {
    font-weight: 300;
    font-size: 26px;
    margin-bottom: 15px;
    color: #333;
  }

  ol {
    list-style-type: decimal;
    padding-left: 30px;
    margin-bottom: 20px;
  }

  ol li {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }

  h4 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
`;

export const StyledPostPage = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const CommentForm = styled.form`
  margin-top: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    background: #fafafa;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
    
    input {
      margin-right: 10px;
    }
  }

  button {
    background-color: #469cf6;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #3f8cff;
      box-shadow: 0 4px 10px rgba(63, 140, 255, 0.4);
    }
  }
`;

export const SocialMediaContainer = styled.div`
  text-align: right;
  margin-bottom: 20px;
  padding-right: 20px;

  & > * {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.3s;
  }

  & > *:hover {
    transform: scale(1.1);
  }
`;

export const SubscriptionContainer = styled.div`
  padding-top: 50px;
  flex: 1;

  @media screen and (max-width: 768px) {
    display: none; /* Hide the container on smaller screens */
  }
`;

export const SubscriptionContent = styled.div`
  background: #ffffff;
  padding: 25px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const SubscriptionTitle = styled.h2`
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
`;

export const SubscriptionForm = styled.form`
  input[type="email"] {
    margin-right: 10px;
    padding: 12px;
    width: 60%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fafafa;
  }

  button[type="submit"] {
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }

  button[type="submit"]:hover {
    background-color: #e60000;
  }

  @media screen and (max-width: 768px) {
    input[type="email"] {
      width: 100%;
      margin-bottom: 15px;
    }

    button[type="submit"] {
      width: 100%;
    }
  }
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContainer,
  ProfileImage,
  Name,
  Money,
  ConsultButton,
  StyledArrowIcon
} from './SepecialistElements';

const SpecialistCard = ({ review }) => {
  const { name, image, money, color1, color2, color3, link } = review;
  const navigate = useNavigate();

  // const handleConsultClick = (e) => {
  //   e.preventDefault();
  //   navigate(link);
  // };
  const handleConsultClick = (e) => {
    e.preventDefault();
    if (link.startsWith("http")) {
      window.open(link, "_blank", "noopener,noreferrer");
    } else {
      navigate(link);
    }
  };

  return (
    <CardContainer>
      <ProfileImage color1={color1} color2={color2} color3={color3}>
        <img src={image} alt={name} />
      </ProfileImage>
      <Name>{name}</Name>
      <Money>{money}</Money>
      <ConsultButton onClick={handleConsultClick}>
        Consult Now <StyledArrowIcon /> {/* Ensure StyledArrowIcon is properly imported and styled */}
      </ConsultButton>
    </CardContainer>
  );
};

export default SpecialistCard;

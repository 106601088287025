import React, { useState, useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import videoConsultationImage from '../../images/v2/banner-doc-at-home.png';
import calendarImage from '../../images/v2/date.png';
import doctorVisitImage from '../../images/v2/emergency.png'; 
import Footer from '../Footer/Footer.jsx';
import { Link } from 'react-router-dom';

import {
  imageStyles,
  StyledHeading,
  Question,
  QuestionContent,
  DropdownIcon,
  Answer,
  FAQContainer,
  ImageContainer,
  StyledButton,
  CardContainer,
  ButtonsContainer,
  BookByDateButton,
  CalendarImage,
  DoctorVisitButton,
  DoctorVisitImage
} from "./NewDoctorElements.js";

import BenifitOnlineConsultation from './BenifitOnlineConsultation.jsx';

const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

const NewDoctorVisitHome = () => {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);


  const handleAppointmentClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ahsclient.app', '_blank'); // Opens appointment page
  };

  return (
    <div>
      <ImageContainer>
        <img src={videoConsultationImage} alt="Doctor Home Visit" />
        <div className="text-overlay">
          <p className="subtitle">India's best Doctor Consultation at Home</p>
          <h1>Expert Doctor Visit At Home</h1>
          <p>Personalized Care + Priority Access · Starts at just ₹99</p>
          <StyledButton onClick={handleAppointmentClick}>Book Appointment</StyledButton>
        </div>
      </ImageContainer>
      
      <CardContainer>
        <StyledHeading>Select Type of visit</StyledHeading>
      
        <ButtonsContainer>
          <BookByDateButton as={Link} to="https://play.google.com/store/apps/details?id=com.ahsclient.app" target="_blank" rel="noopener noreferrer">
            <CalendarImage src={calendarImage} alt="Calendar" />
            Book by Date
          </BookByDateButton>
      
          <DoctorVisitButton as={Link} to="https://play.google.com/store/apps/details?id=com.ahsclient.app" target="_blank" rel="noopener noreferrer">
            <DoctorVisitImage src={doctorVisitImage} alt="Doctor Visit" />
            Immediate Visit
          </DoctorVisitButton>
        </ButtonsContainer>

        <h2>No date selection</h2>
        <p>Please select date or immediate doctor visit from above</p>
      </CardContainer>
      
      <BenifitOnlineConsultation />

      <StyledHeading>Frequently asked questions</StyledHeading>
   
      <FAQContainer>
        <DropdownItem
          question="What is a doctor visit at home?"
          answer="A doctor visit at home involves a healthcare professional coming to your home to provide medical care, diagnosis, and treatment, ensuring convenience for patients who may have difficulty travelling to a clinic or hospital."
        />
        <DropdownItem
          question="Who can benefit from a doctor consultation at home?"
          answer="Anyone who is unable to visit a medical facility due to illness, injury, mobility issues, or other issues can benefit from a doctor consultation at home. This service is particularly helpful for elderly individuals, people with disabilities, or those who have busy schedules."
        />
        <DropdownItem
          question="How can I schedule a doctor visit at home with HealthKo?"
          answer={
            <ol>
              <p style={{ marginBottom: '1rem' }}>Scheduling a doctor visit at home with Healthko is very easy and simple just follow these steps:</p>
              <li>Select your preference time and date.</li>
              <li>Click on reserve slot.</li>
              <li>We will assign top healthcare professionals.</li>
              <li>Provide basic details and address.</li>
            </ol>
          }
        />
        <DropdownItem
          question="What services are typically offered during a home visit?"
          answer="During a home visit, doctors can provide a wide range of medical services including physical examinations, medication management, wound care, diagnostic tests, vaccinations, and counselling on managing chronic conditions."
        />
        <DropdownItem
          question="Can I request a specific doctor for a home visit?"
          answer="Depending on the availability and the policies of the healthcare provider or service, you may be able to request a specific doctor for your home visit, ensuring continuity of care and familiarity with your medical history."
        />
        <DropdownItem
          question="How do I prepare for a doctor visit at home?"
          answer="To prepare for a doctor visit at home, ensure that your living space is accessible and has good lighting, have your medical history and any relevant documents ready, and make a list of any questions or concerns you wish to discuss with the doctor."
        />
        <DropdownItem
          question="What should I expect during and after the visit?"
          answer="During the visit, the doctor will conduct an examination, provide necessary treatment or recommendations, and discuss follow-up care if needed. After the visit, you should follow the instructions provided by the healthcare professional and reach out if you have any further questions or concerns."
        />
      </FAQContainer>
 
      <Footer />
    </div>
  );
}

export default NewDoctorVisitHome;

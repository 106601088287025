import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { animateScroll as scroll, Link as ScrollLink, scroller } from "react-scroll";
import { IoMdNotificationsOutline } from "react-icons/io";
import Logo from "../../images/v1/logo.svg";
import GooglePlayIcon from "../../images/v1/Googleplaystore.png";
import OurDoctorImg from "../../images/v1/OurDoctor.png";
import IosStoreIcon from "../../images/v1/IosStore.png";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavBtnContainer,
  Img,
  NotificationContainer,
  Notification,
  UserProfileContainer,
  UserProfile,
  UserName,
  UserLoggedIn,
  MenuContainer,
  Item,
  MenuItem,
  MenuIcon,
  StyledLink,
  DropdownList,
  DropdownItem,
  GooglePlayIconImage,
  GooglePlayIconContainer,
  IosStoreIconContainer,
  IosStoreIconImage,
  IconContainer,
  IconRow,
  StyledNavLink,
  StyledIcon
} from "./NavbarElements";
import { OutlinedBtn } from "../commonComponents";
import Modal from "../Modal/Modal";
import SettingsModal from "./SettingsModal";
import { menuItems } from "../../constants/constants";
import { useAuthContext } from "../../hooks/AuthContextHook";
import { Axios } from "../../util/ApiEndpoint";

function Menu({ name, Icon, handleMenuOpen }) {
  return (
    <MenuItem onClick={() => handleMenuOpen(name)}>
      <MenuIcon src={Icon} alt={name} />
      <Item>{name}</Item>
    </MenuItem>
  );
}

const Navbar = ({ toggle, openSignup, openLogin }) => {
  const [isOurDoctorsOpen, setIsOurDoctorsOpen] = useState(false);
  const { auth } = useAuthContext();
  const menuRef = useRef(null);
  const [data, setData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuClicked, setMenuClicked] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [activeSection, setActiveSection] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const toggleOurDoctors = () => {
    setIsOurDoctorsOpen(!isOurDoctorsOpen);
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const handleModalClose = () => {
    setMenuClicked("");
  };

  const handleGooglePlayClick = (event) => {
    event.preventDefault();
    window.open ( "https://play.google.com/store/apps/details?id=com.HealthKoNew.Doc2", "_blank"); 
  };

  const handleIosStoreClick = (event) => {
    event.preventDefault();
    alert("Coming Soon");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "Bearer " + localStorage.getItem("token");
        const response = await Axios.get(`/users/${userId}`, {
          headers: {
            Authorization: token,
          },
        });
        const result = response.data;
        setData(result);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [userId, auth.isLogged]);

  useEffect(() => {
    // Extract the section from the pathname
    const section = location.pathname.substring(1);
    // Set the active section based on the pathname
    setActiveSection(section);
  }, [location]);

  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  const handleNavClick = (sectionId) => {
    if (location.pathname === "/") {
      scroller.scrollTo(sectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    } else {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(sectionId, {
          duration: 500,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -80,
        });
      }, 100); // Adjust delay as needed
    }
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <Img src={Logo} width="60" height="60" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <li>
            <StyledNavLink
                  as="div"
                  className={activeSection === "services" ? "active" : ""}
                  onClick={() => {
                    setActiveSection("services");
                    handleNavClick("services");
                  }}
                >
                  Our Services
                </StyledNavLink>
            </li>
            <li>
            <StyledNavLink
                    as="div"
                    className={activeSection === "about" ? "active" : ""}
                    onClick={() => {
                      setActiveSection("about");
                      handleNavClick("about");
                    }}
                  >
                    About Us
                  </StyledNavLink>
            </li>
            <li>
            <StyledNavLink to="/Contact-Us">Contact Us</StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/blogs">Blogs</StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/Team_Member">Team</StyledNavLink>
            </li>
            <li>
              <div className="our-doctors">
                <StyledLink onClick={toggleOurDoctors}>
                  For Doctors
                  <StyledIcon>
                    {isOurDoctorsOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </StyledIcon>
                </StyledLink>
                {isOurDoctorsOpen && (
                  <DropdownList ref={menuRef}>
                    <DropdownItem>
                      <h3>
                        Free registration for doctors keen on sharing their expertise with millions of patients in need.
                        Join now!
                      </h3>
                      <img src={OurDoctorImg} alt="Doctor" />
                      <IconRow>
                        <IconContainer>
                          <GooglePlayIconContainer onClick={handleGooglePlayClick}>
                            <GooglePlayIconImage src={GooglePlayIcon} alt="Google Play Store Icon" />
                          </GooglePlayIconContainer>
                        </IconContainer>
                        <IconContainer>
                          <IosStoreIconContainer onClick={handleIosStoreClick}>
                            <IosStoreIconImage src={IosStoreIcon} alt="iOS App Store Icon" />
                          </IosStoreIconContainer>
                        </IconContainer>
                      </IconRow>
                    </DropdownItem>
                  </DropdownList>
                )}
              </div>
            </li>
          </NavMenu>
          {!auth.isLogged ? (
            <NavBtnContainer>
              <OutlinedBtn onClick={openLogin}>Login/Sign up</OutlinedBtn>
            </NavBtnContainer>
          ) : (
            <UserLoggedIn>
              <NotificationContainer>
                <IoMdNotificationsOutline />
                <Notification>11</Notification>
              </NotificationContainer>
              <UserProfileContainer>
                <UserProfile>
                  {data?.data?.name ? data?.data?.name[0].toUpperCase() : "User"}
                </UserProfile>
                <UserName onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  {data?.data?.name}
                  {isMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
                </UserName>
                {isMenuOpen && (
                  <MenuContainer ref={menuRef}>
                    {menuItems.map((menu) => (
                      <div key={menu.name} onClick={() => setIsMenuOpen(false)}>
                        <Menu {...menu} handleMenuOpen={setMenuClicked} />
                      </div>
                    ))}
                  </MenuContainer>
                )}
              </UserProfileContainer>
            </UserLoggedIn>
          )}
        </NavbarContainer>
      </Nav>

      <Modal isOpen={menuClicked === "Settings"} closeModal={handleModalClose} label={"Settings"}>
        <div>
          <SettingsModal handleModalClose={handleModalClose} />
        </div>
      </Modal>
    </>
  );
};

export default Navbar;

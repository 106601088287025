import React, { useState , useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import doctorConsultationImage from '../../images/v2/banner-doc-at-home.png'
import Footer from '../Footer/Footer';

import {
  imageStyles,
  StyledHeading,
  StyledParagraph,
  Question,
  QuestionContent,
  DropdownIcon,
  Answer,
  FAQContainer,
  ImageContainers
} from "./AboususpagesElements";
import { StyledButton} from "../NewClinicAppointment/NewServicesElements.js"


const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

function DoctorAtHomePage() {
  return (
    <div>
       <ImageContainers>
      <img src={doctorConsultationImage} alt="Video Consultation" />
      <div className="text-overlay">
      <p className="subtitle">Experience India's Best Doctor Consultations at Home</p>
        <h1>Expert Doctor Visit At Home</h1>
        {/* <p>Private audio + Video consultation · Starts at just ₹99</p> */}
        <StyledButton>Schedule a Home Visit</StyledButton>
      </div>
    </ImageContainers>
      <StyledHeading>About Doctor Home Visit</StyledHeading>
        <StyledParagraph>HealthKo is a company where convenience and well-being are the core. Elimination of stress for scheduling of the appointments and rushing to the doctor's office is a goodbye. Thus, it is better to go to health care that is according to your schedule, your comfort and your peace of mind. The objective is to smoothen every stage of your recuperation and make it as stress-free as possible, so that you can devote your time and energy to what is important to you for getting better. </StyledParagraph>
        <StyledParagraph>Our team of proficient healthcare professionals knows that the process of healing is not just about cure of the symptoms, it is the provision of caring and personalised attention. Whether you are recovering from an illness, managing a chronic condition or even you just need to have routine check-ups our home visit doctors are here to cater to your specific needs.</StyledParagraph>
        <StyledParagraph>The distinguishing feature of HealthKo is the fact that we are constantly working on the follow-up care which is the long term one. We don't just come to your house and leave; we are always with you, giving you the help and watching your improvements. If you feel that you have been treated unfairly we will make sure that you can return so you can get the full service that you deserve.</StyledParagraph>
        <StyledParagraph>When you choose HealthKo, you're not just opting for medical care; you're choosing a partner in your journey towards better health. Our home visit doctors aren't just there to diagnose and treat; they're there to listen, to understand your concerns, and to empower you with the knowledge and resources you need to take control of your health.</StyledParagraph>
        <StyledParagraph>Imagine the relief of knowing that your doctor is just a phone call away, ready to address any questions or concerns that may arise. Whether it's adjusting your treatment plan, discussing test results, or simply providing reassurance, our team is dedicated to being there for you every step of the way.</StyledParagraph>
        <StyledParagraph>At HealthKo, we think that every person should get the best healthcare which is apt for his/her individual situation. That is the reason that we go above and beyond to provide the personalised care that is all about your well-being and convenience. With our home doctor visit service, you can have a peace of mind that the health on which you are depending on is in the best hands, right at the place of your home.</StyledParagraph>
      <StyledHeading>Frequently asked questions</StyledHeading>
      <FAQContainer>
      <DropdownItem
        question="What is a doctor visit at home?"
        answer="A doctor visit at home involves a healthcare professional coming to your home to provide medical care, diagnosis, and treatment, ensuring convenience for patients who may have difficulty travelling to a clinic or hospital." />
      <DropdownItem
        question="Who can benefit from a doctor consultation at home?"
        answer="Anyone who is unable to visit a medical facility due to illness, injury, mobility issues, or other issues can benefit from a doctor consultation at home. This service is particularly helpful for elderly individuals, people with disabilities, or those who have busy schedules."
      />
      <DropdownItem
        question="How can I schedule a doctor visit at home with HealthKo?"
        answer={
          <>
          Scheduling a doctor visit at home with Healthko is very easy and simple just follow these steps:
              
            <ol>
              <li>Select your preference time and date.</li>
              <li>Click on reserve slot</li>
              <li>We will assign top healthcare professionals</li>
              <li>Provide basic details and address</li>
             
            </ol>
          </>
        }
      />
      <DropdownItem
        question="What services are typically offered during a home visit?"
        answer="Depending on the availability and the policies of the healthcare provider or service, you may be able to request a specific doctor for your home visit, ensuring continuity of care and familiarity with your medical history."
      />
      <DropdownItem
        question="Can I request a specific doctor for a home visit?"
        answer="Depending on the availability and the policies of the healthcare provider or service, you may be able to request a specific doctor for your home visit, ensuring continuity of care and familiarity with your medical history."
      />
       <DropdownItem
        question="How do I prepare for a doctor visit at home?"
        answer="To prepare for a doctor visit at home, ensure that your living space is accessible and has good lighting, have your medical history and any relevant documents ready, and make a list of any questions or concerns you wish to discuss with the doctor."
      />
      <DropdownItem
        question="What should I expect during and after the visit?"
        answer="During the visit, the doctor will conduct an examination, provide necessary treatment or recommendations, and discuss follow-up care if needed. After the visit, you should follow the instructions provided by the healthcare professional and reach out if you have any further questions or concerns."
      />
      </FAQContainer>
      
       <Footer />
      
    </div>
  );
}

export default DoctorAtHomePage;

import React from "react";
import "./index.css"; // Import your CSS file here
import { GrPrevious } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

export const Videotnc = () => {
  const { variable } = useParams();
  console.log(variable);
  const navigate = useNavigate();
  const navigation = () => {
    navigate("/");
    if (variable == 1) {
      navigate("/video-consultation");
    }
  };

  return (
    <>
      <div className="terms-section">
        <div className="header-terms">Terms and Conditions</div>
        <GrPrevious
          style={{ fontSize: "24px", cursor: "pointer" }}
          onClick={navigation}
        />
        <div className="partition"></div>
        {/* <div className='t-data'>
        {Object.keys(terms_and_conditions).map((key) => (
          <div  key={key}>
            <ul className='heading'><li>{key}</li></ul>
            <div className='sub-head'>{terms_and_conditions[key].Header}</div>
            <div className='desc'>{terms_and_conditions[key].Description}</div>
          </div>
        ))}
        {Object.keys(tnc).map((section) => (
    <div key={section}>
      <ul className='heading'><li>{section}</li></ul>
      {Array.isArray(tnc[section]) ? (
        tnc[section].map((point, index) => (
          <div key={index}>
            <div className='sub-head'>{point.Point}</div>
            <div className='desc'>{point.Description}</div>
          </div>
        ))
      ) : (
        <div className='desc'>{tnc[section]}</div>
      )}
    </div>
  ))}
  <ul className='heading'><li>Consultation and Fee Payments</li></ul>
 {Object.keys(consult['Consultation and Fee Payments']).map((section) => (
  <div className='sub-head' key={section}>
    <h2>{section}</h2>
    {Object.keys(consult['Consultation and Fee Payments'][section]).map((subsection) => (
      <div style={{ display: 'flex', gap: '8px' }} key={subsection}>
        <div className='sub-head'>{subsection}</div>
        <div className='desc'>{consult['Consultation and Fee Payments'][section][subsection].Description}</div>
      </div>
    ))}
    {section === 'Acknowledgement' && (
      <div className='desc'>{consult['Consultation and Fee Payments'][section]}</div>
    )}
  </div>
))}

      </div> */}
      </div>
      <div
        style={{
          width: "90%",
          marginLeft: "7%",
          textAlign: "justify",
          letterSpacing: "1px",
        }}
      >
        <h2>• TERMS AND CONDITION FOR CLINIC APPOINTMENT</h2>
        <br />
        <>
          <span style={{ lineHeight: "108%", fontSize: "14pt" }}>
            1. Appointment Scheduling:
          </span>

          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginLeft: "33.24pt",
                lineHeight: "108%",
                paddingLeft: "2.76pt",
                fontSize: "14pt",
              }}
            >
              <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                Scheduling Appointments:{" "}
              </span>
              <br />
              <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                i. Patients may schedule appointments through our designated
                channels like website, App, including but not limited to phone
                calls through company channel.
              </span>
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Availability: <br />
              i. Appointments are subject to the availability of our healthcare
              professionals. <br />
              ii. We strive to accommodate your preferred date and time, but
              scheduling is based on availability and may be subject to change.
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              &nbsp;Confirmation: <br />
              i. A confirmation of the scheduled appointment will be provided
              through the chosen communication method. <br />
              ii. It is the responsibility of the patient to confirm the
              appointment details and notify us promptly of any discrepancies.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Cancellation and Rescheduling: <br />
              i. To cancel or reschedule an appointment, patients must provide
              advance notice through the designated channels. <br />
              ii. Failure to provide sufficient notice may result in
              cancellation fees or other applicable charges.
            </li>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              &nbsp;Late Arrival: <br />
              i. Patients are encouraged to arrive promptly for their scheduled
              appointments. <br />
              ii. Late arrivals may result in a shortened consultation time or,
              in some cases, rescheduling at the discretion of the healthcare
              professional.
            </li>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              &nbsp;Emergency Situations: <br />
              i. In case of emergencies or unforeseen circumstances, we reserve
              the right to reschedule appointments for the safety and well-being
              of our patients.
            </li>
            <li style={{ marginLeft: "30.95pt", paddingLeft: "5.05pt" }}>
              &nbsp;Missed Appointments: <br />
              i. Missed appointments without prior notice may result in a fee or
              affect future scheduling privileges. <br />
              ii. Persistent failure to attend scheduled appointments may lead
              to the termination of services.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Changes to Schedule: <br />
              i. We reserve the right to modify healthcare professional
              schedules as necessary. <br />
              ii. Patients will be notified in a timely manner of any changes to
              their scheduled appointments.
            </li>
            <li style={{ marginLeft: "28.3pt", paddingLeft: "7.7pt" }}>
              &nbsp;Telehealth Appointments: <br />
              i. Telehealth appointments are subject to the same terms and
              conditions as in-person consultations. <br />
              ii. Patients are responsible for ensuring a suitable and secure
              environment for telehealth consultations.
            </li>
            <li style={{ marginLeft: "28.41pt", paddingLeft: "7.59pt" }}>
              &nbsp;Confidentiality: <br />
              i. All information shared during appointments, whether in-person
              or via telehealth, will be treated with the utmost confidentiality
              in accordance with applicable laws and regulations.
            </li>
            <li style={{ marginLeft: "30.78pt", paddingLeft: "5.22pt" }}>
              &nbsp;Feedback and Concerns: <br />
              i. We welcome patient feedback and encourage open communication.{" "}
              <br />
              ii. If you have concerns about your appointment experience, please
              contact our designated customer service representatives.
            </li>
            <li
              style={{
                marginLeft: "28.3pt",
                marginBottom: "8pt",
                paddingLeft: "7.7pt",
              }}
            >
              &nbsp;Governing Law: <br />
              i. These appointment scheduling terms and conditions are governed
              by the laws of Delhi and any disputes will be subject to
              resolution in the appropriate jurisdiction.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
              By scheduling an appointment with our clinic, you acknowledge that
              you have read, understood, and agreed to these terms and
              conditions. We reserve the right to update or modify these terms
              at any time, with changes communicated through appropriate
              channels.
            </span>
            <br />
            <br />
            2. Cancellation and rescheduling:
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Cancellation of Appointments: <br />
              i. Customers are kindly requested to provide at least 1 hours'
              notice for the cancellation of any scheduled appointment with
              Clinic or Doctor.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii.. Failure to provide timely notice may result in a cancellation
            fee being charged to the customer account.
          </p>
          <ol start={2} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Cancellations can be made by contacting our customers
              supports at 7339966643.
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              &nbsp;Rescheduling Appointments: <br />
              i. Clients may request to reschedule an appointment by contacting
              our clinic reception at least 1 hours before the originally
              scheduled time.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Every effort will be made to accommodate rescheduling requests,
            subject to the availability of appointment slots.
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            iii. Company reserves the right to charge a rescheduling fee of
            Amount for appointments rescheduled within 1 hours of the originally
            scheduled time.
          </p>
          <ol start={4} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Emergency Circumstances: <br />
              i. In case of emergencies or unforeseen circumstances,
              clinic/doctor understands that cancellations or rescheduling may
              be necessary with short notice.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Clients experiencing emergencies are encouraged to contact our
            clinic reception as soon as possible to discuss their situation.
          </p>
          <ol start={5} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              &nbsp;Refunds: <br />
              i. Cancellation fees are non-refundable and will be charged to the
              client's account.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. In the event of extenuating circumstances, refund requests will
            be considered on a case-by-case basis.
          </p>
          <ol start={6} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              &nbsp;No-Show Policy: <br />
              i. A "no-show" is defined as a client who misses an appointment
              without canceling or rescheduling in advance.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Clients who fail to show up for their appointment without notice
            may be subject to a no-show fee.
          </p>
          <ol start={7} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "30.95pt", paddingLeft: "5.05pt" }}>
              &nbsp;Policy Amendments: <br />
              i. company and doctor reserves the right to amend this
              Cancellation and Rescheduling Policy at any time.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "8pt",
            }}
          >
            ii. Clients will be notified of any changes to the policy through
            the contact information provided during the appointment booking
            process.
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
              By engaging in our services, clients acknowledge and agree to
              abide by the terms and conditions outlined in this Cancellation
              and Rescheduling Policy. Failure to comply with these terms may
              result in additional charges and impacts on the ability to
              schedule future appointments.
            </span>
            <br />
            <br />
            3. Payments:
            <br />
            &nbsp;
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Service Fees
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  The customer agrees to pay the applicable service fees for
                  clinic appointments and doctor consultancy services as
                  outlined by HealthKo. The current fee schedule is available on
                  our official website or can be obtained by contacting our
                  customer service.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Service fees are subject to change, and any revisions will be
                  communicated in advance through our official communication
                  channels. It is the responsibility of the client to stay
                  informed about the current fee structure.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Payment Methods
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Healthko accepts payments through various methods, Like UPI,
                  all credit/debit cards, electronic funds transfer, you can pay
                  at clinic also and other methods as specified on our website.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Or you can pay cash amount at the clinic or doctor.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Payment details, including credit/debit card information, are
                  securely processed in compliance with industry standards.
                  HealthKo does not store sensitive payment information on its
                  servers.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              Billing and Invoicing
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Invoices for clinic appointments and doctor consultancy
                  services will be issued electronically and sent to the email
                  address provided by the client. It is the client's
                  responsibility to ensure the accuracy of their contact
                  information.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Payment is due within the specified period mentioned on the
                  invoice. Failure to make timely payments may result in the
                  suspension of services or additional charges as outlined in
                  the Late Payment section.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Late Payment
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Payments not received by the due date are considered overdue.
                  Healthko reserves the right to charge a late fee or interest
                  on overdue payments as allowed by applicable laws.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  In the event of persistent late payments, Healthko may suspend
                  or terminate services until outstanding payments are settled.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              Refunds
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Refunds for clinic appointments and doctor consultancy
                  services are subject to Healthko's refund policy, as stated on
                  our website. Clients are encouraged to review the policy to
                  understand the conditions under which refunds may be issued.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Refund requests must be submitted in writing to HealthKo
                  within the timeframe specified in the refund policy.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              Taxes
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  All fees are exclusive of applicable taxes. Clients are
                  responsible for any taxes, duties, or levies imposed by
                  regulatory authorities in connection with the services
                  provided by HealthKo.
                </li>
                <li
                  style={{
                    marginLeft: "23pt",
                    marginBottom: "8pt",
                    lineHeight: "108%",
                    paddingLeft: "13pt",
                    fontSize: "14pt",
                  }}
                >
                  <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                    HealthKo will clearly indicate any applicable taxes on
                    invoices as required by law.
                  </span>
                  <br />
                  &nbsp;
                </li>
              </ol>
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            4. Medical Records:
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Purpose of Medical Records: By engaging in our clinic appointment
              and doctor consultancy services, you acknowledge and agree that
              the purpose of maintaining medical records is to ensure accurate
              and comprehensive healthcare services. Medical records may include
              but are not limited to, personal information, medical history,
              diagnostic reports, treatment plans, and any other relevant
              healthcare data.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Confidentiality: We are committed to maintaining the
              confidentiality of your medical records in accordance with
              applicable laws and regulations. Your medical information will
              only be accessed and disclosed by authorized healthcare
              professionals involved in your treatment. We have implemented
              robust security measures to safeguard your medical records from
              unauthorized access.
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              Data Storage and Retrieval: Your medical records will be stored in
              electronic and/or physical formats securely. Our clinic employs
              industry-standard practices to ensure the integrity and
              accessibility of your medical information. You may request a copy
              of your medical records in accordance with the procedures outlined
              in the applicable laws and regulations.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Access to Medical Records: Access to your medical records will be
              granted to authorized personnel within our clinic, including
              healthcare providers directly involved in your care. In certain
              circumstances, your records may be shared with other healthcare
              professionals or entities involved in your treatment, with your
              explicit consent or as required by law.
            </li>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              Correction of Medical Records: If you believe that any information
              in your medical records is inaccurate or incomplete, you have the
              right to request corrections. Requests for corrections should be
              submitted in writing to our clinic administrator. We will review
              such requests in accordance with applicable laws and promptly make
              any necessary corrections.
            </li>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              Retention Period: Your medical records will be retained for the
              duration required by law and ethical standards. After the legally
              mandated retention period has expired, your records may be
              securely disposed of in compliance with applicable regulations.
            </li>
            <li style={{ marginLeft: "30.95pt", paddingLeft: "5.05pt" }}>
              Communication and Notifications: By providing your contact
              information, you consent to receiving communications and
              notifications related to your clinic appointments, test results,
              and other healthcare-related matters. You may opt-out of
              non-essential communications at any time.
            </li>
            <li
              style={{
                marginLeft: "31.56pt",
                marginBottom: "8pt",
                paddingLeft: "4.44pt",
              }}
            >
              Amendments to Terms and Conditions: These terms and conditions may
              be amended from time to time to comply with changes in laws and
              regulations or to enhance our services. Notice of any amendments
              will be provided, and continued use of our services after such
              notice constitutes acceptance of the updated terms.
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            5. Privacy and Confidentiality:
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Collection of Personal Information
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Information We Collect: In the course of providing clinic
                  appointment and doctor consultancy services, we may collect
                  personal information from users. This may include, but is not
                  limited to, names, contact details, medical history, and
                  appointment preferences.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Voluntary Submission: Users may voluntarily provide certain
                  personal information when using our services, such as when
                  scheduling appointments or filling out medical history forms.
                  Submission of such information is considered a consent for its
                  collection and processing.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Use of Personal Information
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Service Provision: The primary purpose of collecting personal
                  information is to facilitate the provision of clinic
                  appointment and doctor consultancy services. This includes
                  appointment scheduling, medical consultations, and maintaining
                  accurate health records.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Communication: We may use the provided contact information to
                  communicate with users regarding appointment confirmations,
                  reminders, and other service-related matters. Users may also
                  receive information about our services, promotions, and
                  updates, but they can opt out of non-essential communications.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              Confidentiality and Security
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Data Security: We employ industry-standard security measures
                  to protect the confidentiality and integrity of personal
                  information. This includes encryption, secure data storage,
                  and access controls.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Limited Access: Access to personal information is restricted
                  to authorized personnel who require the information to perform
                  their duties. All staff members are trained on the importance
                  of confidentiality.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Third-Party Disclosures
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Service Providers: In certain cases, we may engage third-party
                  service providers to assist in delivering our services. These
                  providers are contractually obligated to maintain the
                  confidentiality of any personal information they may access.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Legal Requirements: We may disclose personal information if
                  required by law or in response to a valid legal process, such
                  as a court order or government request.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              User Rights
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Access and Correction: Users have the right to access their
                  personal information and request corrections. Requests can be
                  made by contacting our support team.
                </li>
                <li style={{ marginLeft: "23pt", paddingLeft: "13pt" }}>
                  Account Deletion: Users can request the deletion of their
                  account and associated personal information. However, certain
                  information may need to be retained for legal or legitimate
                  business purposes.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              Changes to Privacy Policy
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li
                  style={{
                    marginLeft: "23pt",
                    marginBottom: "8pt",
                    paddingLeft: "13pt",
                  }}
                >
                  Updates: We reserve the right to update this Privacy and
                  Confidentiality section as necessary. Users will be notified
                  of any material changes, and continued use of our services
                  constitutes acceptance of the updated terms.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            By using our clinic appointment and doctor consultancy services, you
            acknowledge that you have read, understood, and agree to the terms
            outlined in this Privacy and Confidentiality section.
            <br />
            <br />
            &nbsp;
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            6. Late Arrivals:
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Patients are encouraged to arrive on time for their appointment
              <span style={{ lineHeight: "108%", fontSize: "14pt" }}>.</span>
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Late arrivals may need to reschedule their appointment, and the
              clinic and company is not responsible for any resulting delays.
            </li>
            <li
              style={{
                marginLeft: "30.43pt",
                marginBottom: "8pt",
                paddingLeft: "5.57pt",
              }}
            >
              If you Reached at the clinic after the appointment time your
              amount will not refund in any circumtances.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            7. Infection Control:
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              &nbsp;Sanitation and Hygiene Standards: <br />
              i. The clinic shall adhere to the highest standards of sanitation
              and hygiene to minimize the risk of infections.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii.&nbsp; All staff members are trained and obligated to follow
            proper hand hygiene and sanitation protocols.
          </p>
          <ol start={2} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Cleaning Procedures: <br />
              i. The clinic premises will undergo regular and thorough cleaning,
              with special attention to high-touch surfaces.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Waiting areas, examination rooms, and other commonly used spaces
            will be disinfected regularly.
          </p>
          <ol start={3} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              &nbsp;Personal Protective Equipment (PPE): <br />
              i. All healthcare providers and staff are required to wear
              appropriate PPE, including masks, gloves, and other necessary
              protective gear.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Patients may be required to wear masks or other protective
            equipment as deemed necessary by the clinic.
          </p>
          <ol start={4} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Patient Screening: <br />
              i. Patients are encouraged to inform the clinic of any symptoms of
              illness or potential exposure to infectious diseases before
              scheduling appointments.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. The clinic reserves the right to reschedule appointments for
            individuals exhibiting symptoms of illness or potential exposure.
          </p>
          <ol start={5} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              &nbsp;Appointment Protocols: <br />
              i. Telemedicine services may be offered as an alternative to
              in-person consultations when deemed appropriate.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Physical distancing measures will be implemented in waiting
            areas, and appointment scheduling will be managed to minimize
            crowding.
          </p>
          <ol start={6} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              &nbsp;Reporting Infections: <br />
              i. Patients and staff are obligated to report any symptoms of
              illness or positive COVID-19 diagnoses to the clinic promptly.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            Ii. The clinic will take necessary steps to inform relevant parties
            about potential exposure while maintaining patient confidentiality.
          </p>
          <ol start={7} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "30.95pt", paddingLeft: "5.05pt" }}>
              Continuity of Care: <br />
              i. In the event of clinic closure due to infection control
              measures, the clinic will make efforts to provide alternative
              means of care, such as telemedicine services.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "0pt",
            }}
          >
            ii. Rescheduled or canceled appointments due to infection control
            measures will be communicated to affected parties as soon as
            possible.
          </p>
          <ol start={8} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              &nbsp;Compliance with Regulations: <br />
              i. The clinic will comply with all local, state, and federal
              regulations related to infection control and healthcare practices.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "36pt",
              marginBottom: "8pt",
            }}
          >
            ii. Any updates or changes to infection control measures will be
            communicated to patients through appropriate channels
            <span style={{ fontFamily: '"Segoe UI"', color: "#d1d5db" }}>
              .
            </span>
            <br />
            <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            <span style={{ fontFamily: '"Segoe UI"' }}>
              8. Follow-up Appointments:
            </span>
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            <br />
            <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
              a. Scheduling Follow-Up Appointments:{" "}
            </span>
            <br />
            <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
              i. After the initial consultation, the attending physician may
              recommend follow-up appointments based on the patient's medical
              condition.
            </span>
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii. The scheduling of follow-up appointments is essential for the
            continuity and effectiveness of the healthcare provided.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            b. Appointment Confirmation: <br />
            i.&nbsp; Patients are responsible for confirming their follow-up
            appointments within the specified time frame provided by the clinic.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii.&nbsp; Failure to confirm a follow-up appointment may result in
            the cancellation of the appointment slot.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            c. Rescheduling and Cancellation: <br />
            i.&nbsp; Patients are encouraged to provide at least 1 hours' notice
            for any rescheduling or cancellation of follow-up appointments.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii.&nbsp; Late cancellations or no-shows may be subject to a fee, as
            outlined in the clinic's fee schedule.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            d. Communication Channels: <br />
            i. The clinic will use the contact information provided by the
            patient to communicate details about follow-up appointments.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii. It is the responsibility of the patient to inform the clinic
            promptly of any changes in their contact information.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            e. Medical Updates: <br />
            i. Patients are expected to provide relevant medical updates and
            information at each follow-up appointment to ensure accurate and
            comprehensive healthcare.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii. The attending physician may adjust the follow-up appointment
            schedule based on the patient's medical progress and needs.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            f. Emergency Follow-Up: <br />
            i. In case of an emergency or significant change in the patient's
            health condition between scheduled follow-up appointments, patients
            are advised to contact the clinic promptly for guidance.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii. The clinic will make reasonable efforts to accommodate emergency
            follow-up appointments as needed.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            g. Record Keeping: <br />
            i. The clinic will maintain accurate records of all follow-up
            appointments, including date, time, and relevant medical
            information.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii. Patients may request access to their follow-up appointment
            records in accordance with the clinic's privacy policy.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            h. Modification of Follow-Up Appointment Terms: <br />
            i. The clinic reserves the right to modify the terms and conditions
            related to follow-up appointments. Any changes will be communicated
            to patients in a timely manner.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            ii. Continued use of the clinic's services after the modification of
            follow-up appointment terms implies acceptance of the updated terms.
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
              By scheduling a follow-up appointment with the clinic, the patient
              agrees to abide by the terms and conditions outlined in this
              section.
            </span>
            <br />
            <br />
            9. Code of Conduct:
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            Welcome to HealthKo. We are committed to providing you with the best
            possible healthcare experience. To ensure a safe and respectful
            environment for all our users, we ask that you adhere to the
            following Code of Conduct when using our clinic appointment and
            doctor consultancy services:
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "67.05pt", paddingLeft: "0pt" }}>
              Respect and Courtesy:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Treat our staff, doctors, and fellow users with respect and
                  courtesy.
                </li>
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Avoid using offensive language or engaging in any form of
                  harassment or discrimination.
                </li>
              </ol>
            </li>
            <li style={{ marginLeft: "67.56pt", paddingLeft: "4.44pt" }}>
              Appropriate Use:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Use our services for their intended purpose – scheduling
                  clinic appointments and consulting with our healthcare
                  professionals.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Do not misuse our platform for any illegal or unethical
                  activities.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={3} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "66.43pt", paddingLeft: "5.57pt" }}>
              Confidentiality:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Respect the privacy and confidentiality of your own health
                  information as well as that of other users.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Do not share sensitive information about yourself or others in
                  public forums or discussions.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={4} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "67.56pt", paddingLeft: "4.44pt" }}>
              Compliance with Laws:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Abide by all applicable laws and regulations when using our
                  services.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Report any illegal or unethical activities observed on our
                  platform.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={5} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "67.25pt", paddingLeft: "4.75pt" }}>
              Timeliness:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Be punctual for scheduled appointments. Notify us in advance
                  if you need to reschedule or cancel an appointment.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={6} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "65.13pt", paddingLeft: "6.87pt" }}>
              Professionalism:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Engage in professional and constructive communication with our
                  healthcare professionals.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Provide accurate information about your medical history and
                  current health status.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={7} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "66.95pt", paddingLeft: "5.05pt" }}>
              Payment and Billing:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Ensure timely payment for services rendered, as per the
                  agreed-upon terms.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Report any billing discrepancies promptly to our customer
                  support.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={8} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "67.56pt", paddingLeft: "4.44pt" }}>
              Feedback:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Provide honest and constructive feedback to help us improve
                  our services.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Refrain from posting false or misleading reviews.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={9} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "64.3pt", paddingLeft: "7.7pt" }}>
              Security:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Take appropriate measures to secure your account and personal
                  information.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Report any unauthorized access or suspicious activities
                  immediately.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <ol start={10} type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "64.41pt", paddingLeft: "7.59pt" }}>
              Consequences of Violation:
              <ol
                type="i"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li style={{ marginLeft: "32pt", paddingLeft: "4pt" }}>
                  Violation of this Code of Conduct may result in the
                  termination of your account and access to our services.
                </li>
                <li
                  style={{
                    marginLeft: "32pt",
                    marginBottom: "8pt",
                    paddingLeft: "4pt",
                  }}
                >
                  Legal action may be taken if your actions are deemed to be
                  unlawful.
                </li>
              </ol>
            </li>
          </ol>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            By using our clinic appointment and doctor consultancy services, you
            agree to abide by this Code of Conduct. We reserve the right to
            update or modify these guidelines at any time. Please review them
            periodically for changes.
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>
            If you have any questions or concerns about this Code of Conduct,
            please contact our customer support team at
            <a href="help@healthko.in" style={{ textDecoration: "none" }}>
              <u>
                <span style={{ color: "#0563c1" }}>help@healthko.in</span>
              </u>
            </a>
            .
          </p>
          <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            10.Dispute Resolution:
            <br />
            &nbsp;
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Negotiation and Informal Resolution: Any dispute, claim, or
              controversy arising out of or relating to these Terms and
              Conditions or the breach, termination, enforcement,
              interpretation, or validity thereof ("Dispute") shall be subject
              to negotiation between the parties. The party raising the Dispute
              shall provide written notice to the other party, and both parties
              shall make good faith efforts to resolve the Dispute amicably
              within thirty (30) days of receiving the notice.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Mediation: If the parties are unable to resolve the Dispute
              through negotiation, they agree to submit the Dispute to mediation
              before resorting to litigation. The mediation shall be conducted
              by a mutually agreed-upon mediator or, if no agreement can be
              reached, by a mediator appointed by [Mediation Service Provider].
              Each party shall bear its own costs of mediation.
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              Arbitration: If mediation does not result in a resolution, any
              unresolved Dispute shall be submitted to final and binding
              arbitration in accordance with the rules. The arbitration shall
              take place in New Delhi. The decision of the arbitrator(s) shall
              be enforceable in any court of competent jurisdiction.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Governing Law: These Terms and Conditions shall be governed by and
              construed in accordance with the laws of the state of Delhi,
              without regard to its conflict of law principles.
            </li>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              Legal Proceedings: If any legal action or other proceeding is
              brought for the enforcement of these Terms and Conditions or
              arises out of or relates to any Dispute, the prevailing party
              shall be entitled to recover its reasonable attorney's fees,
              costs, and other expenses.
            </li>
            <li
              style={{
                marginLeft: "29.13pt",
                marginBottom: "8pt",
                paddingLeft: "6.87pt",
              }}
            >
              Class Action Waiver: Any disputes must be brought in the parties'
              individual capacity and not as a plaintiff or class member in any
              purported class or representative proceeding. The parties
              expressly waive any right to participate in class actions.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          >
            <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
              By using the services provided by HealthKo, you agree to abide by
              the dispute resolution procedures outlined in this section.
            </span>
            <br />
            <br />
            11. Termination of Services:
          </p>
          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li style={{ marginLeft: "31.05pt", paddingLeft: "4.95pt" }}>
              Termination by Either Party: Either party may terminate the
              services provided under this agreement with written notice to the
              other party. The notice period for termination shall be [insert
              number of 30 days, unless otherwise agreed upon in writing.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Termination for Cause: Either party may terminate this agreement
              immediately upon written notice if the other party breaches any
              material term or condition of this agreement and fails to remedy
              such breach within 30 days of receiving written notice specifying
              the breach.
            </li>
            <li style={{ marginLeft: "30.43pt", paddingLeft: "5.57pt" }}>
              Termination for Convenience: The company and clinic reserves the
              right to terminate this agreement for convenience upon 30 days'
              written notice to the Client. In such cases, the Clinic shall
              refund any prepaid fees for services not yet rendered.
            </li>
            <li style={{ marginLeft: "31.56pt", paddingLeft: "4.44pt" }}>
              Effects of Termination: Upon termination of this agreement, the
              Client shall promptly pay any outstanding fees or charges for
              services provided up to the effective date of termination. The
              Client shall return any property or materials belonging to the
              Clinic, including but not limited to patient records, within 30
              days of termination.
            </li>
            <li style={{ marginLeft: "31.25pt", paddingLeft: "4.75pt" }}>
              Survival of Terms: Termination of this agreement shall not affect
              the rights and obligations of the parties accrued prior to
              termination, including but not limited to any confidentiality
              obligations, intellectual property rights, or payment obligations.
            </li>
            <li style={{ marginLeft: "29.13pt", paddingLeft: "6.87pt" }}>
              Transition of Services: In the event of termination, the Clinic
              shall cooperate with the Client to facilitate a smooth transition
              of services. The Client shall have the option to transfer patient
              records to another healthcare provider upon written request.
            </li>
            <li style={{ marginLeft: "30.95pt", paddingLeft: "5.05pt" }}>
              Dispute Resolution: Any disputes arising from or relating to the
              termination of services under this agreement shall be resolved in
              accordance with the dispute resolution provisions outlined in the
              general terms and conditions of this agreement.
            </li>
            <li style={{ marginLeft: "30.95pt", paddingLeft: "5.05pt" }}>
              Governing Law: This termination section shall be governed by and
              construed in accordance with the laws of jurisdiction. Any legal
              actions or proceedings related to the termination of services
              shall be brought exclusively in the courts of New Delhi.
            </li>
          </ol>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "8pt",
              lineHeight: "108%",
              fontSize: "14pt",
            }}
          ></p>
        </>
      </div>
      <br />
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          textAlign: "justify",
          letterSpacing: "1px",
        }}
      >
        <h2>• TERMS AND CONDITION FOR DOCTOR CONSULTANCY AT PATIENT HOME</h2>
        <br />
        <>
          <div>
            <ol type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "serif",
                  fontSize: "12pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.52pt",
                  paddingLeft: "7.48pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                  Scope of services:
                </span>
                <br />
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  a. The doctor will provide medical consultation services at
                  the patient's home and the consultation service provide based
                  on patient requirement.{" "}
                </span>
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  b. Consultations may include assessments, diagnoses, and
                  treatment recommendation which doctor recommend based on
                  patient condition.
                </span>
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  c. The doctor is also{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  R
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  ecommend medicine, diagnoses and many other treatment
                  equipment to buy a trustable institute, medicine shop, or
                  other medicine seller platform.
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  {" "}
                </span>
                <br />
                <br />
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                  2. Fees and payment
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                  :
                </span>
                <br />
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  a. Initial Consultation Fees
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  :
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  First of{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  all
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  you have to pay initial consultation fee when a doctor
                  allotted you, when a doctor allotted you for home visit and
                  that time you have to pay initial consultation fee, when
                  company received{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  initial
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  consultation fee after that our doctor proceed for next.
                </span>
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  b. Additional Fees
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  :
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  {" "}
                </span>
                <span
                  style={{
                    backgroundColor: "#ffffff",
                    fontFamily: '"Segoe UI"',
                    fontSize: "11pt",
                  }}
                >
                  Doctors may offer additional services during a home visit,
                  such as diagnostic tests, administering medications, or
                  providing medical certificates. The cost of these additional
                  services should be outlined in the terms and conditions, and
                  patients should be informed of any extra charges before these
                  services are rendered.
                </span>
                <br />
                <br />
                <span
                  style={{
                    backgroundColor: "#ffffff",
                    fontFamily: '"Segoe UI"',
                    fontSize: "11pt",
                  }}
                >
                  c. Follow-Up Consultation Fees
                </span>
                <span
                  style={{
                    backgroundColor: "#ffffff",
                    fontFamily: '"Segoe UI"',
                    fontSize: "11pt",
                  }}
                >
                  :{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  In cases where follow-up consultations are required, doctors
                  must establish a fee structure for these services. Follow-up
                  fees can be different from the initial consultation fee and
                  should be communicated clearly to the patient. Having a
                  transparent fee schedule ensures that patients are aware of
                  the costs associated with their ongoing medical care.
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  d. Payment Methods
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  :{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  To facilitate payments,{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  company
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  should accept various payment methods, including{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  UPI,
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  all{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  credit/debit cards,
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  Internet banking,
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  various{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  mobile wallets, and online bank transfers. Offering multiple
                  payment options ensures convenience for patients and increases
                  the likelihood of prompt payments
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  .
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  e. For Initial Consultation Fee company will accept only
                  online payment and for additional fee which is charged by
                  doctor they will accept all payment method at your home.
                </span>
                <br />
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                  3. Rescheduling:
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  a. Rescheduling Policies
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  :{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  You{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  can not
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  reschedule after the doctor allotment, when a doctor is
                  allotted to you and you are paid initial consultation fee
                  after that you{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  can not
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  reschedule the home visit appointment because our doctor time
                  is very important,
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  (
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Except emergency condition at that condition{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  you should conta
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  ct the customer support team).
                </span>
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  In Emergency condition or you are not home at that time or you
                  are about to leave home at that time for this condition
                  contact to that doctor which is allotted to you
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  or contact our customer support{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  team and{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  talk to that and ask him for rescheduling{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  other wise
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  company has not reschedule
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  and your amount will not refun
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  ded.
                </span>
                <br />
                <br />
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "14pt",
                    fontWeight: "bold",
                  }}
                >
                  4.
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  &nbsp;
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "14pt",
                    fontWeight: "bold",
                  }}
                >
                  CANC
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "14pt",
                    fontWeight: "bold",
                  }}
                >
                  ELLATION PO
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "14pt",
                    fontWeight: "bold",
                  }}
                >
                  LICIES:
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  We at{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  HealthKo
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  .
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  understand that unforeseen circumstances can sometimes require
                  you to cancel a doctor's visit at your home. To maintain a
                  fair and efficient healthcare system for all our patients, we
                  have established the following cancellation policy. Please
                  read and understand the terms and conditions below:
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  a
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  . Cancellation Timeframe:
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  Patients are allowed to cancel their doctor's visit at their
                  home at least{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  before 1
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  hours before the scheduled appointment time. This allows us to
                  make the necessary adjustments to our schedule
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  and if doctor is left their clinic for patient treatment for
                  those condition your initial consultation fee amount is not
                  refunded if you are cancel in that period so please contact
                  the doctor which is allotted to you
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  or talk to our customer support team at
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  7339966643 or mail us
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                  at
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  &nbsp;{" "}
                </span>
                <a
                  style={{ color: "#0563c1" }}
                  href="mailto:%20support@healthko.in"
                >
                  <span
                    style={{
                      color: "#0563c1",
                      fontFamily: '"Segoe UI"',
                      fontSize: "11pt",
                      textDecoration: "underline",
                    }}
                  >
                    support@healthko.in
                  </span>
                </a>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  and talk to them before cancellation.
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  b.{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Cancellation Procedure:
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  To cancel an appointment, patients must contact our customer
                  service department by phone a
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  t 7339966643
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  or through our online booking system at Website
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                  or App
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  . Please provide your full name, appointment details, and the
                  reason for cancellation
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  .
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  c
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  . Late Cancellation or No-Show:
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  If you cancel your appointment within{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  1{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  hours of the scheduled time
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  ,
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  you may be subject to a cancellation fee
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                  and if{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  our doctor is left for{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  patients
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  treatment at patients home at that condition{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  then amount will not refunded
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  . This fee is determined by the healthcare provider and is
                  designed to compensate for the time and resources allocated to
                  the visit.
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  d
                </span>
                <span
                  style={{
                    fontFamily: '"Segoe UI"',
                    fontSize: "11pt",
                    fontWeight: "bold",
                  }}
                >
                  .
                </span>
                <span
                  style={{
                    fontFamily: '"Segoe UI"',
                    fontSize: "11pt",
                    fontWeight: "bold",
                  }}
                ></span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  Emergency Situations:{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  We understand that emergencies can happen. If you encounter an
                  unforeseen emergency that prevents you from keeping your
                  appointment, please notify us as soon as possible. We will
                  assess the situation on a case-by-case basis and may waive any
                  cancellation fees in genuine emergency cases
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  in emergency situations contact our customer care support
                  team.
                </span>
                <br />
                <br />
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  e. Refund Policies: If you are Cancel the before 1 hour of
                  appointment time then company will refund all the amount{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  and
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                  no
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  cancellation charge is applicable{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  and
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  {" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  after{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  t
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  hat{" "}
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  no amount will refund you if our doctor is left clinic for
                  patient treatment
                </span>
                <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                  at your home.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                f
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                . Repeat Offenders:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Consistent late cancellations or no-shows may result in
                restrictions on future appointment scheduling. We are committed
                to serving all our patients efficiently and need to manage our
                resources accordingly
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                g.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Provider Cancellations:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                In the rare event that the healthcare provider has to cancel a
                scheduled visit, we will make every effort to reschedule the
                appointment at a time that is convenient for you. If a suitable
                reschedule is not possible, any prepaid fees will be refunded
                promptly.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                h
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                . Policy Changes:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                reserves the right to modify or update this cancellation policy.
                Patients will be notified of any changes through our official
                communication channels.{" "}
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                By engaging our doctor consultancy services at your home, you
                acknowledge and agree to comply with the above cancellation
                policy. We are dedicated to delivering the best care possible,
                and your cooperation in this matter helps us achieve that goa
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                l.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                If you have any questions or require further clarification
                regarding this policy, please feel free to contact our customer
                service department
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                5. Patient Responsibilities:
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                In the evolving landscape of healthcare, many patients are now
                opting for doctor consultations at their homes, providing a
                convenient and personalized healthcare experience. However, this
                innovative approach to healthcare delivery comes with its own
                set of terms and conditions. Patients receiving doctor
                consultations at home are expected to adhere to certain
                responsibilities to ensure a smooth and effective healthcare
                experience. This article outlines some key patient
                responsibilities in the context of doctor consultations at a
                patient's home
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                a
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . Timeliness and Preparedness
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients should be punctual and prepared for the scheduled
                doctor's visit. This means being available at the agreed-upon
                time and location, providing all necessary medical records and
                information, and being ready to discuss their health concerns
                and medical history in a clear and concise manner
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                b.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Safety and Privacy
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients are responsible for ensuring a safe and private
                environment for the doctor's visit. This includes making sure
                the home is clean, well-lit, and free from hazards that may pose
                a risk to the healthcare provider. Patients should also respect
                the doctor's need for confidentiality and ensure that{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                conversations and medical information are kept private.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "12pt" }}>
                c.
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Active Participation
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Active participation is crucial during a home consultation.
                Patients should engage in meaningful dialogue with the doctor,
                ask questions, seek clarification on treatment plans, and
                express any concerns or preferences they may have regarding
                their care. An open and honest communication channel is
                essential for effective healthcare delivery.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                d.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Compliance with Medical Advice
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients are expected to follow the doctor's recommendations and
                treatment plans diligently. This includes taking prescribed
                medications as directed, adhering to dietary and lifestyle
                changes, and attending follow-up appointments as required.
                Non-compliance can jeopardize the effectiveness of the treatment
                and overall health outcomes.
              </span>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "12pt" }}>
                e
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . I
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                nformed Decision-Making
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients have a right to make informed decisions about their
                healthcare. To{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                fulfill
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                this responsibility, they should educate themselves about their
                medical conditions, treatment options, and potential risks and
                benefits. Patients are encouraged to seek a second opinion if
                they have doubts or reservations about a recommended course of
                treatment.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                f.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Reporting Changes in Health
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients must promptly report any changes in their health
                condition to the treating physician. Whether it's a sudden
                deterioration of symptoms or side effects from prescribed
                medications, timely communication is essential to ensure
                appropriate adjustments to the treatment plan
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                g
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . Respect for the Healthcare Provider
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Respect and professionalism are important in any healthcare
                setting, including home consultations. Patients should treat
                their doctors with courtesy, respect their time, and refrain
                from abusive language or{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                behavior
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . Building a strong doctor-patient relationship is crucial for
                effective care
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Receiving medical consultations at home offers many benefits in
                terms of convenience and personalized care. However, it also
                comes with a set of responsibilities that patients must adhere
                to in order to ensure a successful and effective healthcare
                experience. By being punctual, maintaining a safe and private
                environment, actively participating in their healthcare,
                complying with medical advice, and fulfilling their financial
                obligations, patients can contribute to a positive and
                productive doctor-patient relationship during home
                consultations. These responsibilities not only enhance the
                patient's experience but also improve overall health outcomes
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Make sure whenever the doctor calls you, please come out of your
                house and take the doctor with you.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                6. Doctor Responsibilities:
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                In the modern healthcare landscape, there is a growing trend
                towards providing medical services in a more patient-centric
                manner, including home-based consultations. When doctors provide
                consultancy services at a patient's home, it is crucial to
                establish clear terms and conditions to ensure the safety,
                professionalism, and quality of care provided. This article
                outlines the responsibilities of doctors when offering
                consultancy services at a patient's home
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                a
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . Professionalism and Ethics
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Doctors providing consultancy services at a patient's home must
                adhere to the highest standards of professionalism and ethics.
                They should maintain patient confidentiality, respect cultural
                and personal boundaries, and act with integrity at all times.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                b. Informed consent:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Prior to any medical examination or procedure, doctors must
                obtain informed consent from the patient. This includes
                explaining the nature of the visit, proposed treatments,
                potential risks, and alternatives. Patients have the right to
                make informed decisions about their care.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                c. Patient safety:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patient safety is of paramount importance. Doctors must ensure
                that the patient's home environment is safe for medical
                examinations and treatment. They should assess the surroundings
                for potential hazards and take necessary precautions.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                d. medical documentations:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Doctors must maintain accurate and complete medical records of
                each home visit. These records should include the patient's
                medical history, examination findings, diagnostic tests,
                treatments, and any recommendations. Proper documentation is
                crucial for continuity of care and legal purposes.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                e. Infection control:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Doctors should adhere to infection control protocols during home
                visits, including hand hygiene, the use of personal protective
                equipment (PPE) when necessary, and proper disposal of medical
                waste. This is especially important in the context of infectious
                diseases.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                e. communication:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Clear and effective communication is essential. Doctors should
                explain medical conditions, treatment options, and follow-up
                instructions in a way that the patient can understand. They
                should also be available for questions or concerns.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                f. Emergency preparedness:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Doctors should be prepared for medical emergencies during home
                visits. This includes having the necessary equipment,
                medications, and skills to handle critical situations until
                emergency services arrive. They should also have a communication
                plan to contact emergency services if needed.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                g. boundary setting:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Maintaining appropriate professional boundaries is crucial when
                visiting a patient's home. Doctors should avoid personal or
                non-medical involvement with patients or their families that
                could compromise the doctor-patient relationship.{" "}
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                When doctors offer consultancy services at a patient's home,
                they take on a unique set of responsibilities. These
                responsibilities go beyond clinical care and extend to
                professionalism, ethical conduct, and patient safety.
                Establishing clear terms and conditions for home-based
                consultations is essential to provide high-quality healthcare
                while safeguarding the interests of both doctors and patients.
                By following these guidelines, doctors can provide effective and
                compassionate care in the comfort of a patient's home.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                7. Privacy and Confidentiality:
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                At{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                we are committed to providing the highest level of healthcare
                services while ensuring the privacy and confidentiality of our
                patients. When you schedule a doctor's consultation at your
                home, your privacy and the confidentiality of your medical
                information are of utmost importance. This document outlines the
                terms and conditions governing the privacy and confidentiality
                of your healthcare information during in-home consultations.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                a.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patient Consent
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I the Patient, consent to receive medical care from the
                healthcare provider at my residence. I understand that the
                doctor will diagnose, treat, and provide medical advice during
                the consultation.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I understand that the doctor will verify my identity before
                initiating any medical consultation to ensure the safety and
                security of both parties.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I acknowledge that I have a responsibility to provide accurate
                and complete information about my medical history, symptoms, and
                any medications I am currently taking. I understand that
                withholding or providing false information may lead to
                inadequate care or misdiagnosis.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I understand that the doctor is bound by medical confidentiality
                obligations and will keep all my medical information and
                consultations confidential, as governed by applicable laws and
                regulations.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I agree to maintain open and honest communication with the
                doctor during the consultation and to seek clarification if I do
                not understand any aspect of my diagnosis or treatment plan.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I understand that if the doctor identifies an emergency
                situation during the consultation, they may need to take
                immediate action, including contacting emergency services,
                without seeking my consent in advance.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I acknowledge that I am responsible for the payment of all
                applicable fees and charges associated with the in-home
                consultation, as agreed upon with the healthcare provider.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I agree to adhere to any cancellation and rescheduling policies
                set forth by the{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                healthcare provider and will notify the doctor in a timely
                manner if I need to cancel or reschedule an appointment.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                I understand that I have the right to provide feedback or raise
                any concerns or complaints about the doctor's services, and I
                will do so through the designated channels provided by the
                healthcare provider.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                The healthcare provider reserves the right to terminate the
                consultation if, in their professional judgment, continuing the
                consultation at the patient's home is not in the best interest
                of the patient or if the patient's{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                behavior
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                is disruptive, uncooperative, or violates any terms and
                conditions.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                This agreement is governed by the laws of{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                New Delhi
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                , and any disputes will be resolved in accordance with the
                applicable laws and regulations of that jurisdiction
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                In-home doctor consultations offer patients the convenience of
                healthcare in familiar surroundings. However, it is essential to
                establish clear terms and conditions, including patient consent,
                to ensure a transparent and satisfactory doctor-patient
                relationship. By obtaining patient consent through a
                well-drafted agreement, both healthcare providers and patients
                can navigate this unique healthcare model with confidence and
                trust. Patients should carefully read and understand these terms
                and conditions before engaging in in-home doctor consultancy
                services.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                b
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . Privacy of Medical Information
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                We understand the sensitivity of medical information and will
                take all reasonable precautions to safeguard your privacy. Your
                medical information, including your medical history, treatment
                plans, and test results, will be treated with the utmost
                confidentiality.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                c. data security:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                We will implement robust data security measures to protect your
                medical information from unauthorized access, disclosure,
                alteration, or destruction. Our electronic records and patient
                data systems will adhere to industry standards for security
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                d. Disclosure of Information:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Your medical information will only be shared with individuals
                directly involved in your care, including our medical staff and
                support personnel. In some cases, we may also share relevant
                information with other healthcare providers, pharmacies, or
                insurers for the purpose of coordinating your care. Any sharing
                of your information will be done in compliance with applicable
                privacy laws and regulations.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                e. Third-party Service:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                In the course of providing in-home consultations, we may use
                third-party services or technologies to support our practice.
                These services will be selected carefully to ensure they adhere
                to high standards of privacy and security. We will not share
                your information with third parties not directly involved in
                your healthcare without your explicit consent
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                f. Record Retention:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Your medical records will be retained in accordance with
                applicable laws and regulations. After a specified retention
                period, your records may be securely archived or destroyed,
                ensuring your privacy is maintaine
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                d.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                g. Your Right:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                You have the right to access your medical records, request
                corrections to your information, and be informed about how your
                information is used and disclosed. If you have any concerns
                about your privacy or would like to exercise your rights, please
                contact our practice's designated privacy officer
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                h. communication:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                We may contact you via phone, email, or in-person for
                appointment scheduling, follow-up, or to discuss your treatment
                plan. If you have specific preferences for communication, please
                inform our practice
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                i
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Non-Disclosure Agreement
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Our healthcare providers, including doctors, nurses, and support
                staff, are bound by strict confidentiality agreements. They are
                committed to upholding your privacy and the confidentiality of
                your medical informatio
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                n.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                j.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Changes to Privacy Policies
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                :
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                may update its privacy and confidentiality policies as needed to
                comply with changing laws or to enhance our commitment to your
                privacy. Any significant changes to these terms will be
                communicated to you in advance.
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Your privacy and confidentiality are paramount to us at [Your
                Medical Practice Name]. We are dedicated to delivering quality
                healthcare services in the comfort of your home while ensuring
                your medical information is handled with the utmost care. By
                scheduling an in-home consultation, you acknowledge and agree to
                these privacy and confidentiality terms and conditions. If you
                have any questions or concerns, please do not hesitate to
                contact our practice's designated privacy officer
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt 36pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontWeight: "bold",
                }}
              >
                By accepting our terms and conditions, you enable us to provide
                you with exceptional healthcare services while maintaining the
                highest standards of privacy and confidentiality. Your trust is
                important to us, and we look forward to serving your healthcare
                needs in a secure and private manner
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                8. EMERGENCY SITUSTIONS:
              </span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                In recent times, the demand for in-home healthcare services has
                increased significantly, especially in emergency situations
                where immediate medical attention is required. To facilitate
                this growing need, healthcare providers often offer doctor
                consultancy services at a patient's home. However, it is
                essential to establish clear terms and conditions to ensure a
                safe and effective experience for both patients and healthcare
                professionals. This article discusses the key considerations for
                emergency situations in the terms and conditions for doctor
                consultancy at a patient's home.
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>a</span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                . Emergency Situations Defined:
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                Emergency situations are those circumstances where a patient's
                medical condition requires immediate attention due to the risk
                of severe harm, deterioration, or loss of life. These may
                include but are not limited to cardiac arrests, severe allergic
                reactions, strokes, breathing difficulties, and other critical
                medical issue
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                s.
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                b.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Consent and Authorization
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>:</span>
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}> </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients must provide clear consent for the doctor to visit
                their home, understanding that this service is typically more
                expensive than traditional clinic visits. In emergency
                situations, patients or their legal guardians should provide
                explicit authorization for the doctor to initiate immediate
                life-saving procedures if necessar
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                y.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                c.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Available Services:
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}> </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                The terms and conditions should outline the specific medical{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                servic
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                es offered during home consultations, including the
                administration of emergency medications, advanced life support,
                and the ability to perform basic medical procedures as required.
                The healthcare provider must clearly specify the range of
                services available in different emergency situation
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                s.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                9. COMPLIANCE EITH LAWS AND REGULATIONS:
              </span>
              <br />
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                In recent years, the healthcare industry has witnessed a
                transformative shift towards providing healthcare services in a
                patient's own home. This shift is driven by the desire to
                improve patient outcomes, reduce healthcare costs, and enhance
                convenience. However, as healthcare services extend beyond
                traditional clinical settings, it becomes imperative for doctors
                and healthcare providers to maintain stringent compliance with
                laws and regulations. This article discusses the importance of
                complying with legal and regulatory requirements when offering
                doctor consultancy services at a patient's hom
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                e.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                a
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                . Licensing and Certification:
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                all the doctor on our platform is certified with{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                the a
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                unique certificate no from the known institutions.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Also
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                all the doctor verified by our team and after that they all are
                started services with{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                b.{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Privacy and Data Protection:
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                all the date from the doctor side and patient side data is
                protected by ISO27001 security and all the patients and{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                doctors
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                data are protected by a security level and all the data are
                monitoring by professional developers.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                During the consultation, doctors will gather medical
                information, including symptoms, diagnoses, and treatment plans.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                The all the data are secure.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                All patient data, whether in physical or electronic form,{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                are
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                stored securely. Electronic records{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                are
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                encrypted, and physical records{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                are
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                stored in locked cabinets or secure facilitie
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                s.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Access to patient data{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                are
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                restricted to authorized healthcare professionals and support
                staff who require the information for patient care.
                Authentication mechanisms and access logs{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                are
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                in place to monitor and control data access.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                data which is{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                provide{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                by the patients which is can be use{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                other healthcare providers involved in their care, insurance
                companies, and relevant authorities when{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                we{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                required
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Patients
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                data can be{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                use
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                for company upgradation in healthcare. Like if we are{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                build
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                AI doctor then we can use patients data.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                Identity of patient data is not{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                disclose
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                to other in any{" "}
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                circumtances
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                .
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                10. Medicine and Prescriptions:
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "14pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                a.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Appointment Scheduling
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                : The Patient can request a home consultation with the Doctor
                through a dedicated booking platform, phone, or other approved
                methods.
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                b.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Agreement to Services
              </span>
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                : By scheduling a home consultation, the Patient agrees to allow
                the Doctor to visit their home and provide medical consultation
                and prescription services as required
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                c.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Medical Consultation: The Doctor will provide medical assessment
                and consultation during the home visit. This may include
                diagnosing conditions, discussing treatment options, and
                prescribing medication if necessary
              </span>
              <span
                style={{
                  color: "#d1d5db",
                  fontFamily: '"Segoe UI"',
                  fontSize: "11pt",
                }}
              >
                .
              </span>
              <br />
              <span style={{ fontFamily: '"Segoe UI"', fontSize: "11pt" }}>
                {" "}
                d.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Prescriptions: If the Doctor deems it necessary, they may
                prescribe medications or treatments during the home
                consultation. The Patient is responsible for any fees associated
                with prescribed medications.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>e</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                . Accuracy of Information: The Patient is responsible for
                providing accurate and complete information about their medical
                history, current medications, and any allergies or adverse
                reactions to medications
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                f.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Follow-Up: The Patient must follow the treatment plan and attend
                follow-up appointments as recommended by the Docto
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                r.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                Doctor's Responsibilities
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                Professional Standards: The Doctor agrees to provide medical
                services that meet the standards of their profession and follow
                applicable laws and regulations
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Prescriptions: The Doctor will issue prescriptions only when it
                is medically necessary and in the best interest of the Patien
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                t.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Privacy
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                :{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                The Doctor will maintain the confidentiality of the Patient's
                medical information and comply with all relevant privacy law
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                s.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                The Doctor shall not be liable for any harm, injury, or adverse
                effects related to the Patient's medical condition, treatment,
                or prescribed medications, unless caused by gross negligence or
                intentional misconduc
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                t.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                11.Termination of Services:
              </span>
            </p>
            <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.05pt",
                  paddingLeft: "4.95pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Termination by the Doctor
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  :
                </span>
                <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      The Doctor may terminate the services at any time with
                      written notice to the patient for the following reasons
                    </span>
                    <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                      <li
                        style={{
                          fontFamily: "Calibri",
                          fontSize: "11pt",
                          lineHeight: "108%",
                          margin: "0pt 0pt 0pt 40.05pt",
                          paddingLeft: "4.95pt",
                          textIndent: "0pt",
                        }}
                      >
                        <span
                          style={{ fontFamily: "Calibri", fontSize: "11pt" }}
                        >
                          Non-payment: In the event of unpaid fees or
                          outstanding bills, the Doctor reserves the right to
                          terminate services after providing a reasonable period
                          for payment.
                        </span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 18pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <ol
              start={2}
              type="a"
              style={{ margin: "0pt", paddingLeft: "0pt" }}
            >
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 112.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Patient Conduct: If the patient engages in disrespectful,
                  threatening, or abusive{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  behavior
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  , the Doctor reserves the right to terminate services.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 18pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <ol
              start={3}
              type="a"
              style={{ margin: "0pt", paddingLeft: "0pt" }}
            >
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 111.43pt",
                  paddingLeft: "5.57pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Failure to Comply: If the patient fails to follow the Doctor's
                  advice or recommendations, making it impossible to provide
                  adequate care.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
            <ol
              start={2}
              type="i"
              style={{ margin: "0pt", paddingLeft: "0pt" }}
            >
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 59pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  The Doctor will provide reasonable notice before termination
                  in most cases unless immediate termination is necessary for
                  the safety of the Doctor or patient.
                </span>
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  &nbsp;
                </span>
              </li>
            </ol>
            <ol
              start={2}
              type="a"
              style={{ margin: "0pt", paddingLeft: "0pt" }}
            >
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Termination by the Patien
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  t:
                </span>
                <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      The patient may terminate the services at any time by
                      providing written notice to the Doctor.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      Upon termination by the patient, any pre-paid fees or
                      deposits may be refundable according to the terms outlined
                      in the payment and refund policy.
                    </span>
                    <br />
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      &nbsp;
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.43pt",
                  paddingLeft: "5.57pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Mutual Termination:
                </span>
                <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      Both parties may mutually agree to terminate the services
                      at any time, with the terms and conditions of such
                      termination to be negotiated on a case-by-case basis.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "italic",
                  }}
                >
                  Continuation of Care:
                </span>
                <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 8pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      In the event of termination of services, the Doctor will
                      assist the patient in transferring medical records and
                      ensuring the patient has access to continued medical care.
                    </span>
                    <br />
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      &nbsp;
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                12. Dispute resolution:
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                a.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Negotiation and Informal Resolution: In the event of a dispute
                or disagreement between the patient and the service provider,
                both parties agree to first attempt to resolve the issue
                informally through good faith negotiations. This may involve
                discussions, email correspondence, or other means of
                communication
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                b.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Mediation: If an informal resolution is not achievable, both
                parties agree to pursue mediation before resorting to formal
                legal action. Mediation will be conducted by a neutral third
                party mutually agreed upon by both the patient and the service
                provider. The costs of mediation will be shared equally between
                the parties.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                c.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Arbitration: If mediation fails to resolve the dispute, both
                parties agree to submit the matter to{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                binding arbitration. The arbitration shall be conducted in
                accordance with the rules of a reputable arbitration service
                mutually agreed upon by both parties. The arbitrator's decision
                will be final and binding. The costs of arbitration will be
                shared equally between the parties
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                d.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Governing Law: This Agreement shall be governed by and construed
                in accordance with the laws of{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                New Delhi
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                without regard to its conflict of law principles.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                e.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Venue: Any legal action, including arbitration or litigation,
                arising out of or in connection with this Agreement shall be
                brought in the state or federal courts located within{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                New Delhi
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                and both parties consent to the exclusive jurisdiction and venue
                in such courts.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                f.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Attorney's Fees: In the event of any legal action or arbitration
                to enforce the terms of this Agreement, the prevailing party
                shall be entitled to recover its reasonable attorney's fees and
                costs from the other party
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                g.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Class Action Waiver: Both parties agree not to participate in a
                class action or collective action lawsuit against the other. Any
                dispute will be resolved on an individual basi
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                s.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                h.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                everability
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                : If any part of this "Dispute Resolution" section is found to
                be invalid or unenforceable, it will not affect the validity or
                enforceability of the remainder of this section or the Agreement
                as a whole.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                i
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                Entire Agreement: This "Dispute Resolution" section, along with
                the entire terms and conditions agreement, constitutes the
                entire understanding and agreement between the patient and the
                service provider, superseding any prior or contemporaneous
                agreements, representations, warranties, and understandings
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                .
              </span>
              <br />
              <br />
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "italic",
                }}
              >
                13. Changes of Terms and conditions:
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                when we need to change the terms and{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                conditions
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                we will change this and keeping in mind company and the
                customers benefits.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "italic",
                }}
              >
                14.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "italic",
                }}
              >
                Governing Law:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                This Agreement and any dispute or claim arising out of or in
                connection with it or its subject matter shall be governed by
                and construed in accordance with the laws of{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                New Delhi,
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                without regard to its conflict of laws principles
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                .
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                &nbsp;
              </span>
            </p>
          </div>
          <div
            className="docpe"
            style={{ position: "absolute", color: "white", marginLeft: -450 }}
          ></div>
        </>
      </div>

      <br />

      <br />
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          textAlign: "justify",
          letterSpacing: "1px",
        }}
      >
        <h2>
          • TERMS AND CONDITION FOR VIDEO CONSULTATION OR ONLINE CONSULTATION
        </h2>
        <br />
        <>
          <div>
            <p
              style={{
                fontSize: "18pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>
                1. Introduction:
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Welcome to{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                your trusted online destination for professional medical
                consultations. These Terms and Conditions govern your use of our
                platform and the services provided herein. By accessing or using
                our services, you agree to comply with and be bound by these
                Terms and Conditions.
              </span>
              <br />
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                At{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                HealthKo.
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                we strive to connect users with qualified healthcare
                professionals through our secure and user-friendly online
                platform. It is essential to understand the terms outlined below
                to ensure a safe and effective experience for all users.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Please read these Terms and Conditions carefully before using
                our services. If you do not agree with any part of these terms,
                you may not access or use our platform
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "italic",
                }}
              >
                .
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                2. Scope of Ser
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                vices:
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Virtual Consultations:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Our platform facilitates secure and confidential virtual
                consultations between patients and licensed healthcare
                professionals.
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                b.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Consultations may include medical advice, diagnosis, treatment
                recommendations, and prescription services when deemed
                appropriat
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                e.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                3.
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                Eligibility and Suitability:
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                Users must ensure they are eligible for online doctor
                consultations based on applicable regulations and laws
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                The platform is designed for non-emergency medical issues. In
                case of emergencies, users are instructed to seek immediate
                in-person medical attention.
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                4.
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                Privacy and Data Protection:
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "14pt" }}>a</span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                .{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                Information We Collect:
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                We are collected{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                your personal information like name,
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                phone no,
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                and many other personal information,{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                medical history
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>,</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                many other but the all date are safe and protected and this data
                not sharing to other in any circum
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>s</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                tances
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                but this data can be use by company{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                for company upgrad
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>a</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                tion
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                and company growing
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                and this data can be use for customer better exp
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>e</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                ri
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>e</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                nce
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>s</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                {" "}
                in future.
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                How We Use Your Information:
              </span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                We{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                can use your information for improving service,
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                providing medical advice,{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                company product sells,{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                and company upgradation{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                and many others
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                for company internal work.
              </span>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                b.
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                Data Sec
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                urity:
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}> </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.78pt",
                  paddingLeft: "7.22pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Confidentiality and Privacy: We understand the sensitivity of
                  the health information shared during consultations, and we are
                  committed to maintaining the confidentiality and privacy of
                  all user data. All information shared through our platform,
                  whether during consultations or in any other form, is treated
                  with the utmost confidentiality.
                </span>
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  ii.{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Secure Transmission: Our platform employs industry-standard
                  encryption protocols to{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  ensure the secure transmission of data between users and our
                  servers. This helps protect your personal and health
                  information from unauthorized access during transi
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  t.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data Storage: We utilize secure servers and databases to store
                  user data, employing robust security measures to safeguard
                  against unauthorized access. Our storage practices adhere to
                  industry standards, and we continuously assess and update our
                  security protocols to address emerging threats.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Access Controls: Access to user data is strictly controlled
                  and limited to authorized personnel with a legitimate need for
                  such access. We implement role-based access controls to ensure
                  that only individuals necessary for the provision of services
                  have access to specific types of user data.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.27pt",
                  paddingLeft: "2.73pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Regular Security Audits: We conduct regular security audits
                  and assessments to identify and address potential
                  vulnerabilities in our systems. These audits help us maintain
                  a proactive approach to data security and ensure that our
                  platform meets or exceeds industry security standard
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  s.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.75pt",
                  paddingLeft: "5.25pt",
                  textIndent: "0pt",
                }}
              >
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Employee Training: Our staff undergoes regular training on
                  data security and privacy practices. This includes education
                  on the importance of maintaining the confidentiality of user
                  information and the secure handling of sensitive dat
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  a.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.27pt",
                  paddingLeft: "2.73pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Incident Response: In the event of a data breach or security
                  incident, we have established incident response procedures to
                  promptly and effectively address the issue. We will notify
                  affected users as required by law and take appropriate
                  measures to mitigate the impact of the breach.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 35.79pt",
                  paddingLeft: "0.21pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Compliance with Regulations: We comply with all relevant data
                  protection laws and regulations applicable to the regions in
                  which we operate
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  (Within India Region
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  and{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  we are regulate within Indian law and{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  jurisdiction
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  )
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Third{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                -{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Party
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Access:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                The online doctor consultancy services provided by{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>W</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>e</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                may involve the engagement of third-party services to enhance or
                facilitate certain aspects of our platform. This section
                outlines the conditions under which third parties may access
                information related to users of our service
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                s.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Consent:
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              ></span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                By using our online doctor consultancy services, you expressly
                consent to the engagement of third parties as described in these
                terms and conditions.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Types of Third-Party Access:
              </span>
            </p>
            <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Healthcare Professionals:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  In the course of providing our services, healthcare
                  professionals, including but not limited to doctors, nurses,
                  and medical specialists, may have access to your personal and
                  health information. Such access is necessary for the provision
                  of medical advice, diagnosis, and treatment.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Technology and Service Providers:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  We may engage third-party technology and service providers to
                  assist in the operation, maintenance, and improvement of our
                  platform. These providers may have access to user data solely
                  for the purpose of providing their services to us.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data Security:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  We take reasonable steps to ensure that any third party with
                  access to user data, as described in this section, maintains
                  appropriate data security measures to safeguard the
                  confidentiality and integrity of the information.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Legal Compliance:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  We require third parties to comply with applicable data
                  protection laws and regulations. In the event of any conflict
                  between this section and such laws, the latter shall prevail.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Limitation of Liability:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  HealthKo.
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  is not responsible for the actions or omissions of third
                  parties, including but not limited to healthcare professionals
                  and service providers. Users acknowledge and agree that the
                  engagement of third parties is necessary for the effective
                  provision of our services.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  User Control:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                  Users may have options to control or limit third-party access
                  to their data through privacy settings provided within our
                  platform. However, certain third-party access may be essential
                  for the core functionality of the service.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Changes to Third-Party Access:
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Healt
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  hKo.
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  reserves the right to modify or update this third-party access
                  section as needed. Users will be notified of any material
                  changes, and continued use of our services after such changes
                  constitutes acceptance of the updated terms
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Contact Information:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                For any questions or concerns regarding third-party access,
                please contact us at{" "}
              </span>
              <a style={{ color: "#0563c1" }} href="mailto:help@healthko.in">
                <span
                  style={{
                    color: "#0563c1",
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    textDecoration: "underline",
                  }}
                >
                  help@healthko.in
                </span>
              </a>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                or call us 7339966643
              </span>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                d.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                Data Re
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                tention:
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}> </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                This Data Retention Policy ("Policy") outlines the principles
                and practices employed by{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo.
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a provider of online doctor consultancy services ("Company"),
                with respect to the collection, storage, and retention of user
                data. By using our services, you agree to the terms outlined in
                this Policy.
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data Collection: The Company collects and processes personal
                  information, including but not limited to, name, contact
                  details, medical history, and other relevant health-related
                  data to provide effective online doctor consultancy services.
                  The information collected is solely for the purpose of
                  facilitating remote medical consultations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data Storage: All user data is securely stored in compliance
                  with industry standards and applicable data protection laws.
                  The Company employs robust security measures to protect
                  against unauthorized access, disclosure, alteration, or
                  destruction of user data.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  User data is collected and processed solely for the purpose of
                  delivering online doctor consultancy services. The Company
                  does not use or share this information for marketing purposes
                  without explicit user consent.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data Retention Period: The Company retains user data only for
                  the duration necessary to achieve the purposes for which it
                  was collected, or as required by applicable laws and
                  regulations. Once the purpose is fulfilled, or if the user
                  requests data deletion, information will be promptly and
                  securely erased from our systems.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  User Rights: Users have the right to access, correct, or
                  delete their personal information at any time. Requests for
                  data access, correction, or deletion can be submitted through
                  the user account settings or by contacting our designated Data
                  Protection Officer at{" "}
                </span>
                <a
                  style={{ color: "#0563c1" }}
                  href="mailto:support@healthko.in"
                >
                  <span
                    style={{
                      color: "#0563c1",
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      textDecoration: "underline",
                    }}
                  >
                    support@healthko.in
                  </span>
                </a>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Legal Requirements: In certain circumstances, the Company may
                  be required to retain user data for a longer period to comply
                  with legal obligations, resolve disputes, or enforce
                  agreements. The Company will ensure that such retention is in
                  accordance with applicable laws.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data De-identification: Where feasible and in compliance with
                  applicable laws, the Company may de-identify or anonymize user
                  data to protect privacy while retaining aggregate information
                  for statistical analysis and improvement of services.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Security Measures: The Company employs industry-standard
                  security measures to safeguard user data. This includes
                  encryption, access controls, and regular security audits to
                  ensure the confidentiality and integrity of the stored
                  information.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Policy Updates: The Company reserves the right to update this
                Data Retention Policy as needed. Users will be notified of any
                material changes, and continued use of our services constitutes
                acceptance of the revised Policy.
              </span>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                e.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                User{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Right:
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              ></span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Welcome to{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo.
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                We are delighted to provide you with our online doctor
                consultancy services. Before you proceed, please carefully read
                and understand the following User Rights section, which outlines
                your rights and responsibilities as a user of our platform.
              </span>
            </p>
            <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 15.45pt",
                  paddingLeft: "22.95pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Access and Use of Services:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      By using our online doctor consultancy services, you
                      acknowledge and agree to abide by these Terms and
                      Conditions.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      You have the right to access our platform and utilize the
                      services in accordance with the terms outlined herein
                    </span>
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      .
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 15.96pt",
                  paddingLeft: "22.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Privacy and Confidentiality:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      We respect your privacy. Your personal and health
                      information will be handled in accordance with our Privacy
                      Policy.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      You have the right to expect confidentiality and secure
                      handling of your personal and health data.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 14.83pt",
                  paddingLeft: "23.57pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Doctor-Patient Relationship:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Our platform facilitates virtual consultations with
                      licensed healthcare professionals. You have the right to
                      expect a professional and respectful doctor-patient
                      relationship.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      You are responsible for providing accurate and complete
                      information to the healthcare professionals during
                      consultations.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 15.96pt",
                  paddingLeft: "22.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Informed Consent:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Prior to engaging in a virtual consultation, you have the
                      right to receive information about the healthcare
                      professional, their qualifications, and any potential
                      risks associated with the consultation.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      You have the right to ask questions and seek
                      clarifications before consenting to any medical advice or
                      treatment.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 15.65pt",
                  paddingLeft: "22.75pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Data Security:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      We employ industry-standard measures to secure your data.
                      You have the right to expect the protection of your
                      information from unauthorized access or disclosure.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      It is your responsibility to keep your login credentials
                      secure and to notify us immediately of any unauthorized
                      access to your account.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 13.53pt",
                  paddingLeft: "24.87pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Feedback and Reviews:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      You have the right to provide feedback and reviews about
                      your experience with our platform and healthcare
                      professionals.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Your feedback should be constructive and adhere to our
                      guidelines for user reviews.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 15.35pt",
                  paddingLeft: "23.05pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Termination of Services:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      We reserve the right to terminate or suspend your access
                      to our services in case of violation of these Terms and
                      Conditions.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      You have the right to terminate your account at any time,
                      subject to the provisions outlined in these Terms.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 15.96pt",
                  paddingLeft: "22.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Updates and Modifications:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      We may update and modify these Terms and Conditions from
                      time to time. You will be notified of any significant
                      changes.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 8pt 20.6pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Your continued use of our services after the effective
                      date of modifications constitutes acceptance of the
                      updated terms.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By using our online doctor consultancy services, you agree to be
                bound by these terms. If you do not agree to any of the terms
                herein, please refrain from using our platform.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                If you have any questions or concerns, please contact our
                customer support team at{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              ></span>
              <a style={{ color: "#0563c1" }} href="mailto:help@healthko.in">
                <span
                  style={{
                    color: "#0563c1",
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    textDecoration: "underline",
                  }}
                >
                  help@healthko.in
                </span>
              </a>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              ></span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                f.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Confidentiality of Medical{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Information:
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              ></span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                We at{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                , recognize the sensitive nature of your personal and medical
                information. This Confidentiality of Medical Information section
                outlines our commitment to maintaining the privacy and security
                of the information you share with us during your use of our
                online doctor consultancy services.
              </span>
            </p>
            <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.05pt",
                  paddingLeft: "4.95pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Confidentiality Pledge
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Protection of Personal and Medical Information:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      We pledge to treat all personal and medical information
                      with the utmost confidentiality and take appropriate
                      measures to ensure its security.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      Limited Access:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      Access to your medical information is restricted to
                      authorized personnel, including our qualified healthcare
                      professionals, who are bound by strict confidentiality
                      agreements.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      Encryption:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      To safeguard your data during transmission, we use
                      industry-standard encryption protocols to secure the
                      communication between your device and our platform.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Collection and Use of Information
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Necessity:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      We collect and use your medical information only to
                      provide you with the best possible healthcare services.
                      Your data will not be used for any other purposes without
                      your explicit consent.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Anonymized Data:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      Aggregated and anonymized data may be used for research
                      and improvement of our services, with all personally
                      identifiable information removed.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.43pt",
                  paddingLeft: "5.57pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Legal Compliance
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Compliance with Laws:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      We adhere to all applicable laws and regulations related
                      to the confidentiality of medical information, including
                      but not limited to the Health Insurance Portability
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      .
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Legal Requests:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      In the event of a legal request or requirement, we may
                      disclose your medical information as mandated by law, with
                      the aim of protecting your rights and complying with legal
                      obligations.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Your Responsibilities
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Secure Access:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      You are responsible for maintaining the security of your
                      account and ensuring that unauthorized access to your
                      medical information is prevented.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Informed Consent: By using our services, you provide
                      informed consent for the collection and use of your
                      medical information as outlined in these terms.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.25pt",
                  paddingLeft: "4.75pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Communication Security
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Secure Communication Channels: All communication between
                      you and our healthcare professionals occurs through secure
                      and encrypted channels to protect the confidentiality of
                      the information exchanged.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 29.13pt",
                  paddingLeft: "6.87pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Changes to the Terms
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 8pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Notification of Changes:
                    </span>
                    <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                      We reserve the right to update and modify these terms. You
                      will be notified of any significant changes, and your
                      continued use of our services implies acceptance of the
                      updated terms.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                By using{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                HealthKo.
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>O</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                nline doctor consultancy services, you acknowledge that you have
                read, understood, and agree to the terms outlined in this
                Confidentiality of Medical Information section. If you have any
                questions or concerns, please contact our support team a
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                t{" "}
              </span>
              <a style={{ color: "#0563c1" }} href="support@healthko.in">
                <span
                  style={{
                    color: "#0563c1",
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    textDecoration: "underline",
                  }}
                >
                  s
                </span>
                <span
                  style={{
                    color: "#0563c1",
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    textDecoration: "underline",
                  }}
                >
                  upport@healthko.in
                </span>
              </a>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                g.{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                Legal
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                {" "}
                Compliance
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>:</span>
              <br />
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                &nbsp;
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Medical Regulations and Licensing: The{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo.
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                ></span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  P
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  latform operates in compliance with all applicable medical
                  regulations and licensing requirements. All participating
                  healthcare professionals are duly licensed and authorized to
                  provide online medical consultations in their respective
                  jurisdictions.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Patient Confidentiality:{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo.
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  is committed to maintaining the confidentiality of patient
                  information in accordance with the Health Insurance
                  Portability and Accountability Act (HIPAA) and other relevant
                  data protection laws. All personal health information shared
                  on the platform is handled with the utmost care and in
                  compliance with applicable privacy regulations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Telemedicine Laws: The platform complies with all telemedicine
                  laws and regulations governing the provision of medical
                  services remotely. Users are encouraged to familiarize
                  themselves with the specific regulations applicable to their
                  location and are responsible for adhering to them.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.27pt",
                  paddingLeft: "2.73pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Informed Consent: Users of{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo.
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  affirmatively acknowledge and consent to the provision of
                  medical services through the online platform. They understand
                  the limitations and benefits of online consultations and agree
                  to the terms outlined in the platform's informed consent
                  policy.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.75pt",
                  paddingLeft: "5.25pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Prescription Medication: The platform strictly adheres to
                  regulations governing the prescription and dispensation of
                  medication. Prescriptions are issued based on a thorough
                  evaluation of the patient's medical history and condition, and
                  in compliance with relevant laws.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.27pt",
                  paddingLeft: "2.73pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Dispute Resolution: In the event of any legal disputes arising
                  between users and{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  , both parties agree to attempt resolution through mediation
                  or arbitration, in accordance with{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Indian
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  laws. Any disputes not resolved through alternative methods
                  will be subject to the exclusive jurisdiction of the courts in{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  New Delhi
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 35.79pt",
                  paddingLeft: "0.21pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Changes to Terms and Conditions:{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  reserves the right to modify these Terms and Conditions at any
                  time. Users will be notified of any changes, and continued use
                  of the platform after such modifications constitutes
                  acceptance of the updated terms.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By using the{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                platform, users agree to comply with all applicable laws and
                regulations, as well as the terms outlined in these Terms and
                Conditions.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "12pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                h.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                Cooki
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              >
                es and Tracking:
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "12pt",
                  fontStyle: "normal",
                }}
              ></span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                This Cookies and Tracking section outlines the use of cookies
                and tracking technologies by{" "}
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                HealthKo
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>.</span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}> </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                We
              </span>
              <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}>
                in connection with the provision of online doctor consultancy
                services through our platform.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 0pt 36pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.05pt",
                  paddingLeft: "4.95pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Cookies: Small pieces of data stored on the user's device.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Tracking Technologies: Technologies such as web beacons,
                  pixels, and scripts used to collect and track user
                  interactions with our platform.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.43pt",
                  paddingLeft: "5.57pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Consent to Cookies
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  By using our platform, you consent to the use of cookies and
                  tracking technologies as described in this section.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.25pt",
                  paddingLeft: "4.75pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Types of Cookies Used
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Essential Cookies: These cookies are necessary for the
                      proper functioning of our platform. They enable core
                      functionalities such as user authentication and account
                      management.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Analytical Cookies: We use analytical cookies to collect
                      information about how users interact with our platform.
                      This helps us improve the performance and usability of our
                      services.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Third-Party Cookies: Some third-party services integrated
                      into our platform may use cookies. We do not have control
                      over these cookies and recommend reviewing the respective
                      third-party privacy policies for more information.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 29.13pt",
                  paddingLeft: "6.87pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Purpose of Tracking Technologies
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.95pt",
                  paddingLeft: "5.05pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We use tracking technologies for the following purposes:
                </span>
                <ol type="I" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Performance Monitoring: To monitor and analyze the
                      performance of our platform.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      User Experience Improvement: To enhance the user
                      experience and tailor our services to user preferences.
                    </span>
                  </li>
                  <li
                    style={{
                      fontFamily: "Calibri",
                      fontSize: "11pt",
                      lineHeight: "108%",
                      margin: "0pt 0pt 0pt 23pt",
                      paddingLeft: "13pt",
                      textIndent: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "11pt",
                        fontStyle: "normal",
                      }}
                    >
                      Security: To ensure the security of our platform and
                      prevent fraudulent activities.
                    </span>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Managing Cookies
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Users can manage cookie preferences through their browser
                  settings. However, disabling certain cookies may affect the
                  functionality of our platform.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.41pt",
                  paddingLeft: "7.59pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Do Not Track (DNT) Signals
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.78pt",
                  paddingLeft: "5.22pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We do not currently respond to "Do Not Track" signals.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Updates to this Policy
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 34.56pt",
                  paddingLeft: "1.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We may update this Cookies and Tracking section to reflect
                  changes in technology or legal requirements. Users will be
                  notified of any material changes.
                </span>
                <br />
                <br />
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  &nbsp;
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                5.Medical Disclaimer:
              </span>
            </p>
            <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.05pt",
                  paddingLeft: "4.95pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  No Doctor-Patient Relationship: The use of HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Online Doctor Consultancy Services ("the Service") does not
                  establish a doctor-patient relationship. The information
                  provided through the Service is for general informational
                  purposes only and should not be considered as medical advice.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Qualified Healthcare Professionals: While we strive to connect
                  you with qualified healthcare professionals,{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  does not employ doctors directly. The healthcare professionals
                  available through the Service are independent practitioners
                  who are solely responsible for their services.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.43pt",
                  paddingLeft: "5.57pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Emergency Situations: The Service is not intended for
                  emergencies. If you are experiencing a medical emergency,
                  please call your local emergency number immediately or visit
                  the nearest emergency room.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Accuracy of Information: We make every effort to provide
                  accurate and up-to-date information, but we do not guarantee
                  the accuracy, completeness, or usefulness of any information
                  provided through the Service. Reliance on any information is
                  at your own risk.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.25pt",
                  paddingLeft: "4.75pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Consultation Limitations: The Service is not a substitute for
                  a face-to-face consultation with a healthcare provider. It is
                  essential to consult with a qualified healthcare professional
                  for a comprehensive evaluation of your medical condition.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 29.13pt",
                  paddingLeft: "6.87pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Prescription Medications:{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  does not prescribe medications directly. Any prescriptions or
                  treatment plans are provided by the independent healthcare
                  professionals, and you are encouraged to consult with your
                  primary care physician for ongoing care.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.95pt",
                  paddingLeft: "5.05pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Confidentiality: While we take measures to ensure the
                  confidentiality of your information, the security of online
                  communication cannot be guaranteed. By using the Service, you
                  acknowledge and accept this limitation.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 31.56pt",
                  paddingLeft: "4.44pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  User Responsibilities: You are responsible for providing
                  accurate and complete information about your medical history
                  and current symptoms. Failure to do so may result in
                  inaccurate advice or recommendations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Legal and Regulatory Compliance: Users of the Service are
                  responsible for complying with all applicable laws and
                  regulations regarding the use of telemedicine services in
                  their jurisdiction.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 28.41pt",
                  paddingLeft: "7.59pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Changes to Terms:{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  reserves the right to modify these terms and conditions at any
                  time. Users are encouraged to review the terms regularly for
                  updates.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By using the{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Online Doctor Consultancy Services, you agree to these terms and
                conditions.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                6
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                Registration and Account:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a. User Registration:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By registering on our platform, you agree to provide accurate,
                current, and complete information about yourself during the
                registration process.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. You are responsible for maintaining the confidentiality of
                your account and password and for restricting access to your
                computer or device to prevent unauthorized access to your
                account.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                b. Account Security:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. You are solely responsible for all activities that occur
                under your account.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. Notify us immediately of any unauthorized use of your
                account or any other breach of security.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                c. Account Termination:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. We reserve the right to terminate or suspend your account at
                any time without prior notice if we have reason to believe that
                you have violated these terms or engaged in any fraudulent or
                unlawful activities.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                d. User Responsibilities:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. Users are responsible for the accuracy and completeness of
                the information provided during registration.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. Users agree not to impersonate any person or entity or
                falsely state or otherwise misrepresent their affiliation with a
                person or entity.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                e. Minors:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. Users under the age of 18 must have the consent of a parent
                or legal guardian to use our services. ii. We reserve the right
                to refuse service, terminate accounts, or remove or edit content
                for users under the age of 1
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                8
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                without parental consent.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                f. Account Information Updates:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. Users are responsible for keeping their account information
                up to date.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. Promptly update any changes to your contact information,
                including email address and phone number.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                g. One Account per User:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. Each user is allowed to have only one account on our
                platform.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. Creating multiple accounts may result in the termination of
                all associated accounts.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                h. Account Access:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. Users are responsible for ensuring that their devices meet
                the technical requirements for accessing and using our services.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. We are not responsible for any technical issues or
                limitations on your ability to access the platform.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i..
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Confidentiality: a. Medical Information:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. All interactions between users and healthcare providers are
                confidential.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. Users are responsible for maintaining the confidentiality of
                their medical information and are advised not to share sensitive
                information in public forums.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                b. Data Security:{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i. We employ industry-standard security measures to protect user
                data.{" "}
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                ii. Users are responsible for maintaining the security of their
                login credentials and reporting any suspicious activity.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By registering and using our services, you acknowledge that you
                have read, understood, and agree to abide by these terms and
                conditions.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                7.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                Consultation and Fee Payments
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                :
              </span>
              <br />
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              ></span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Consultation Services:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Our online doctor consultancy services provide users with the
                  opportunity to engage in virtual consultations with qualified
                  healthcare professionals. These consultations may include
                  medical advice, diagnosis, and treatment recommendations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Users must ensure that all information provided during the
                  consultation is accurate and complete. Any misinformation may
                  impact the quality of the consultation and subsequent medical
                  recommendations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Consultations are not a substitute for in-person medical
                  examinations. Users are advised to seek in-person medical
                  attention for emergencies or situations requiring physical
                  examinations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 33.27pt",
                  paddingLeft: "2.73pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  The consultation services offered are based on the information
                  provided by the user. The healthcare professional will make
                  recommendations based on the available data and may request
                  additional information if necessary.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                b.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Fee Payments:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Users are required to make payments for the consultation
                  services as specified on our platform. The fees may vary
                  depending on the type and duration of the consultation.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Payment must be made in advance of the scheduled consultation.
                  Failure to make timely payments may result in the cancellation
                  or rescheduling of the consultation.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We accept payments through{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  UPI, All credit/Debit card
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  ,
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Internet banking,
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  And many other Payment option
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  s
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  and users are responsible for any transaction fees associated
                  with their chosen payment method.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 33.27pt",
                  paddingLeft: "2.73pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Refunds will only be issued in accordance with our refund
                  policy, outlined in a separate section of these terms and
                  conditions.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.75pt",
                  paddingLeft: "5.25pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Prices for consultation services are subject to change, and
                  any changes will be communicated to users in advance.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                c.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Cancellation and Rescheduling:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Users may request to cancel or reschedule a consultation up to{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  1
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  hours before the scheduled appointment. Cancellations made
                  after this timeframe may not be eligible for a refund.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  I
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  f user take consultation immediate service
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  to{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  consult to doctor
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  (within 60sec to get consultation service) in that case if you
                  are pay that amount and doctor allocated to you
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  and you are not present
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  at virtual consultation or video consultation
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  then at that condition amount will not refunded
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  The company reserves the right to cancel or reschedule
                  consultations in the event of unforeseen circumstances or
                  technical issues. In such cases, users will be notified
                  promptly, and alternative arrangements will be made.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                d.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Confidentiality:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  All information shared during the consultation will be treated
                  with the utmost confidentiality in accordance with our privacy
                  policy.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Users are responsible for maintaining the confidentiality of
                  their login credentials and must not share their account
                  information with third parties.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By using our online doctor consultancy services, users agree to
                abide by the terms and conditions outlined in this section. The
                company reserves the right to update or modify these terms at
                any time, and users will be notified of any changes. It is the
                responsibility of users to review and understand these terms
                before accessing our services.
              </span>
              <br />
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                8. Cancellation and Refund Policy:
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                We at{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                understand that circumstances may arise where you need to cancel
                your online doctor consultancy services or request a refund.
                Please read the following cancellation and refund policy
                carefully to ensure a smooth process:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Cancellation Policy:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Cancellations by User: Users may cancel their scheduled online
                  doctor consultations up to{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  1
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  hours before the scheduled appointment time. To initiate a
                  cancellation, users must log in to their account on our
                  platform and follow the designated cancellation process.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Late Cancellations: Cancellations made within{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  1
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  hours of the scheduled appointment may be subject to a
                  cancellation fee. The specific fee amount will be outlined
                  during the cancellation process and is designed to compensate
                  our healthcare professionals for their time.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  If user take consultation immediate service to consult to
                  doctor (within 60sec to get consultation service) in that case
                  if you are pay that amount and doctor allocated to you and{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  you are not present at virtual consultation or video
                  consultation then at that condition amount will not refunded
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 23pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  No-Show Policy: If a user fails to attend a scheduled online
                  consultation without prior notice, it will be considered a
                  "no-show." No-shows may be subject to the full consultation
                  fee, and rescheduling may be at the discretion of the
                  healthcare professional.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Refund Policy:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Refunds for Cancellations: Users who cancel their appointments
                  within the stipulated time frame will be eligible for a refund
                  of the consultation fee, excluding any applicable cancellation
                  fees.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Refunds for Technical Issues: In the event of technical issues
                  preventing the successful completion of an online
                  consultation, users may request a refund. Such requests will
                  be assessed on a case-by-case basis, and users are encouraged
                  to contact our customer support team with relevant details.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Refunds for Dissatisfaction: If a user is dissatisfied with
                  the quality of the online consultation, they may request a
                  refund by contacting our customer support team. Refund
                  requests based on dissatisfaction will be reviewed, and
                  decisions will be made at the discretion of{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  HealthKo
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  .
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Refund Process:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Initiating Refund Requests: Users can initiate refund requests
                  by contacting our customer support team through the designated
                  channels provided on our platform.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Refund Processing Time: Refunds will be processed within{" "}
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  1
                </span>
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  business days after the approval of the refund request. The
                  time it takes for the refund to be reflected in the user's
                  account may vary based on the payment method used.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Changes to the Policy:{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                reserves the right to update or modify this cancellation and
                refund policy at any time without prior notice. Users are
                encouraged to review this policy periodically for any changes.
              </span>
              <br />
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                9. Code of Conduct:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Welcome to{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                , a platform dedicated to providing online doctor consultancy
                services. To ensure a safe, secure, and respectful environment
                for all users, we have established this Code of Conduct that
                outlines the expected behavior of users engaging with our
                services.
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By using our platform, you agree to adhere to the following
                principles:
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Professionalism and Respect
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Respect for Others: Treat doctors, support staff, and fellow
                  users with courtesy and respect. Do not engage in any form of
                  harassment, discrimination, or offensive behavior.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Professional Communication: Maintain a professional and
                  constructive tone in all communications. Avoid the use of
                  offensive language, threats, or any form of communication that
                  may be deemed inappropriate.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Privacy and Confidentiality
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 10.3pt",
                  paddingLeft: "8.9pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Patient Privacy: Protect the privacy of patients and their
                  confidential information. Do not share personal details or any
                  sensitive information without explicit consent.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 12.83pt",
                  paddingLeft: "6.37pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Doctor-Patient Confidentiality: Doctors are expected to adhere
                  to the same standards of confidentiality as in traditional
                  healthcare settings. Users must also respect the
                  confidentiality of the medical professionals.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Compliance with Laws and Regulations
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Legal Compliance: Users must comply with all applicable laws
                  and regulations related to online healthcare services. This
                  includes but is not limited to privacy laws, medical
                  regulations, and licensing requirements.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Prescription Guidelines: Users seeking prescription
                  medications must comply with relevant regulations and provide
                  accurate information during the consultation process.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                d
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Security and Technology Use
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Security Measures: Users are expected to adhere to security
                  measures and guidelines provided by the platform. Unauthorized
                  access, hacking, or any attempt to compromise the integrity of
                  the platform is strictly prohibited.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Technology Etiquette: Users should use the platform
                  responsibly and avoid any disruptive or harmful activities,
                  including the distribution of malware or engaging in
                  activities that may harm the platform or its users.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                e
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Feedback and Dispute Resolution
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Constructive Feedback: Provide constructive feedback to help
                  improve the platform and services. Avoid using inappropriate
                  language or making false accusations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Dispute Resolution: In the event of a dispute, users are
                  encouraged to engage in a constructive dialogue. If a
                  resolution cannot be reached, the platform may provide
                  mediation or arbitration services.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                e
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Reporting Violations
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                i
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Reporting Violations: Users who witness or experience any
                violations of this Code of Conduct{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                are encouraged to report them promptly to{" "}
              </span>
              <a style={{ color: "#0563c1" }} href="support@healthko.in">
                <span
                  style={{
                    color: "#0563c1",
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    textDecoration: "underline",
                  }}
                >
                  support@hea
                </span>
                <span
                  style={{
                    color: "#0563c1",
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    textDecoration: "underline",
                  }}
                >
                  lthko.in
                </span>
              </a>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                f
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                . Consequences of Violations
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Enforcement: Failure to adhere to this Code of Conduct may
                  result in various actions, including warnings, suspension, or
                  permanent removal of access to the platform.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Legal Action: Serious violations may result in legal action
                  being taken against the responsible party.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "14pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                By using our platform, you acknowledge that you have read,
                understood, and agreed to abide by this Code of Conduct.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                HealthKo
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                reserves the right to update or modify this Code of Conduct at
                any time. Users will be notified of any changes, and continued
                use of the platform constitutes acceptance of the revised Code
                of Conduct.
              </span>
              <br />
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                10.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                M
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                edical Records:
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "14pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                a
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Information Collection and Storage:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  By availing our online doctor consultancy services, you agree
                  to provide accurate and complete medical information during
                  the registration and consultation process.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We collect and store your medical records securely, adhering
                  to all applicable data protection laws and regulations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 33.35pt",
                  paddingLeft: "2.65pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Your medical records are encrypted and stored on servers with
                  robust security measures to prevent unauthorized access.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Authorized Access:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Access to your medical records is strictly limited to
                  authorized personnel, including our qualified healthcare
                  professionals involved in your consultation and treatment.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We do not share your medical information with any third
                  parties without your explicit consent, except where required
                  by law.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Confidentiality:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We prioritize the confidentiality of your medical records and
                  follow industry best practices to safeguard your sensitive
                  information.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Our platform employs encryption protocols and secure
                  communication channels to protect the confidentiality of your
                  medical data.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                d
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Data Usage:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Your medical records are utilized solely for the purpose of
                  providing you with accurate and personalized healthcare advice
                  and treatment.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Aggregate and de-identified data may be used for research and
                  statistical analysis to enhance the quality of our services,
                  ensuring the protection of individual privacy.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                e
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Data Retention:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We retain your medical records for a reasonable period, as
                  required for the provision of ongoing healthcare services and
                  to comply with legal and regulatory obligations.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  You may request the deletion of your medical records upon the
                  termination of your account, subject to any legal and ethical
                  obligations.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                f
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Patient Rights:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  You have the right to access, review, and request corrections
                  to your medical records.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  You may withdraw consent for the collection and storage of
                  your medical information at any time, with the understanding
                  that it may impact the quality of the services we can provide.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Compliance with Laws:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Our company complies with all applicable laws and regulations
                  governing the collection, storage, and transmission of medical
                  records.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  We reserve the right to disclose your medical information if
                  required by law, legal process, or governmental request.
                </span>
              </li>
            </ol>
            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                .{" "}
              </span>
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                Updates to Terms and Conditions:
              </span>
            </p>
            <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 0pt 28.3pt",
                  paddingLeft: "7.7pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  These terms and conditions may be updated to reflect changes
                  in technology, laws, and our business practices. You will be
                  notified of any material changes.
                </span>
              </li>
              <li
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  lineHeight: "108%",
                  margin: "0pt 0pt 8pt 30.83pt",
                  paddingLeft: "5.17pt",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: "Calibri",
                    fontSize: "11pt",
                    fontStyle: "normal",
                  }}
                >
                  Continued use of our services after the effective date of
                  updated terms constitutes acceptance of the revised terms.
                </span>
                <br />
                <br />
                <br />
                <br />
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  By using HealthKo you acknowledge that you have read,
                  understood, and agree to abide by the terms{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  and{" "}
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  You Must acce
                </span>
                <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                  pt this terms and conditions to use.
                </span>
              </li>
            </ol>

            <p
              style={{
                fontSize: "11pt",
                lineHeight: "108%",
                margin: "0pt 0pt 8pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                  fontStyle: "normal",
                }}
              >
                &nbsp;
              </span>
            </p>
          </div>
        </>
      </div>

      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          textAlign: "justify",
          letterSpacing: "0px",
        }}
      >
        <h2>• Registered Address for Communication</h2>
        <>
          <span
            style={{
              marginLeft: "1%",
              lineHeight: "108%",
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Get in Touch with Us:
          </span>

          <ol type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginLeft: "33.24pt",
                lineHeight: "108%",
                paddingLeft: "2.76pt",
                fontSize: "14pt",
              }}
            >
              <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                Address:{" "}
              </span>
              <br />
              <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                AHS HEALTHCARE PRIVATE LIMITED Plot No-7,Third Floor,New Number
                -7A,BLK-B2,Uttam Nagar,New Delhi,West Delhi-110059,Delhi
              </span>
            </li>
            <li
              style={{
                marginLeft: "33.24pt",
                lineHeight: "108%",
                paddingLeft: "2.76pt",
                fontSize: "14pt",
              }}
            >
              <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                Contact Information:{" "}
              </span>
              <br />
              <span style={{ lineHeight: "108%", fontSize: "11pt" }}>
                Mo-7339966643 <br />
                Mail- support@healthko.in
              </span>
            </li>
          </ol>
        </>

        <p
          style={{
            fontSize: "11pt",
            lineHeight: "108%",
            margin: "0pt 0pt 8pt",
          }}
        >
          <span
            style={{
              fontFamily: "Calibri",
              fontSize: "11pt",
              fontStyle: "normal",
            }}
          >
            &nbsp;
          </span>
        </p>
      </div>
    </>
  );
};

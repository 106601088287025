import React from 'react';
import ModalComponent from 'react-modal';
import { IoMdCloseCircle } from 'react-icons/io';
import { CloseBtn } from './ModalElements';
import { useAuthContext } from '../../hooks/AuthContextHook';
import { useNavigate } from 'react-router-dom';

function AuthModal({ closeModal, label, children }) {
  const { auth } = useAuthContext();
  const navigate = useNavigate();
  return (
    <ModalComponent
      isOpen={auth.isAuthModalOpen}
      onRequestClose={() => {
        navigate('/');
        closeModal();
      }}
      className='Modal'
      overlayClassName='Overlay_zIndex'
      shouldCloseOnOverlayClick={false}
    >
      <div>
        <CloseBtn
          onClick={() => {
            navigate('/');
            closeModal();
          }}
        >
          <IoMdCloseCircle />
        </CloseBtn>

        {children}
      </div>
    </ModalComponent>
  );
}

export default AuthModal;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import Icon1 from '../../images/v1/services-purple.svg';
// import Icon2 from '../../images/v1/services-yellow.svg';
// import Icon3 from '../../images/v1/services-black.svg';
// import {
//   ServiceCard,
//   ServiceContainer,
//   ServiceH2,
//   ServiceIcon,
//   ServiceWrapper,
//   SectionParagraph,
// } from './ServiceElements';
// import { Container, SectionHeading, Paragraph } from '../commonComponents';
// import { useAuthContext } from '../../hooks/AuthContextHook';

// function Service() {
//   const navigate = useNavigate();
//   const { auth, updateAuth } = useAuthContext();

//   function handleNavigation(url) {
//     if (auth.isLogged) {
//       navigate(url);
//     } else updateAuth({ ...auth, isAuthModalOpen: true });
//   }

//   return (
//     <Container id='services'>
//       <ServiceContainer>
//         <SectionHeading>Our Services</SectionHeading>
//         <Paragraph>
//           "Empowering health, enriching lives: your partner in personalized
//           healthcare"
//         </Paragraph>
//         <ServiceWrapper>
//           <ServiceCard onClick={() => handleNavigation('/New-Video-Consultation')}>
//             <ServiceIcon src={Icon1} />
//             <ServiceH2>Video Consultation</ServiceH2>
//             <SectionParagraph>Connect within 60 secs</SectionParagraph>
//           </ServiceCard>
//           <ServiceCard onClick={() => handleNavigation('/doctor-at-home')}>
//             <ServiceIcon src={Icon2} />
//             <ServiceH2>Doctor at Home</ServiceH2>
//             <SectionParagraph>Expert doctors at your home</SectionParagraph>
//           </ServiceCard>
//           <ServiceCard onClick={() => handleNavigation('/clinic-appointment')}>
//             <ServiceIcon src={Icon3} />
//             <ServiceH2>Clinic Appointment</ServiceH2>
//             <SectionParagraph>Confirmed appointment</SectionParagraph>
//           </ServiceCard>
//         </ServiceWrapper>
//       </ServiceContainer>
//     </Container>
//   );
// }

// export default Service;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon1 from '../../images/v1/services-purple.svg';
import Icon2 from '../../images/v1/services-yellow.svg';
import Icon3 from '../../images/v1/services-black.svg';
import Icon4 from '../../images/v1/AI-doctor.svg';
import Icon5 from '../../images/v1/surgery.svg';
import {
  ServiceCard,
  ServiceContainer,
  ServiceH2,
  ServiceIcon,
  ServiceWrapper,
  SectionParagraph,

} from './ServiceElements';
import { Container, SectionHeading, Paragraph } from '../commonComponents';

function Service() {
  const navigate = useNavigate();

  function handleNavigation(url) {
    navigate(url);
  }

  return (
    <Container id='services'>
      <ServiceContainer>
        <SectionHeading>Our Services</SectionHeading>
        <Paragraph>
          "Empowering health, enriching lives: your partner in personalized
          healthcare"
        </Paragraph>
        <ServiceWrapper>
          <ServiceCard onClick={() => handleNavigation('/Online-Doctor-Consultation')}>
            <ServiceIcon src={Icon1} />
            <ServiceH2>Video Consultation</ServiceH2>
            <SectionParagraph>Connect with Expert Doctor with in 30 sec powered by Artificial intelligence</SectionParagraph>
          </ServiceCard>
          <ServiceCard onClick={() => handleNavigation('/Doctor-Home-Visit')}>
            <ServiceIcon src={Icon2} />
        
            <ServiceH2>Doctor at Home</ServiceH2>
            <SectionParagraph>Expert Doctor at your Doorstep with vertical integration and Artificial intelligence</SectionParagraph>
   
          </ServiceCard>
          <ServiceCard onClick={() => handleNavigation('/Book-An-Appointment')}>
            <ServiceIcon src={Icon3} />
   
            <ServiceH2>Clinic Appointment</ServiceH2>
            <SectionParagraph>Streamline your Clinics appointments with the help of Artificial Intelligence.</SectionParagraph>
    
          </ServiceCard>
          
           <ServiceCard onClick={() => alert('Coming Soon')}>
            <ServiceIcon src={Icon4} />
            <ServiceH2>A.I Doctor</ServiceH2>
            <SectionParagraph>Free Artificial intelligence powered Doctor answer all you General Health Queries.</SectionParagraph>
        </ServiceCard>
        <ServiceCard onClick={() => alert('Coming Soon')}>
            <ServiceIcon src={Icon5} />
            <ServiceH2>Plan You Surgery</ServiceH2>
            <SectionParagraph>Book your Surgery with HealtKo Centre( Multi-specialist Clinic ) or HealthKo Partner Hospitals with the Utilisation of A.I and 3D Body laser Technology</SectionParagraph>
        </ServiceCard> 
          
        </ServiceWrapper>
      </ServiceContainer>
    </Container>
  );
}

export default Service;

import styled from "styled-components";
import { FaArrowRight } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const imageStyles = {
  display: 'flex',
  maxWidth: '100%',
  height: '100%',
  marginBottom: '20px',
};

export const StyledHeading = styled.h1`
 color: #1B263B;
  font-size: 36px;
  font-weight: 400;
  font-family: Roboto;
  line-height: 42.19px;
  letter-spacing: -2%;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
`;


export const StyledParagraph = styled.p`
  color: #696969;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  padding: 10px 30px 20px 30px;
`;

export const StyledParagraphs = styled.p`
  color: #696969;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  letter-spacing: -2%;
  text-align: center;
  padding: 5px 30px 30px 30px;
`;
export const StyledHeadings = styled.h1`
  color: #1B263B;
  font-size: 36px;
  font-weight: 400;
  font-family: Roboto;
  line-height: 42.19px;
  letter-spacing: -2%;
  margin-top: 30px;
 margin-bottom: 10px;
  text-align: center;
`;

export const Question = styled.div`
  background-color: ${props => props.isOpen ? '#C4E8F9' : '#fff'};
  cursor: pointer;
  border: 1px solid #C6C6C6; 
  border-radius: 5px;
  margin-bottom: 20px; 
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 14px ; 
  box-sizing: border-box;
`;
export const QuestionContent = styled.div`
   padding: 0 20px 0 20px;
`;

export const DropdownIcon = styled.div`
  color: #555; /* Color of the dropdown icon */
`;

export const Answer = styled.div`
  color: #696969;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  padding: 10px 30px 20px 30px;
`;
export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column; 
  width: 100%;
  max-width: 75%; 
  margin: 0 auto;
  padding: 20px; 
  @media screen and (max-width: 500px) {
    display: flex;
  flex-direction: column; 
  width: 100%;
  max-width: 95%; 
  margin: 0 auto;
  padding: 20px; 
  }

`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; /* Wrap items to the next row if there's not enough space */
  width: 100%;
  margin: 0 auto; /* Center the container */
  padding: 20px; 
`;

export const StepItem = styled.div`
flex: 1; 
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 40px; 
position: relative;
`;

export const StepImage = styled.img`
  width: 70px;
  height: 70px;

`;

export const StepText = styled.p`
 text-align: center;
  font-size: 18px;
  margin-top: 20px;
`;


export const Circle = styled.div`
width: 100px;
height: 100px; 
background-color: #87CEFA; 
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;

`;

export const StepLine = styled.div`
  width: 380px;
  height: 2px;
  position: absolute;
  left: 100%; 
  top: 39.4%; 
  transform: translate(-50%, -1px); 
  background-color: #0073FE;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 1500px) {
    width: 280px;
  height: 2px;
  position: absolute;
  left: 99%; 
  top: 40.4%; 
  transform: translate(-50%, -1px); 
  background-color: #0073FE;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  @media screen and (max-width: 1200px) {
    width: 150px;
  height: 2px;
  position: absolute;
  left: 99%; 
  top: 33.4%; 
  transform: translate(-50%, -1px); 
  background-color: #0073FE;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  @media screen and (max-width: 788px) {
    width: 50px;
  height: 2px;
  position: absolute;
  left: 99%; 
  top: 33.4%; 
  transform: translate(-50%, -1px); 
  background-color: #0073FE;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  @media screen and (max-width: 667px) {
    width: 25px;
  height: 2px;
  position: absolute;
  left: 99%; 
  top: 30.4%; 
  transform: translate(-50%, -1px); 
  background-color: #0073FE;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  @media screen and (max-width: 437px) {
    width: 10px;
  height: 2px;
  position: absolute;
  left: 99%; 
  top: 27.4%; 
  transform: translate(-50%, -1px); 
  background-color: #0073FE;
  display: flex;
  align-items: center;
  justify-content: center;
  }
`;

export const StyledArrowRight = styled(FaArrowRight)`
  color: #0073FE; /* Adjust the color of the arrow icon */
  position: absolute;
  top: 48.5px; /* Adjust vertical position as needed */
  right: -195px; /* Adjust horizontal position as needed */
  font-size: 14px;

  @media screen and (max-width: 1500px) {
   
  position: absolute;
  top: 48.5px; /* Adjust vertical position as needed */
  right: -145px; /* Adjust horizontal position as needed */
  font-size: 14px;
  }
  @media screen and (max-width: 1200px) {
   
  position: absolute;
  top: 47.5px; /* Adjust vertical position as needed */
  right: -78px; /* Adjust horizontal position as needed */
  font-size: 14px;
  }
  @media screen and (max-width: 788px) {
   
  position: absolute;
  top: 47.5px; /* Adjust vertical position as needed */
  right: -30px; /* Adjust horizontal position as needed */
  font-size: 14px;
  }
  @media screen and (max-width: 667px) {
    
  position: absolute;
  top: 49.5px;
  right: -20px; 
  font-size: 14px;
  }
  @media screen and (max-width: 437px) {
  
  position: absolute;
  top: 50.5px; 
  right: -10px; 
  font-size: 12px;
  }
`;

export const StepLineWithArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    /* Adjustments for smaller screens */
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 60px;
  width: auto;
  max-width: 95%; /* Allow the container to expand */
  margin-right: 10px; /* Add margin to the right side of the container */
`;

export const SearchInput = styled.input`
  width: 50%;
  height: 40px;
  padding: 0 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px; /* Add margin between the input and the button */
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  left: 75px;
  top: 50%;
  transform: translateY(-50%);
  color: #00AFFE;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .text-overlay {
    position: absolute;
    top: 50%;
    left: 34%;
    transform: translate(-50%, -50%);
    color: #262c2f;
    text-align: start;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
 
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.4rem;
    font-family: bold;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1256px) {
    .text-overlay {
      padding: 10px;
      max-width: 90%;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 994px) {
    .text-overlay {
      padding: 5px;
      max-width: 95%;
    }

    h1 {
      font-size: 1.4rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 580px) {
    .text-overlay {
      padding: 5px;
      max-width: 95%;
    }

    h1 {
      font-size: 0.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.3rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 0.5rem;
      margin-bottom: 5px;
    }
  }
`;

export const StyledButton = styled.button`
  /* Define your button styles here */
  background-color: #0073FE;
  font-size: 14px;
  margin-top: 20px;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  position: relative;

  &:hover {
    &:before {
      border-radius: 15px;
      content: "";
      position: absolute;
      left: -2.5px;
      top: -2px;
     
      width: 100%;
      height: 100%;
      border-top: 3px solid #0073FE; /* Adjust this value as needed for your line thickness */
      border-bottom: 3px solid #0073FE; /* Adjust this value as needed for your line thickness */
      border-left: 3px solid #0073FE; /* Adjust this value as needed for your line thickness */
      border-right: 3px solid #0073FE; /* Adjust this value as needed for your line thickness */
    }
  }

  @media screen and (max-width: 580px) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;

export const NavSection = styled.div`
  display: flex;
  justify-content: start;
  margin: 20px 0;
`;

export const NavLink = styled(Link)`
  margin: 0 35px; /* Reduced the margin here to create less gap */
  padding: 1px 5px; /* Reduced the padding here */

  color: black;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:active {
    background-color: #0056b3; /* Change to the desired background color on click */
  }
`;export const CardContainer = styled.div`
width: 100%;
height: 600px;
text-align: center;
/* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
/* background: linear-gradient( #CAF0F8, #D8F3DC); */
background-color: #ffffff;
padding: 40px 20px; /* Adjust padding */
margin-bottom: 40px;
h1 {
  padding-bottom: 40px;
}
h2{
padding-top: 10%;
font-size: 35px;
color: #1B263B;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
p {

font-size: 13px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
`;

export const ButtonsContainer = styled.div`
display: flex;
align-items: center;
justify-content: start; /* Add space between the buttons */
flex-wrap: wrap;
`;
export const BookByDateButton = styled(StyledButton)`
display: flex;
align-items: center;
justify-content: center; 
margin-top: 20px;
margin-right: 30px; /* Add margin-right */
width: 263px;
height: 100px;
border-radius: 12px;
background-color: #FFFFFF;
box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.35);
color: #000;
font-size: 23px;
font-weight: 700;
line-height: 28.13px;
margin-bottom: 20px;
margin-left: 30px;
text-decoration: none;
`;

export const DoctorVisitButton = styled(StyledButton)`
display: flex;
align-items: center;
justify-content: center; 
margin-top: 20px;
margin-left: 20px; /* Set margin-left to 0 */
width: 297px;
height: 100px;
border-radius: 12px;
background-color: #FFFFFF;
box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.35);
color: #000;
font-size: 23px;
font-weight: 700;
line-height: 28.13px;
margin-bottom: 20px;
text-decoration: none;
`;


export const CalendarImage = styled.img`
width: 60px; 
height: 60px;
`;
export const DoctorVisitImage = styled.img`
width: 60px; 
height: 60px;
`;
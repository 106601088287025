import React, { useEffect } from 'react';
import ClinicAppointment from '../components/ClinicAppointment/ClinicAppointment';
import { useAuthContext } from '../hooks/AuthContextHook';

function ClinicAppointmentPage() {
  const { auth, updateAuth } = useAuthContext();
  useEffect(() => {
    if (!auth.isLogged) updateAuth({ ...auth, isAuthModalOpen: true });

    return () => {
      updateAuth({ ...auth, isAuthModalOpen: false });
    };
  }, []);
  return <ClinicAppointment />;
}

export default ClinicAppointmentPage;

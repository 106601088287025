import React, { useState , useEffect} from 'react';
import { FaAngleDown, FaArrowRight } from 'react-icons/fa';
import videoConsultationImage from '../../images/v2/banner-clinic.png'
import Footer from '../Footer/Footer.jsx';



import {
  imageStyles,
  StyledHeading,
  StyledParagraph,
  Question,
  QuestionContent,
  DropdownIcon,
  Answer,
  FAQContainer,
  SearchBarContainer,
  SearchInput,
  SearchIcon,
  ImageContainer,
  StyledButton,
  NavSection,
  NavLink,
  StyledHeadings
  

} from "./NewServicesElements.js.js";
import SpecialistService from './Secialistcard/SpecialistService.jsx.jsx';
import BenifitOnlineConsultation from './BenifitOnlineConsultation.jsx';




const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

const SearchBar = () => {
  return (
    <SearchBarContainer>

      <SearchInput type="text" placeholder="Search for specialists..." />
      <SearchIcon />
     
    </SearchBarContainer>
  );
};


const NewClinicAppointment = () => {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleAppointmentClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ahsclient.app', '_blank'); // Opens appointment page
  };

  return (
    <div>
     <ImageContainer>
     <img src={videoConsultationImage} alt="Clinic Appointment" />
      <div className="text-overlay">
      <p className="subtitle">India's best online appointment scheduling platform</p>
        <h1>Book An Clinic Appointment </h1>
        <p>Personalized Care + Priority Access · Starts at just ₹99</p>
        <StyledButton onClick={handleAppointmentClick}>Book Appointment</StyledButton>
      </div>
    </ImageContainer>
     
      <StyledHeadings>Select Clinic Category</StyledHeadings>
      {/* <StyledParagraph>
        Leveraging the capabilities of modern technology, HealthKo provides accessible and effective healthcare solutions through remote video consultations with qualified medical professionals, augmented by state-of-the-art AI technology. With the simple click of a button, you can seamlessly connect with a licensed healthcare provider from the convenience of your own home. Our advanced AI-enhanced platform ensures a smooth and personalized healthcare experience tailored to your needs.
      </StyledParagraph> */}
      <SearchBar/>
      <NavSection>
      <NavLink exact to="" activeClassName="active">Gynecology</NavLink>
      <NavLink to="" activeClassName="active">General Physician</NavLink>
      <NavLink to="" activeClassName="active">Dermatology</NavLink>
      <NavLink to="" activeClassName="active">Sexology</NavLink>
      <NavLink to="" activeClassName="active">Psychiatry</NavLink>
      <NavLink to="" activeClassName="active">Pulmonology</NavLink>
      <NavLink to="" activeClassName="active">Hematology</NavLink>
    </NavSection>

      <SpecialistService/>
     
     <BenifitOnlineConsultation/>

      <StyledHeading>Frequently asked questions</StyledHeading>
      <FAQContainer>
      <DropdownItem
        question="How do I book a clinic appointment?"
        answer="You can book clinic appointments either by phone or online through our website. Simply choose a convenient time slot and provide your details."
      />
     <DropdownItem
        question="What information do I need to provide when booking?"
        answer="We require basic personal information such as your name, contact number, and reason for the appointment. Any relevant medical history will also be helpful."
      />
     <DropdownItem
        question="Can I reschedule or cancel my clinic appointment?"
        answer="Yes, you can reschedule or cancel your appointment by contacting the clinic either by phone or through our online booking system. Please provide at least 24 hours notice if possible."
      />
     <DropdownItem
        question="Can I book appointments for family members?"
        answer="Yes, you can book appointments on behalf of family members. Please provide their details accurately when making the booking, including any specific requirements or preferences."
      />
      <DropdownItem
        question="How far in advance can I book an appointment?"
        answer="You can typically book appointments up to a few weeks in advance, depending on availability. We recommend booking as early as possible to secure your preferred time slot."
      />
      <DropdownItem
        question="What do I need to bring to my appointment?"
        answer="Please bring any relevant medical documents and a list of current medications. It's also helpful to arrive a few minutes early to complete any necessary paperwork."
      />
       <DropdownItem
        question="Can I request a specific doctor?"
        answer="Yes, you can request a specific doctor when booking your appointment, subject to their availability. We will do our best to accommodate your preferences whenever possible."
      />
      </FAQContainer>
 
      <Footer />
    </div>
  );
}

export default NewClinicAppointment;

import React from 'react';
import {
  Card,
  CardContainer,
  Container,
  CustomOutlinedBtn,
  FlexImageContainer1,
  SessionHeading,
} from './ClinicCardElements';
import clinicImg from '../../../images/v1/clinic.png';
import {
  DoctorsImage,
  Price,
  SessionParagraph1,
} from '../../VideoConsultation/VideoConsultationElements';

function ClinicCard({ slide, onClickHandler }) {
  return (
    <CardContainer>
      <Card>
        <Container dir='row'>
          <FlexImageContainer1>
            <DoctorsImage src={clinicImg} alt='p' />
          </FlexImageContainer1>
          <Container dir='column'>
            <SessionHeading>{slide.name}</SessionHeading>
            <SessionParagraph1>{slide.address}</SessionParagraph1>
            <Price>&#8377;{slide.fees}</Price>
          </Container>
        </Container>
        <CustomOutlinedBtn onClick={onClickHandler ? onClickHandler : () => {}}>
          Select clinic
        </CustomOutlinedBtn>
      </Card>
    </CardContainer>
  );
}

export default ClinicCard;

import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs8 from "../../images/blogsimage/HealthKoBlogs8.jpg";


const PostPage8 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    document.title = '10 Effective Ways to Treat Sunburn at Home | Sunburn Relief Tips';

    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };
  
    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };
  
    setLinkTag('blogs', 'https://www.healthko.in/10-Ways-to-Treat-Sunburn-at-Home');

    setMetaTag('description', 'Discover 10 home remedies to treat sunburn effectively. Learn how to soothe, heal, and relieve sunburn pain with these simple, dermatologist-approved tips.');

  }, []);

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>10 Ways to Treat Sunburn at Home</h1>
        <img src={HealthKoBlogs8} alt="10 Ways to Treat Sunburn at Home" />
        
        <p>One of the frequently heard problems is sunburn, which can occur throughout the year but is more commonly experienced in the summer due to increased exposure to<a href="https://www.who.int/health-topics/ultraviolet-radiation#tab=tab_1"target="_blank" rel="noopener noreferrer">UV radiation</a> . It presents manifestations such as inflammation, itching, pain, rashes, blistering and even skin peeling. Pretreatment of sunburn is important to minimise pain and inflammation and to avoid worsening of the skin condition. Here are 10 ways for sunburn relief tips that can be involved in the treatment of sunburn at home.</p>

        <h2>1. Cool Water Baths and Compresses</h2>
        <p>To help soothe the skin in case of sunburn, individuals can use cool water baths or make use of cool compresses. Cold water further assists in minimising irritation and killing the sensation of burning. To do a cool compress, take a fresh piece of clean cloth and dipped it in the cool water then after rinsing it, put the cloth on the part of the body affected by the sunburn. It is one of the best sunburn relief tips.</p>
        
        <h2>2. Aloe Vera</h2>
        <p>Folk medicine views Aloe Vera as the skin’s friend and it is often recommended for treatment of sunburn. This has properties which include; It has anti-inflammatory properties and skin moisture which can help the skin to heal quickly. They should use topical <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2763764/"target="_blank" rel="noopener noreferrer">Aloe Vera</a> gel, avoid using water and apply this gel to the affected area several times a day. However, you have the option of chilling the gel before use for a better cooling sensation.</p>

        <h2>3. Hydration</h2>
        <p>This is especially important when it comes to treating sunburn as it is a major aspect that is recommended in order to prevent the worsening of the condition. The sunburn causes water to be attracted to the skin and hence causes the skin to expand and form edema while at the same time repelling water from the rest of the body due to dehydration. Increase your intake of water, juices and any other <a href="https://www.healthline.com/nutrition/electrolytes-drinks#:~:text=Coconut%20water%2C%20milk%2C%20fruit%20juices,exercise%2C%20vomiting%2C%20or%20diarrhea."target="_blank" rel="noopener noreferrer">electrolyte-containing fluids</a> so as to ensure adequate hydration as the healing process ensues.</p>

        <h2>4. Moisturise with Natural Oils</h2>
        <p>Coconut oil and olive oil are easily available natural oils that aid in moisturising the region and healing sunburned skin. Here, we have mentioned that coconut oil has <a href="https://en.wikipedia.org/wiki/Anti-inflammatory"target="_blank" rel="noopener noreferrer"> anti-inflammatory</a> as well as <a href="https://www.ncbi.nlm.nih.gov/books/NBK482369/#:~:text=Acetaminophen%2C%20also%20known%20as%20N,significant%20component%20of%20their%20presentation."target="_blank" rel="noopener noreferrer">antibacterial</a> properties and olive oil contains antioxidants and vitamins. Essential oils can be used by massaging a small amount on that part of the body to moisturise the skin and encourage the healing process.</p>

        <h2>5. Avoiding Sun Exposure</h2>
        <p>One important aspect to understand if you have a sunburn is to stay out of the sun if possible. Avoid going out of the house or remain under the shade particularly when the sun is at its hottest between 10:00 in the morning to 4:00 in the afternoon. If you have to step out, make sure to wear a hat or a headscarf to cover your head and also cover your body wear, and avoid UV rays by applying strong sunscreen products.</p>

        <h2>6. Over-the-Counter Pain Relievers</h2>
        <p>Among remedies that one can consider taking without consulting the doctor are those analgesic drugs, which include <a href="https://www.drugs.com/ibuprofen.html"target="_blank" rel="noopener noreferrer">ibuprofen</a>, aspirin, or acetaminophen for relieving pain and inflammation from sunburn. It is recommended that you take the product according to the directions on the packaging and, therefore, speak to a healthcare provider in-case you have concerns about the use of these medications.</p>

        <h2>7. Oatmeal Baths</h2>
        <p>Sunburn can be eased to an extent by using oatmeal, besides helping to make the skin healthy. To prepare an oatmeal bath, it is necessary to grind regular oatmeal until obtaining a fine powder and then pour it to the warm bath tile. Thus, it is recommended to stay in the water of the oatmeal bath for about 15 to 20 minutes to create an effect on the itching and irritation.</p>

        <h2>8. Cool, Loose Clothing</h2>
        <p>Remaining in fresh clothes made from breathable fabrics will help reduce more irritation to the affected skin area by the sunburn. Select loose-fitting silhouettes made of natural fabrics like cotton to let your skin breathe. Loose clothing should be possibly worn to do away with any chafing that may be caused by the sunburn.</p>

        <h2>9. Honey</h2>
        <p>Honey is an effective bactericide and has anti-inflammatory abilities, which are condones for a sunburn remedy. It assists in holding moisture in the skin as this curiously speeds up the restoration work. When using honey, pour a thin layer of it on the skin that is affected by acne and allow it to sit for approximately 15-20 minutes after which you can rinse it off with cold water. Allow to recall several times a day for the optimal outcome.</p>

        <h2>10. Cucumber</h2>
        <p>Cucumber has a very cooling effect on the skin and relieving sunburn is one of the best reasons why you should apply cucumber on your skin. It has <a href="https://medlineplus.gov/antioxidants.html#:~:text=Antioxidants%20are%20man%2Dmade%20or,Beta%2Dcarotene"target="_blank" rel="noopener noreferrer">antioxidants</a> and pain reducer compounds that also aid in reducing inflammation. To use it, you can cut a chilled cucumber into small slices and place it directly on the sunburned area or you can blend the cucumber so that it turns into a paste and apply that paste on the skin. Wash it off with cool water after a 15-20 minutes interval, after applying the mixture.</p>

        <h1>Conclusion,</h1>
        <p>As much as sunburn can be a sore issue, here are some natural remedies in sunburn relief as well as healing. As a reminder, make sure to drink water, put lotion on your skin, and avoid going out in the sun anymore. One should <a href="https://www.healthko.in/Online-Doctor-Consultation"target="_blank" rel="noopener noreferrer"> Consult a doctor</a> if one has a severe sunburn or signs like fever, chills or if the sunburn causes blisters. Early intervention is especially useful to lessen the intensity and duration of the side effects and the healing process.</p>
       
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage8;

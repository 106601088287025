import React from 'react';
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterWrap,
  FooterLinkWrapper,
  SocialMedia,
  SocialMediaWrap,
  SocialIconLink,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
} from './FooterElements';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { FaXTwitter , FaWhatsapp ,FaThreads ,FaMedium } from "react-icons/fa6";
import { SiLinktree } from "react-icons/si";


const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to='#'>How it Works</FooterLink>
              <FooterLink to='#'>Careers</FooterLink>
              <FooterLink to='/videotnc/0'>Terms of Services</FooterLink>
              <FooterLink to='https://drive.google.com/file/d/1VH4GGeJz1wuT5YTwC4kwPnyno1Ax2hrk/view?usp=drive_link ' target='_blank' aria-label=''>Official HealthKo DOC. (Early Access )</FooterLink>
              
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to='#'>Contact</FooterLink>
              <FooterLink to='#'>Support</FooterLink>
              <FooterLink to='#'>Destinations</FooterLink>
              <FooterLink to='#'>Sponsorships</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink to='https://www.instagram.com/healthko_official' target='_blank' aria-label='Instagram'>Instagram</FooterLink>
              <FooterLink to='https://m.facebook.com/100092920637640/'  target='_blank' aria-label='Facebook'>Facebook</FooterLink>
              <FooterLink to='https://youtube.com/@HealthKo?si=9yHYXq4_ZxGw3kc9'  target='_blank' aria-label='YouTube'>YouTube</FooterLink>
              <FooterLink to='https://www.linkedin.com/company/healthko-in/mycompany/verification/'  target='_blank' aria-label='LinkedIn'>LinkedIn</FooterLink>
              <FooterLink to='https://twitter.com/healthko_offl' target='_blank' aria-label='Twitter'>Twitter</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Videos</FooterLinkTitle>
              <FooterLink to='#'>Submit Video</FooterLink>
              <FooterLink to='#'>Ambassadors</FooterLink>
              <FooterLink to='#'>Agency</FooterLink>
              <FooterLink to='#'>Influencer</FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              HealthKo.
            </SocialLogo>
            <WebsiteRights>
            Copyright &copy; {new Date().getFullYear()} AHS HEALTHCARE PRIVATE LIMITED.  All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.instagram.com/healthko_official' target='_blank' aria-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='https://m.facebook.com/100092920637640/' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='https://youtube.com/@HealthKo?si=9yHYXq4_ZxGw3kc9' target='_blank' aria-label='YouTube'>
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href='https://www.linkedin.com/company/healthko-in/mycompany/verification/' target='_blank' aria-label='LinkedIn'>
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href='https://twitter.com/healthko_offl' target='_blank' aria-label='Twitter'>
              <FaXTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://linktr.ee/healthko_official' target='_blank' aria-label='Twitter'>
              <SiLinktree />
              </SocialIconLink>
              <SocialIconLink href='https://wa.me/7339966643' target='_blank' aria-label='whatsapp'>
              <FaWhatsapp />
              </SocialIconLink>
              <SocialIconLink href='https://www.threads.net/@healthko_official' target='_blank' aria-label='Thread'>
              <FaThreads />
              </SocialIconLink>
              <SocialIconLink href='https://medium.com/@healthko' target='_blank' aria-label='medium'>
              <FaMedium />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;

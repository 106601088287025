import styled from "styled-components";

export const SidebarWrapper = styled.div`
  background-color: #f0f0f0;
  width: 200px;
  height: 100vh;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const TabList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const TabItem = styled.li`
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #ddd;
  }
`;

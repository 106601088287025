import React, { useState, useEffect, useRef } from 'react';
import { FaTimes, FaUserMd } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import GooglePlayIcon from "../../images/v1/Googleplaystore.png";
import OurDoctorImg from "../../images/v1/OurDoctor.png";
import IosStoreIcon from "../../images/v1/IosStore.png";
import { scroller } from 'react-scroll';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SideBtnWrap,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  BtnContainer,
  SidebarsLink,
  DropdownItem,
  GooglePlayIconImage,
  GooglePlayIconContainer,
  IosStoreIconContainer,
  IosStoreIconImage,
  IconContainer,
  IconRow,
  DropdownContent
} from './SidebarElements';
import { OutlinedBtn, PrimaryBtn } from '../commonComponents';

const Sidebar = ({ isSideBarOpen, toggle, openSignup, openLogin }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const closeSidebar = () => {
    setIsDropdownOpen(false);
    toggle();
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavClick = (sectionId) => {
    if (location.pathname === "/") {
      scroller.scrollTo(sectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    } else {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(sectionId, {
          duration: 500,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -80,
        });
      }, 100); // Adjust delay as needed
    }
    toggle(); // Close the sidebar
  };

  const handleGooglePlayClick = (event) => {
    event.preventDefault();
    window.open ( "https://play.google.com/store/apps/details?id=com.HealthKoNew.Doc2", "_blank"); 
  };

  const handleIosStoreClick = (event) => {
    event.preventDefault();
    alert("Coming Soon");
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SidebarContainer issidebaropen={isSideBarOpen.toString()} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        <SidebarLink onClick={() => handleNavClick('services')}>
          Our Services
        </SidebarLink>
        <SidebarLink onClick={() => handleNavClick('about')}>
          About Us
        </SidebarLink>
        <SidebarsLink to='/Contact-Us'>
          Contact Us
        </SidebarsLink>
        <SidebarsLink to='/blogs'>
          Blogs
        </SidebarsLink>
        <SidebarsLink to='/Team_Member'>
          Team
        </SidebarsLink>
        <SidebarLink onClick={handleDropdownToggle}>
          For Doctors <FaUserMd /> 
        </SidebarLink>
        {isDropdownOpen && (
          <DropdownContent isOpen={isDropdownOpen} onClick={stopPropagation} ref={dropdownRef}>
            <DropdownItem>
              <h3>Free registration for doctors keen on sharing their expertise with millions of patients in need. Join now!</h3> 
              <IconRow>
                <IconContainer>
                  <GooglePlayIconContainer onClick={handleGooglePlayClick}>
                    <GooglePlayIconImage src={GooglePlayIcon} alt="Google Play Store Icon" />
                  </GooglePlayIconContainer>
                </IconContainer>
                <IconContainer>
                  <IosStoreIconContainer onClick={handleIosStoreClick}>
                    <IosStoreIconImage src={IosStoreIcon} alt="iOS App Store Icon" />
                  </IosStoreIconContainer>
                </IconContainer>
              </IconRow>
            </DropdownItem>
          </DropdownContent>
        )}
        <BtnContainer>
          <OutlinedBtn onClick={openLogin}>Login</OutlinedBtn>
          <PrimaryBtn onClick={openSignup}>Sign up</PrimaryBtn>
        </BtnContainer>
      </SidebarMenu>
    </SidebarContainer>
  );
};

export default Sidebar;

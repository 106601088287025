import styled from 'styled-components';
import { OutlinedBtn } from '../../commonComponents';
import { FlexImageContainer } from '../../VideoConsultation/VideoConsultationElements';

export const CardContainer = styled.div`
  height: 306px;
  width: 400px;
  padding: 1rem;
`;

export const Card = styled.div`
  width: 90%;
  height: 95%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 1rem;
`;

export const CustomOutlinedBtn = styled(OutlinedBtn)`
  width: 100%;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: #fff;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: ${(props) => (props.dir === 'column' ? 'flex-start' : 'center')};
  justify-content: space-between;
  flex-direction: ${(props) => props.dir};
  gap: 1rem;
`;

export const FlexImageContainer1 = styled(FlexImageContainer)`
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  margin-bottom: 1rem;
`;

export const SessionHeading = styled.p`
  color: #2e2e2e;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  display: block;
  line-height: 24px; /* 104.348% */
  letter-spacing: 0.53px;
`;

// export const Card = styled.div`
//   width: 386px;
//   height: 306px;
//   border-radius: 12px;
//   background: #fff;
//   box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
// `;

import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs6 from "../../images/blogsimage/HealthKoBlogs6.jpg";


const PostPage6 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    document.title = 'Painful intercourse (dyspareunia) - Causes,Symptoms and Treatment';

    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };
  
    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };
  
    setLinkTag('blogs', 'https://www.healthko.in/Painful-Intercoure-Causes-Symptoms-And-Treatments');

    setMetaTag('description', ' Experiencing pain during sex? Learn about the causes, symptoms, and treatments of dyspareunia. Find relief and improve your intimate life.');

  }, []);

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>Painful Intercourse(Dyspareunia) : Causes, Symptoms And Treatments</h1>
        <p>Dyspareunia, also referred to as painful sexual intercourse, is a complex problem that may occur with any person, no matter their age. If left untreated, it can bring a lot of physical pain and stress, not only in their marriage but in their daily lives as well. It is important to look at the cause for this condition, look at warning signs, and seek ways to deal with this condition.</p>
        <img src={HealthKoBlogs6} alt="Diabetes: Symptoms, Causes, Types, Treatments, and Prevention" />
        
        <h2>What Is Dyspareunia</h2>
        <p>Dyspareunia therefore involves intermittent pain that occurs during or after <a href="https://dictionary.cambridge.org/dictionary/english/coitus"target="_blank" rel="noopener noreferrer"> coitus</a>. This pain can be in varying degrees and can affect any area, from the outer part of the vagina to the area deep in the abdomen. This is a complex disorder that may have a variety of causes related to a person’s physical or psychological health.</p>

        <h1>Causes of Painful Intercourse</h1>
        <h2>Physical Causes</h2>
        <p>●  Vaginal Dryness: It is common in women and is widely attributed to hormonal changes such as those that occur during menopause or while breastfeeding or due to certain medications. Low levels of <a href="https://en.wikipedia.org/wiki/Estrogen"target="_blank" rel="noopener noreferrer"> estrogen</a> result in dry, thin and less elastic skin of the vagina.</p>
        <p>●  Yeast Infections: May lead to inflammation and irritation.</p>
        <p>● Urinary Tract Infections (UTIs): Other signs include pain and discomfort during intercourse.</p>
        <p>●  Sexually Transmitted Infections (STIs): As for example herpes or chlamydia.</p>
        <p>●  <a href="https://www.who.int/news-room/fact-sheets/detail/endometriosis#:~:text=Overview,period%20and%20last%20until%20menopause."target="_blank" rel="noopener noreferrer">Endometriosis </a>:  A disease in which tissue akin to the uterine endometrium develops outside the body organ, causing inflammation, pain, and scarring.</p>
        <p>●  Pelvic Inflammatory Disease (PID): A disease that affects the female reproductive organs and which may be initiated or exacerbated by STIs; can cause long-term pain.</p>
        <p>● <a href="https://www.nhs.uk/conditions/vulvodynia/"target="_blank" rel="noopener noreferrer">Vulvodynia </a>: Constant pain near the vaginal orifice with no known cause, this makes intercourse painful.</p>
  
        <h2>Psychological Causes</h2>
        <p>● Stress and Anxiety: It becomes important to note that mental health problems may contribute to physical symptoms, and lead to muscle contraction and reduced lubrication.</p>
        <p>● Past Trauma: Emotional trauma from past experiences such as sexual abuse or assault may lead to so much anxiety and pain during intercourse.</p>
        <p>● Body Image Issues: Women having negative thoughts about their body can experience discomfort or pain when involved in sexual activities.</p>
        <p>● Relationship Problems: In cases where there is poor feeling, or there are issues between the couple, then sexual relations could be uncomfortable or even painful.</p>

        <h2>Symptoms of Painful Intercourse</h2>
        <p>● Sharp, Burning, or Aching Pain: Pain may be local or general during intercourse, that is, it may only be present in one area or it may affect various parts of the body.</p>
        <p>● Throbbing Pain: Which may last for hours after the act of intercourse.</p>
        <p>● Vaginal Tightness: That is, involuntary spasms of the vaginal muscles known as vaginismus.</p>
        <p>● Fear of Intimacy: Specifically, pain anxiety may result in sexual avoidance among patients.</p>
        <p>● Emotional Distress: Emotions such as anger, depression or low self esteem regarding sexual experience.</p>
        <p>● Decreased Interest in Sex: Loss of sexual desire due to the anticipation of pain.</p>
       
       <h1>Possible Treatments for Painful Intercourse</h1>
       <h2>Medical Treatments</h2>
        <p>● Lubricants: Over-the-counter products can relieve vagination dryness and minimise friction during sex.</p>
        <p>● Hormonal Treatments: Such as hormonal creams or estrogen pills for vaginal dryness.</p>
        <p>● Antibiotics or Antifungals: For infection treatment.</p>
        <p>● Physical Therapy: Pelvic floor exercises and therapy can enhance muscle coordination and reduce pain.</p>
        <p>● Surgery: Surgery may be needed in critical cases to correct anatomical defects or eliminate scars tissue.</p>

        <h2>Psychological Treatments</h2>
        <p>● Counselling:  Therapy can help to deal with some of the mental health problems that contribute to dyspareunia e.g. a person’s past traumatic events or relationship issues.</p>
        <p>● Cognitive Behavioral Therapy (CBT): Assists in changing negative thought patterns and behaviours associated with pain.</p>
        <p>● Sex Therapy: A form of special counselling that deals with sexual function and intimacy related issues.</p>

        <h2>Lifestyle Changes</h2>
        <p>● Healthy Diet: Eating balanced meals, well endowed in nutrients, will lead to better health and lower vaginal dryness symptoms and inflammation.</p>
        <p>● Regular Exercise: Physical exercises help improve mood, reduce stress levels, overall body function improvement.</p>
        <p>● Stress Management Techniques: Activities as such yoga, meditation, mindfulness etc. are recommended as they can be used to manage anxiety and pain caused by stress.</p>
        <p>● Adequate Hydration: This helps to maintain good mucosal health and lubrication.</p>
       
        <h1>Conclusion,</h1>
        <p>One should realise that they can be treated for these conditions. The starting point to effective treatment is knowing the reasons behind and diagnosing the symptoms. You need to see a doctor if you experience painful sex. <a href="https://www.healthko.in/Online-Doctor-Consultation"target="_blank" rel="noopener noreferrer"> Consult a doctor</a> so as to evaluate the ways out and decide on what suits your case. In early stages, it might get better and you will soon have a satisfying and pain free sexual life.</p>
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage6;

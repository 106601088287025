import React, { useEffect } from 'react';
import VideoConsultation from '../components/VideoConsultation/VideoConsultation';
import { useAuthContext } from '../hooks/AuthContextHook';

function VideoConsultationPage() {
  const { auth, updateAuth } = useAuthContext();

  useEffect(() => {
    if (!auth.isLogged) updateAuth({ ...auth, isAuthModalOpen: true });

    return () => {
      updateAuth({ ...auth, isAuthModalOpen: false });
    };
  }, []);

  return (
    <div>
      <VideoConsultation />
    </div>
  );
}

export default VideoConsultationPage;

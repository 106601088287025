import React from "react";
import {
  Image,
  Paragraph,
  SectionHeading,
} from "../commonComponents";
import MainImg from "../../images/v1/Our-app.png";
import GooglePlayIcon from "../../images/v1/Googleplaystore.png";
import AppStoreIcon from "../../images/v1/IosStore.png"
import {
  BtnContainer,
  CustomContainer,
  MainContainer,
  MainHeading,
} from "../AboutUs/AboutUsElements";
import { CustomImageContainer,IconContainer, SectionContainer , GooglePlayIconImg, AppStoreIconImg} from "./OurAppElements";

function OurApp() {
  const handleGooglePlayClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    window.open ( "https://play.google.com/store/apps/details?id=com.ahsclient.app", "_blank"); // Replace with your app's URL
  };
  const handleAppStoreClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    alert("Coming Soon");
  };

  return (
    <CustomContainer>
      <SectionHeading>Our App</SectionHeading>
      <MainContainer>
        <CustomImageContainer>
          <Image src={MainImg} alt="Our App" />
        </CustomImageContainer>
        <SectionContainer>
          <MainHeading>Download Our App</MainHeading>
          <Paragraph>
            Experience healthcare at your fingertips with our dedicated apps
            designed for both patients and doctors. For patients, our
            user-friendly app provides seamless access to a world of healthcare
            services. Schedule appointments, connect with healthcare
            professionals through secure video consultations, and manage your
            health records with ease. Your well-being is just a click away.
          </Paragraph>
          <IconContainer>
      <GooglePlayIconImg src={GooglePlayIcon} alt="Google Play Store Icon" onClick={handleGooglePlayClick} />
      <AppStoreIconImg src={AppStoreIcon} alt="App Store Icon" onClick={handleAppStoreClick} />
    </IconContainer>
        </SectionContainer>
      </MainContainer>
    </CustomContainer>
  );
}

export default OurApp;

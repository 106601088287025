import React from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import Service from "../components/ServicesSection/Service";
import AboutUs from "../components/AboutUs/AboutUs";
import OurApp from "../components/OurApp/OurApp";
import OurServices from "../components/OurServices/OurServices";
import Footer from "../components/Footer/Footer";
import DoctorReview from "../components/DoctorReview/DoctorReview";
import CustomerReview from '../components/CustomerReview/CustomerReview';
import FAQ from "../components/FAQ/FAQ";
// import HomeBanner from "../components/SlidingBanner/HomeBanner"

const Home = () => {
  return (
    <div>
      {/* <HomeBanner/> */}
      <HeroSection />
      <Service />
      <OurServices />
      <DoctorReview/>
      <CustomerReview/>
      <AboutUs />
      <OurApp />
      <FAQ/>
      <Footer />
    </div>
  );
};

export default Home;

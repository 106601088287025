import React, { useEffect, useRef, useState } from "react";
import index from "./index.css";
import {
  AuthContainer,
  CheckBox,
  CustomPrimaryBtn,
  ImageContainer,
  Input,
  Label,
  TabContainer,
  TabContent,
  Tabs,
  Terms,
  TermsSection,
} from "./AuthUIElements";
import login from "../../images/v1/login.png";
import { Error, Image } from "../commonComponents";
import useAthHooks from "../../hooks/AuthHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AuthUI({ activeState }) {
  const {
    isRegistered,
    formSignup,
    signupDataErr,
    formLogin,
    formLoginErr,
    setFormLogin,
    setFormSignup,
    handleSignup,
    handleLogin,
  } = useAthHooks();
  const [activeTab, setActiveTab] = useState(activeState || "login");
  const [enteredotp, setenteredotp] = useState(null);
  const [actualotp, setactualotp] = useState(null);
  const [enteredotpe, setenteredotpe] = useState(null);
  const [actualotpe, setactualotpe] = useState(null);
  const [sender, setsender] = useState(true);
  const [sender2, setsender2] = useState(false);
  const [a, seta] = useState(true);
  const [signed, setsigned] = useState(false);
  const handleInputChange = (e) => {
    if (e.target.name == "phone") {
      setsender(true);
      setvisible(false);
    } else if (e.target.name == "email") {
      setevisible(false);
      setsender2(true);
      seta(true);
    }

    setFormSignup({
      ...formSignup,
      [e.target.name]: e.target.value,
    });
    // console.log(formSignup)
  };
  const [visible, setvisible] = useState(false);
  const [evisible, setevisible] = useState(false);

  const handleInputChangeLogin = (e) => {
    setFormLogin({
      ...formLogin,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isRegistered) setActiveTab("login");
  }, [isRegistered]);

  const getotp = async (e) => {
    e.preventDefault();
    const phone = formSignup.phone;
    const mobileRegex = /^[0-9]{10}$/;
    const validation = mobileRegex.test(phone);
    // console.log(phone)
    if (validation) {
      try {
        const response = await fetch(
          "https://api.healthko.in/api/userauth/phonechecker",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ phone }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          // console.log(data.message)
          if (data.status) {
            toast.success(data.message);
            setvisible(true);
            sendotp();
          } else {
            toast.error(data.message);
          }
        }
      } catch (error) {
        console.log("Error Occurred");
      }
    } else {
      toast.error("Please Enter a Valid Mobile Number");
    }
  };
  const getotp2 = async (e) => {
    e.preventDefault();
    // console.log(formSignup.email)
    const email = formSignup.email;

    try {
      const response = await fetch(
        "https://api.healthko.in/api/userauth/emailchecker",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        // console.log(data.message)
        if (data.status) {
          toast.success(data.message);
          setevisible(true);
          sendotp2();
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      console.log("Error Occurred");
    }
  };
  const sendotp = async () => {
    const num = formSignup.phone;
    try {
      const response = await fetch(
        "https://api.healthko.in/api/verification/verifyphone",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ num }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        setactualotp(data.data.otp);
      }
    } catch (error) {
      toast.error("Internal Error Occured");
    }
  };
  const sendotp2 = async () => {
    const email = formSignup.email;
    // console.log("lkashay",email)
    try {
      const response = await fetch(
        "https://api.healthko.in/api/verification/verifyemail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        setactualotpe(data.otp);
      }
    } catch (error) {
      toast.error("Internal Error Occured");
    }
  };
  const inputs = useRef([]);
  const einputs = useRef([]);
  const handleInput = (index, event) => {
    const { value } = event.target;

    // Update the input value
    inputs.current[index].value = value;

    try {
      if (value && index < inputs.current.length - 1) {
        inputs.current[index + 1].focus();
      }

      if (!value && index > 0) {
        inputs.current[index - 1].focus();
      }

      // Check if the 4th digit is entered
      if (index === 3) {
        if (value.length === 1) {
          // Run a function when the 4th digit is entered
          functionWhenFourthDigitEntered();
        } else {
          // Run a different function if the 4th digit is cancelled
          functionWhenFourthDigitCancelled();
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInpute = (index, event) => {
    const { value } = event.target;

    // Update the input value
    einputs.current[index].value = value;

    try {
      if (value && index < einputs.current.length - 1) {
        einputs.current[index + 1].focus();
      }

      if (!value && index > 0) {
        einputs.current[index - 1].focus();
      }

      // Check if the 4th digit is entered
      if (index === 5) {
        if (value.length === 1) {
          // Run a function when the 4th digit is entered
          functionWhenFourthDigitEnterede();
        } else {
          // Run a different function if the 4th digit is cancelled
          functionWhenFourthDigitCancellede();
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Define the functions to be executed
  const functionWhenFourthDigitEntered = () => {
    // Function logic when the 4th digit is entered
    // console.log('Fourth digit entered!');
    setsender(false);
    handleSubmit();

    // Call your function here
  };

  const functionWhenFourthDigitCancelled = () => {
    // Function logic when the 4th digit is cancelled
    // console.log('Fourth digit cancelled!');
    handleSubmit();
    // Call your other function here
  };
  const functionWhenFourthDigitEnterede = () => {
    // Function logic when the 4th digit is entered
    // console.log('Fourth digit entered!');
    seta(false);
    handleSubmite();

    // Call your function here
  };

  const functionWhenFourthDigitCancellede = () => {
    // Function logic when the 4th digit is cancelled
    // console.log('Fourth digit cancelled!');

    handleSubmite();
    // Call your other function here
  };

  const handleKeyUp = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      inputs.current[index - 1].focus();
    }
  };

  const handleKeyUpe = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      einputs.current[index - 1].focus();
    }
  };

  const handleSubmit = () => {
    setenteredotp(inputs.current.map((input) => input.value).join(""));
  };
  const handleSubmite = () => {
    setenteredotpe(einputs.current.map((input) => input.value).join(""));
  };
  const verifymob = () => {
    if (enteredotp.length != 4) {
      toast.warning("Please Enter a 4 digit OTP");
    } else if (enteredotp.length == 4) {
      if (enteredotp == actualotp) {
        toast.success("OTP verification successful");
        setTimeout(setsender(null), 2000);
      } else {
        toast.error("Incorrect OTP entered");
      }
    }
  };
  const verifyemail = () => {
    if (enteredotpe.length != 6) {
      toast.warning("Please Enter a 6 digit OTP");
    } else if (enteredotpe.length == 6) {
      if (enteredotpe == actualotpe) {
        toast.success("OTP verification successful,Click Sign-Up");
        setsigned(true);
      } else {
        toast.error("Incorrect OTP entered");
      }
    }
  };
  const takedet = (e) => {
    e.preventDefault();
    setsender2(true);
  };

  return (
    <>
      <ToastContainer />
      <AuthContainer>
        <ImageContainer>
          <Image src={login} />
        </ImageContainer>
        <div>
          <TabContainer>
            <Tabs
              onClick={() => setActiveTab("login")}
              $active={activeTab === "login" ? true : false}
            >
              Login
            </Tabs>
            <Tabs
              onClick={() => setActiveTab("signup")}
              $active={activeTab === "signup" ? true : false}
            >
              Sign up
            </Tabs>
          </TabContainer>
          <TabContent>
            {activeTab === "login" ? (
              <>
                <Label htmlFor="email">Email or Phone</Label>
                <Input
                  placeholder="Email or Phone"
                  type="text"
                  name="email"
                  onChange={handleInputChangeLogin}
                />
                <Error>{formLoginErr.email}</Error>

                <Label htmlFor="password">Password</Label>
                <Input
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleInputChangeLogin}
                />
                <Error>{formLoginErr.username}</Error>

                <TermsSection>
                  <CheckBox
                    type="checkbox"
                    id="terms"
                    name="terms"
                    value={true}
                    onChange={handleInputChangeLogin}
                  />
                  <Terms htmlFor="terms">Remember me</Terms>
                </TermsSection>
                <CustomPrimaryBtn onClick={handleLogin}>Login</CustomPrimaryBtn>
                <Error>{formLoginErr.login}</Error>
              </>
            ) : (
              <>
                {sender2 == true && sender == null && (
                  <form onSubmit={getotp2} className="email">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      required
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                    {evisible == true && (
                      <div className="otp-input">
                        {[...Array(6)].map((_, index) => (
                          <input
                            key={index}
                            className="otp-inp"
                            type="text"
                            maxLength="1"
                            ref={(el) => (einputs.current[index] = el)}
                            onChange={(e) => handleInpute(index, e)}
                            onKeyUp={(e) => handleKeyUpe(index, e)}
                          />
                        ))}
                      </div>
                    )}
                    {sender == null && sender2 == true && a == true && (
                      <button className="verify-phone">Send OTP</button>
                    )}
                    <Error>{signupDataErr.email}</Error>
                  </form>
                )}
                {sender2 == true && sender == null && a == false && (
                  <button onClick={verifyemail} className="verify-phone">
                    Verify OTP
                  </button>
                )}
                <form onSubmit={takedet}>
                  {sender2 == false && sender == null && (
                    <div className="name">
                      <Label htmlFor="name">Name</Label>
                      <Input
                        placeholder="Name"
                        type="text"
                        name="name"
                        required
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <Error>{signupDataErr.name}</Error>
                    </div>
                  )}
                  {sender2 == false && sender == null && (
                    <div className="address">
                      <Label htmlFor="address">Address</Label>
                      <Input
                        placeholder="Enter Address"
                        type="text"
                        required
                        name="address"
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <Error>{signupDataErr.address}</Error>
                    </div>
                  )}
                  {sender2 == false && sender == null && (
                    <div className="password">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        placeholder="Password"
                        type="password"
                        pattern=".{6,}" // Minimum length of 6 characters
                        title="Password should be at least 6 characters long" // Error message
                        required
                        name="password"
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <Error>{signupDataErr.password}</Error>
                    </div>
                  )}
                  {sender2 == false && sender == null && (
                    <button className="proceed">Proceed</button>
                  )}
                </form>
                {sender2 == false && sender != null && (
                  <form onSubmit={getotp} className="phone">
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      placeholder="Phone"
                      type="text"
                      required
                      name="phone"
                      onChange={handleInputChange}
                      autoComplete="off"
                      pattern="{0-9}10"
                    />
                    {visible == true && (
                      <div className="otp-input">
                        {[...Array(4)].map((_, index) => (
                          <input
                            key={index}
                            className="otp-inp"
                            type="text"
                            maxLength="1"
                            ref={(el) => (inputs.current[index] = el)}
                            onChange={(e) => handleInput(index, e)}
                            onKeyUp={(e) => handleKeyUp(index, e)}
                          />
                        ))}
                      </div>
                    )}
                    {sender != null && sender == true && (
                      <button className="verify-phone">Send OTP</button>
                    )}
                    <Error>{signupDataErr.phone}</Error>
                  </form>
                )}
                {sender != null && sender != true && (
                  <button onClick={verifymob} className="verify-phone">
                    Verify OTP
                  </button>
                )}

                {signed == true && (
                  <TermsSection>
                    <CheckBox
                      type="checkbox"
                      id="terms"
                      name="terms"
                      value={true}
                      onChange={handleInputChange}
                    />
                    <Terms htmlFor="terms">Remember me</Terms>
                  </TermsSection>
                )}
                {signed == true && (
                  <CustomPrimaryBtn onClick={handleSignup}>
                    Sign up
                  </CustomPrimaryBtn>
                )}
                <Error>{signupDataErr.signup}</Error>
              </>
            )}
          </TabContent>
        </div>
      </AuthContainer>
    </>
  );
}

export default AuthUI;

import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs4 from "../../images/blogsimage/HealthKoBlogs4.jpg";

const PostPage4 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    // Update document title
    document.title = '10 Best Summer Foods to Stay Cool and Hydrated';

    // Function to set meta tags dynamically
    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };

    // Function to set link tags (for example: canonical URL)
    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };

    // Set link tag for canonical URL
    setLinkTag('canonical', 'https://www.healthko.in/10-Best-Summer-Foods-to-Stay-Cool');

    // Set meta tags for SEO
    setMetaTag('description', 'Discover 10 best summer foods that help you stay cool and hydrated. Beat the heat with these refreshing fruits and vegetables.');
    setMetaTag('keywords', 'summer foods, stay cool, hydration, healthy diet, fruits, vegetables');
    setMetaTag('author', 'HealthKo');

    // Set Open Graph tags for better social media sharing
    setMetaTag('og:title', '10 Best Summer Foods to Stay Cool and Hydrated');
    setMetaTag('og:description', 'Discover 10 best summer foods that help you stay cool and hydrated. Beat the heat with these refreshing fruits and vegetables.');
    setMetaTag('og:image', HealthKoBlogs4);
    setMetaTag('og:url', window.location.href);

  }, []);

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>10 Best Summer Foods to Stay Cool</h1>
        <p>During the hot season, it is vitally important to keep cool. Although drinking water and avoiding prolonged sun exposure is effective, diet also plays a crucial role in how the body reacts to heat. There are certain types of best summer foods that assist our body to be cool if introduced in our diet.</p>
        <img src={HealthKoBlogs4} alt="Summer Foods" />
        <p>Here are 10 best foods for summer to consume for overcoming the <a href="https://www.vocabulary.com/dictionary/scorching#:~:text=hot%20and%20dry%20enough%20to,sensation%20of%20heat%20or%20burning"target="_blank" rel="noopener noreferrer"> scorching heat </a>.</p>
      
        <h1>Watermelon</h1>
        <h2>Nutritional Benefits</h2>
        <p> ●  High water content (about 92%)</p>
        <p> ●  Rich in vitamins A, C, and B6</p>
        <p> ●  Contains antioxidants like <a href="https://www.healthline.com/nutrition/lycopene#sun-protection"target="_blank" rel="noopener noreferrer"> lycopene </a></p>
       

        <h2>How It Helps in Staying Cool</h2>
        <p>●  Maintains hydration levels and provides a cooling effect</p>
        <p>●  Replenishes electrolytes lost through sweat</p>
        
        <h1>Pineapple</h1>
        <h2>Nutritional Benefits</h2>
        <p>●  Packed with Vitamin C and B6, manganese, and <a href="https://www.medicalnewstoday.com/articles/323783"target="_blank" rel="noopener noreferrer"> Bromelain (anti-inflammatory agent).</a></p>

        <h2>How It Helps in Staying Cool</h2>
        <p>●  High water content and <a href="https://dictionary.cambridge.org/dictionary/english/thirst-quenching"target="_blank" rel="noopener noreferrer">thirst quenching feel   </a>that makes it one of the best foods in summer</p>
        <p>●  You can enjoy it fresh, in smoothies or as a frozen delight</p>
        
        <h1>Cucumber</h1>
        <h2>Nutritional Benefits</h2>
        <p>● Low in calories</p>
        <p>●  High water content</p>
        <p>● They have nutrients like vitamin K and C.</p>
        <p>●  it is also enriched in minerals such as magnesium and potassium</p>

        <h2>Hydration Properties</h2>
        <p>● Helps to regulate the body by providing<a href="https://www.corrosionpedia.com/definition/5041/heat-dissipation"target="_blank" rel="noopener noreferrer"> heat dissipation </a>  and keeping the body properly hydrated.</p>
        <p>●  May be taken raw and used in salads, taken as a snack or as a chilled water with the plant immersed in it.</p>

        <h1>Mint</h1>
        <h2>Cooling Effect</h2>
        <p>● Temperature regulation surrounding the body</p>
        <p>●  Promotes skin care and gives a cooling effect to the body especially to the stomach area.</p>
        
        <h2>Ways to Incorporate into Diet</h2>
        <p>● Form it in beverages such as lemonade or even iced tea</p>
        <p>●  They are used as garnishing agents especially in salads and sweet dishes.</p>

        <h1>Coconut Water</h1>
        <h2>Electrolyte Balance</h2>
        <p>● Rich in potassium, magnesium, sodium and other electrolytes.</p>
        <p>●  Remains as one of the ways through which the body’s fluid balance is regulated.</p>
        
        <h2>Natural Hydration</h2>
        <p>● This helps to replace fluids and electrolytes that the body loses through sweating and other bodily functions</p>
        <p>● A tasty but much better option than the sports drinks full of sugar.</p>

        <h1>Tomatoes</h1>
        <h2>Benefits:</h2>
        <p>● 95% water content</p>
        <p>●  Contains lycopene, vitamins C and A</p>
        <p>●  Versatile in dishes</p>
        
        <h2>Best Options for Summer</h2>
        <p>● Tomatoes are hydrating and rich in antioxidants, making them a great addition to salads and salsas.</p>

        <h1>Berries</h1>
        <h2>Antioxidants and Vitamins</h2>
        <p>● It contains high levels of antioxidants, vitamins and even fibre.</p>
        <p>●  These typically are foods low in calories and high in water content.</p>
        
        <h2>Refreshing Ways to Consume</h2>
        <p>● Use as toppings for salads, fruits, pastas, or grain dishes incorporate into<a href="https://en.wikipedia.org/wiki/Yogurt"target="_blank" rel="noopener noreferrer">   yogurt</a>, oatmeal or smoothies, or freeze for a cool summer snack.</p>

        <h1>Citrus Fruits</h1>
        <h2>Vitamin C and Hydration</h2>
        <p>● Good sources of vitamin C.</p>
        <p>● High water content makes them hydrating and good for our skin.</p>
        
        <h2>Best Options for Summer</h2>
        <p>● They can be taken as fresh juice, mixed with salads or even taken as a snack.</p>

        <h1>Leafy Greens</h1>
        <h2>Nutritional Benefits</h2>
        <p>● Packed with vitamin, minerals and other <a href="https://www.medicalnewstoday.com/articles/301506#benefits"target="_blank" rel="noopener noreferrer"> anti-oxidant properties </a>.</p>
        <p>● These foods are very light, their energy-yield per portion is low and they contain a lot of water.</p>
        
        <h2>How They Help in Cooling the Body</h2>
        <p>● Due to their rich water content, and nutrient density they cool the body,</p>
        <p>● Use it in salads, smoothies, or even as sandwiches to create a cool meal.</p>

        <h1>Iced Herbal Teas</h1>
        <h2>Different Types of Herbal Teas</h2>
        <p>● Peppermint, chamomile, and  <a href="https://en.wikipedia.org/wiki/Hibiscus_tea"target="_blank" rel="noopener noreferrer">  hibiscus teas</a>.</p>
        <h2>Benefits and Cooling Effects</h2>
        <p>● Some of the natural cooling properties help in bringing down the body temperature.</p>
        <p>● Hydrate without caffeine</p>

        <h1>Conclusion,</h1>
        <p>Here are ten summer foods that can aid in keeping your body cool and adequately hydrated during the summer. Drink lots of water and ensure that you take a balanced diet as well. It is always advisable to <a href="https://www.healthko.in/Online-Doctor-Consultation"target="_blank" rel="noopener noreferrer"> consult a doctor </a>if you are a person with special dietary requirements or medical conditions. Have a nice summer! </p>
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage4;

import styled from 'styled-components';
import { ImageContainer } from '../commonComponents';

export const SectionContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const CustomImageContainer = styled(ImageContainer)`
  width: 50%;
  height: 100%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const GooglePlayIconImg = styled.img`
  width: 180px;
  cursor: pointer;
`;

export const AppStoreIconImg = styled.img`
  width: 170px;
  cursor: pointer;
`;
export const IconContainer = styled.div`
  display: flex;
  
`;
import React, { useState } from "react";
import {
  StyledContactPage,
  ImageContainer,
  ContactImage,
  CustomerSupportInfo,
  FormContainer,
  Form,
  Label,
  Input,
  TextArea,
  Button,
  ModalOverlay,
  ModalContent,
} from "./ContactElements";
import contactImage from "../../images/v1/Contact us.png";

// Functional component ContactUs
function ContactUs() {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    concern: "",
  });

  // State to manage confirmation message visibility
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://api.healthko.in/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      setLoading(false);
      if (response.ok) {
        console.log("Form submitted successfully!");
        // Optionally, you can reset the form data after successful submission
        setFormData({
          name: "",
          email: "",
          phone: "",
          concern: "",
        });
        // Show confirmation message
        setSubmitted(true);
        setError(false);
        // Hide confirmation message after 3 seconds
        setTimeout(() => {
          setSubmitted(false);
        }, 3000);
      } else {
        console.error("Form submission failed:", response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <StyledContactPage>
      <ImageContainer>
        <ContactImage src={contactImage} alt="Contact" />
        <CustomerSupportInfo>
          <p>Customer Support: +917339966643</p>
          <p>Email: support@healthko.in</p>
          <p>Address: Sewak park, Uttam nagar, Delhi,110059, Delhi, India</p>
        </CustomerSupportInfo>
      </ImageContainer>
      <FormContainer>
        <h1>We are here to help.</h1>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="name">Name:</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <Label htmlFor="email">Email:</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <Label htmlFor="phone">Phone:</Label>
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />

          <Label htmlFor="concern">Explain your concern:</Label>
          <TextArea
            id="concern"
            name="concern"
            value={formData.concern}
            onChange={handleChange}
            rows="4"
            required
          />

          <Button type="submit" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Form>
        {error && <p className="error">Failed to submit your concern. Please try again later.</p>}
      </FormContainer>
      {submitted && (
        <ModalOverlay>
          <ModalContent>
            <p>Your concern has been submitted successfully!</p>
          </ModalContent>
        </ModalOverlay>
      )}
    </StyledContactPage>
  );
}

export default ContactUs;

import React, { useEffect, useState } from 'react';
import {
  ModalContent,
  SessionContainer,
  SessionHeading,
  SessionParagraph,
  SliderConatiner,
} from '../commonComponents';
import Modal from '../Modal/Modal';
import { useNavigate,Link } from 'react-router-dom';
import {
  doctorDetails,
  multipleSliderSettings,
  multipleSlides,
} from '../../constants/constants';
import SessionCard from './SessionCard/SessionCard';
import onlineIcon from '../../images/v1/online-green.svg';
import {
  Btn,
  BtnStyle,
  BtnWrapepr,
  ChatButton,
  ConsultationBody,
  ConsultationForm,
  CustomCheckBox,
  CustomDoctorsDetails,
  CustomLabel,
  CustomLink,
  CustomModalHead,
  DoctorImageDetails,
  DoctorList,
  DoctorName,
  DoctorsImage,
  Experince,
  Flex,
  FlexBody,
  FlexImageContainer,
  FormContainer,
  Label,
  LeftIcon,
  ModalHead,
  Paragraph1,
  SessionParagraph1,
  SliderHeading,
  Wrapper,
} from './VideoConsultationElements';
import DoctorCard from './SessionCard/DoctorCard';
import { Terms, TermsSection } from '../AuthUI/AuthUIElements';
import { FaChevronLeft } from 'react-icons/fa6';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { TbVideo } from 'react-icons/tb';
import doctorImg from '../../images/v1/doctor1.jpg';
import ReactSlider from '../ReactSlider/ReactSlider';
import useConsultationHook from '../../hooks/ConsultationHook';
import { useAuthContext } from '../../hooks/AuthContextHook';

function VideoConsultation() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const { auth } = useAuthContext();

  const { getDoctors, doctorArray } = useConsultationHook();

  const handleCloseModal = () => {
    navigate(-1);
    setIsOpen(false);
  };

  const handleDepartmentSelection = (departmentId) => {
    setStep(2);
  };

  const handleForm = (e) => {
    e.preventDefault();
    setStep(3);
  };

  const handleInputChange = (e) => {};

  useEffect(() => {
    if (auth.isLogged) getDoctors('video');
  }, [auth.isLogged]);

  const getPadding = () => (step === 2 || step === 3 ? 0 : '3rem');
  if (auth.isLogged)
    return (
      <SessionContainer>
        <Modal isOpen={isOpen} closeModal={handleCloseModal}>
          <ModalContent padding={getPadding}>
            {step === 1 ? (
              <>
                <ModalHead>
                  <SessionHeading>Select specialist</SessionHeading>
                  <SessionParagraph>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque in etiam
                    tempor amet sit sit gravida elit. Imperdiet eget euismod sit
                    tempor. Enim consectetur senectus placerat aliquet varius
                    commodo faucibus. Ac accumsa.
                  </SessionParagraph>
                </ModalHead>
                <SliderConatiner>
                  <ReactSlider settings={multipleSliderSettings}>
                    {multipleSlides.map((slide) => {
                      return (
                        <div key={slide.id}>
                          <SessionCard
                            slide={slide}
                            handleDepartmentSelection={
                              handleDepartmentSelection
                            }
                          />
                        </div>
                      );
                    })}
                  </ReactSlider>
                </SliderConatiner>
              </>
            ) : step === 2 ? (
              <>
                <CustomModalHead color='#e1e1e1'>
                  <LeftIcon onClick={() => setStep(step - 1)}>
                    <FaChevronLeft />
                  </LeftIcon>
                  <SessionHeading>Select specialist</SessionHeading>
                  <SessionParagraph>
                    View our doctors currently online &nbsp;
                    <img src={onlineIcon} alt='online' />
                  </SessionParagraph>
                </CustomModalHead>
                <ConsultationBody>
                  <DoctorList>
                    {doctorDetails && doctorDetails.length ? (
                      doctorDetails.map((doctor) => <DoctorCard {...doctor} />)
                    ) : (
                      <Label>No doctors found</Label>
                    )}
                  </DoctorList>
                  <ConsultationForm onSubmit={handleForm}>
                    <Label>Select language</Label>
                    <FormContainer>
                      <CustomLabel for='languague'>English</CustomLabel>
                      <CustomCheckBox
                        type='checkbox'
                        id='languague'
                        name='languague'
                        value={'english'}
                        onChange={handleInputChange}
                      />
                    </FormContainer>
                    <FormContainer>
                      <CustomLabel for='nationality'>Indian</CustomLabel>
                      <CustomCheckBox
                        type='checkbox'
                        id='nationality'
                        name='nationality'
                        value={'indian'}
                        onChange={handleInputChange}
                      />
                    </FormContainer>
                    <Label>Iterations</Label>
                    <FormContainer>
                      <Wrapper>
                        <CustomLabel for='sigleConsultation'>
                          Single consultation
                        </CustomLabel>
                        <SessionParagraph1>
                          Chat audio, video consultation free 7 day, follow up
                        </SessionParagraph1>
                      </Wrapper>
                      <CustomCheckBox
                        type='checkbox'
                        id='sigleConsultation'
                        name='sigleConsultation'
                        value={'sigleConsultation'}
                        onChange={handleInputChange}
                      />
                    </FormContainer>

                    <TermsSection>
                      <CustomCheckBox
                        type='checkbox'
                        id='terms'
                        name='terms'
                        value={'agree'}
                        onChange={handleInputChange}
                      />
                      <Terms for='terms' >
                        I agree to the Terms and Conditions. &nbsp;
                        <span onClick={()=>{navigate('/videotnc/1')}}><CustomLink >Read T & c</CustomLink></span>
                      </Terms>
                    </TermsSection>
                    <BtnWrapepr type='submit'>₹ 549 Consult Now</BtnWrapepr>
                  </ConsultationForm>
                </ConsultationBody>
              </>
            ) : step === 3 ? (
              <>
                <CustomModalHead color='transparent'>
                  <LeftIcon onClick={() => setStep(step - 1)}>
                    <FaChevronLeft />
                  </LeftIcon>
                  <SessionHeading>Consultant Doctor</SessionHeading>
                </CustomModalHead>
                <ConsultationBody topMargin>
                  <DoctorImageDetails>
                    <FlexImageContainer>
                      <DoctorsImage src={doctorImg} alt='p' />
                    </FlexImageContainer>
                    <FlexBody>
                      <DoctorName>Doctor Iram Anwar</DoctorName>
                      <Paragraph1>Surgical superident</Paragraph1>
                      <CustomDoctorsDetails>
                        Graduated from forman christian medical college in 2023
                      </CustomDoctorsDetails>
                      <Flex>
                        <Experince>
                          <BtnStyle black>Patients</BtnStyle>
                          <Paragraph1 center>5K</Paragraph1>
                        </Experince>
                        <Experince>
                          <BtnStyle black>Reviews</BtnStyle>
                          <Paragraph1 center>300K</Paragraph1>
                        </Experince>
                        <Experince>
                          <BtnStyle black>Experience</BtnStyle>
                          <Paragraph1 center>5 years</Paragraph1>
                        </Experince>
                      </Flex>
                    </FlexBody>
                  </DoctorImageDetails>
                  <DoctorImageDetails border>
                    <div>
                      <SliderHeading>About</SliderHeading>
                      <CustomDoctorsDetails>
                        I am a dedicated and compassionate healthcare
                        professional who has undergone extensive medical
                        training and education. As a doctor, my primary mission
                        is to diagnose, treat, and care for patients' physical
                        and emotional well-being.
                      </CustomDoctorsDetails>
                      <DoctorImageDetails paddingTop='3rem'>
                        <ChatButton>
                          <IoChatbubbleEllipsesSharp />
                        </ChatButton>
                        <Btn onClick={handleCloseModal}>
                          <BtnStyle>
                            <TbVideo /> &nbsp; Consult Now
                          </BtnStyle>
                        </Btn>
                      </DoctorImageDetails>
                    </div>
                  </DoctorImageDetails>
                </ConsultationBody>
              </>
            ) : (
              <></>
            )}
          </ModalContent>
        </Modal>
      </SessionContainer>
    );
  return <></>;
}

export default VideoConsultation;

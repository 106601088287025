import React, { useState ,useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import videoConsultationImage from '../../images/v2/banner-clinic.png';
import Footer from '../Footer/Footer';

import {
  imageStyles,
  StyledHeading,
  StyledParagraph,
  Question,
  QuestionContent,
  DropdownIcon,
  Answer,
  FAQContainer,

  

} from "./AboususpagesElements";
import {ImageContainer , StyledButton} from "../NewClinicAppointment/NewServicesElements.js"


const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

function ClinicAppointmentpage() {
  return (
    <div>
    <ImageContainer>
      <img src={videoConsultationImage} alt="Video Consultation" />
      <div className="text-overlay">
      <p className="subtitle">India's best online appointment scheduling platform</p>
        <h1>Book An Clinic Appointment </h1>
        <p>Personalized Care + Priority Access · Starts at just ₹99</p>
        <StyledButton>Book Clinic Appointment</StyledButton>
      </div>
    </ImageContainer>
      <StyledHeading>About Clinic Appointment</StyledHeading>
      <StyledParagraph>The old times of countless phoneless and long waiting are over. Through HealthKo, you can finally eliminate the administrative hassle that usually accompanies the booking of appointments. The issue of the logistics is taken care of, so you can devote yourself to what really matters to you, which is your health.</StyledParagraph>
      <StyledParagraph>Through HealthKo, you will be sure that your healthcare journey will be more smooth and efficient. We are here to take the stress of booking appointments so that you can concentrate on self-care. Say hello to the hassle-free healthcare – say hello to HealthKo.</StyledParagraph>
      <StyledParagraph>The vast number of doctors and clinics that our network of health facilities has ensured that no matter the medical needs, we can find a time that fits well into your schedule. Either you are looking for a general health check up or a specialised consultation, we have you there.</StyledParagraph>
      <StyledParagraph>Nevertheless, our dedication to convenience does not stop there with booking your appointment. Here at HealthKo we think in giving a helping hand in all the phases of the process. Thus, we provide facilities like the appointment reminders and the option to reschedule easily, so that you never miss an important visit.</StyledParagraph>
      <StyledParagraph>Besides, we also comprehend that life is usually unpredictable and there are many times when health issues come out of nowhere. This is the reason why we have created HealthKo to be ready and answer your needs. In case you have an urgent medical problem, our platform can briefly link you to the available appointments, thus giving you the peace of mind when you are in need of it most.</StyledParagraph>
      <StyledParagraph>Apart from just that HealthKo is also providing a personalised service which is based on your individual healthcare journey. Our website safely holds your medical history and views, so we can suggest specialists and facilities that will be the most helpful for you.</StyledParagraph>
      <StyledParagraph>Hence, since transparency and trust are the values, you can be sure that your information is always confidential and secure. We comply with the highest standards of data protection, thus, your privacy is the first thing we care about.</StyledParagraph>
      <StyledParagraph>Thus, no matter if you are dealing with a long-term disease, looking for preventive care, or just needing a check-up, let HealthKo be your health partner. We have the streamlined appointment booking service and the unfailing dedication to your health, hence we are here to make your healthcare experience smooth and stress-free.</StyledParagraph>
      <StyledHeading>Frequently asked questions</StyledHeading>
      <FAQContainer>
      <DropdownItem
        question="How do I book a clinic appointment?"
        answer="You can book clinic appointments either by phone or online through our website. Simply choose a convenient time slot and provide your details."
      />
     <DropdownItem
        question="What information do I need to provide when booking?"
        answer="We require basic personal information such as your name, contact number, and reason for the appointment. Any relevant medical history will also be helpful."
      />
     <DropdownItem
        question="Can I reschedule or cancel my clinic appointment?"
        answer="Yes, you can reschedule or cancel your appointment by contacting the clinic either by phone or through our online booking system. Please provide at least 24 hours notice if possible."
      />
     <DropdownItem
        question="Can I book appointments for family members?"
        answer="Yes, you can book appointments on behalf of family members. Please provide their details accurately when making the booking, including any specific requirements or preferences."
      />
      <DropdownItem
        question="How far in advance can I book an appointment?"
        answer="You can typically book appointments up to a few weeks in advance, depending on availability. We recommend booking as early as possible to secure your preferred time slot."
      />
      <DropdownItem
        question="What do I need to bring to my appointment?"
        answer="Please bring any relevant medical documents and a list of current medications. It's also helpful to arrive a few minutes early to complete any necessary paperwork."
      />
       <DropdownItem
        question="Can I request a specific doctor?"
        answer="Yes, you can request a specific doctor when booking your appointment, subject to their availability. We will do our best to accommodate your preferences whenever possible."
      />
      </FAQContainer>
      
       <Footer />
      
    </div>
  );
}

export default ClinicAppointmentpage;

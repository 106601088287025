import React from "react";
import styled from "styled-components";
import { Container, SectionHeading } from "../../commonComponents";
import { CustomContainer, SliderSection } from "./SepecialistElements";
import ReactSlider from "../../ReactSlider/ReactSlider";
import SpecialistCard from "./SpecialistCard";

import Gynecology from '../../../images/v2/gynocology.png' 
import GeneralPhysician from '../../../images/v2/physician.png'
import Dermatology from '../../../images/v2/dermatology.png'
import Sexology from '../../../images/v2/sexology.png'
import Psychiatry from '../../../images/v2/psychiatry.png'
import Pediatrics from '../../../images/v2/pediatrician.png'
import Pulmonology from '../../../images/v2/pulmonology.png'
import Haematology from '../../../images/v2/Hemotology.png'
import { IoIosArrowForward } from "react-icons/io";

// Sample data for customer reviews
const SpecialSpecialities = [
  { 
    id: 1,
    image: Gynecology,
    name: "Gynecology",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#C4E8F9",
    color2: "#E5F7C3",
 
  },
  // Add more reviews here
  { 
    id: 2,
    image: GeneralPhysician,
    name: "General Physician",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#C4E8F9",
    color2: "#E5F7C3",
   
  },
  { 
    id: 3,
    image: Dermatology,
    name: "Dermatology",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#C4E8F9",
    color2: "#E5F7C3",
  
  },
  { 
    id: 4,
    image: Sexology,
    name: "Sexology",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#CAF0F8",
    color2: "#D8F3DC",
   
  },
  { 
    id: 5,
    image: Psychiatry,
    name: "Psychiatry",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#E5F7C3",
    color2: "#C4E8F9",
    color3: "#E5F7C3",

  },
  { 
    id: 6,
    image: Pediatrics,
    name: "Pediatrics",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#CAF0F8",
    color2: "#D8F3DC",
  
  },
  { 
    id: 7,
    image: Pulmonology,
    name: "Pulmonology",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#CAF0F8",
    color2: "#D8F3DC",

 
  },
  { 
    id: 8,
    image: Haematology,
    name: "Haematology",
    money: "₹499",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
    color1: "#CAF0F8",
    color2: "#D8F3DC",

  },
 
];

// Define slider settings here
const sliderSettings = {
  slidesToShow: 7,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 1500,
  responsive: [
    {
      breakpoint: 1865,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1285,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
  
    {
      breakpoint: 1044,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 788,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 483,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};



const StyledReactSlider = styled(ReactSlider)`
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
`;

const SpecialistService = () => {
  return (
    <Container id="contact">
      <CustomContainer>
        <SliderSection>
          <StyledReactSlider settings={sliderSettings}>
            {SpecialSpecialities.map((review) => (
              <SpecialistCard key={review.id} review={review} />
            ))}
          </StyledReactSlider>
        </SliderSection>
      </CustomContainer>
    </Container>
  );
};

export default SpecialistService;

import React, { useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { GlobalStyle, theme } from "./globalStyles";
import Home from "./pages/home";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import { ThemeProvider } from "styled-components";
import AuthUI from "./components/AuthUI/AuthUI";
import AuthModal from "./components/Modal/AuthModal";
import DoctorAtHome from "./pages/doctorAtHome";
import ClinicAppointment from "./pages/clinicAppointment";
import VideoConsultation from "./pages/videoConsultation";
import { useAuthContext } from "./hooks/AuthContextHook";
import { Videotnc } from "./components/Tnc/videotnc";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import AdminLoginPage from "./components/AdminPanel/AdminLoginPage";
import BlogPage from "./components/Blogs/Blogs";
import PostPage from "./components/Blogs/PostPage";
import PostPage2 from "./components/Blogs/PostPage2";
import ContactUs from "./components/ContactUs/Contactus";
import AboutUs from "./components/AboutUs/AboutUs";
import OurServices from "./components/OurServices/OurServices";
import Service from "./components/ServicesSection/Service";
import VideoConsultationPage from "./components/OurServices/VideoConsultationPage";
import DoctorAtHomePage from "./components/OurServices/DoctorAtHomePage";
import ClinicAppointmentpage from "./components/OurServices/ClinicAppointmentpage";
import NewVideoConsultation from './components/NewOurServices/NewVideoConsultation'
import NewClinicAppointment from './components/NewClinicAppointment/NewClinicAppointment'
import NewDoctorVisitHome from './components/NewDoctorVisitHome/NewDoctorVisitHome'
import PostPage3 from "./components/Blogs/PostPage3";
import PostPage4 from "./components/Blogs/PostPage4";
import PostPage5 from "./components/Blogs/PostPage5";
import PostPage6 from "./components/Blogs/PostPage6";
import PostPage7 from "./components/Blogs/PostPage7";
import PostPage8 from "./components/Blogs/PostPage8";
import Team from "./components/Team_Member/Team";
import PostPage9 from "./components/Blogs/PostPage9";



function App() {
  const [isNavMenuOpen, setNavMenuOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const { auth, updateAuth } = useAuthContext();
  const [AdminLoggedIn, setAdminLoggedIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = (username, password) => {
    // Perform authentication logic here (e.g., call an API)
    // For simplicity, assume login is successful if username and password are not empty
    if (username && password) {
      setLoggedIn(true);
    }
  };

  const openLogin = () => {
    setAuthOpen("login");
    updateAuth({ ...auth, isAuthModalOpen: true });
  };

  const openSignup = () => {
    setAuthOpen("signup");
    updateAuth({ ...auth, isAuthModalOpen: true });
  };

  const toggle = () => {
    setNavMenuOpen(!isNavMenuOpen);
  };

  const onCloseModal = () => {
    setAuthOpen(null);
    updateAuth({ ...auth, isAuthModalOpen: false });
  };
  console.log(auth);
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Sidebar
            isSideBarOpen={isNavMenuOpen}
            toggle={toggle}
            openLogin={openLogin}
            openSignup={openSignup}
          />
          <Navbar
            toggle={toggle}
            openLogin={openLogin}
            openSignup={openSignup}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/video-consultation" element={<VideoConsultation />} />
            <Route path="/doctor-at-home" element={<DoctorAtHome />} />
            <Route path="/clinic-appointment" element={<ClinicAppointment />} />
            <Route path="/videotnc/:variable" element={<Videotnc />} />
            <Route path="/adminlogin" element={<AdminLoginPage />} />
            <Route path="/admindashboard" element={<AdminPanel />} />
            <Route path="/blogs" element= {<BlogPage/>} />
            <Route path="/How-to-Protect-Your-Skin-from-the-Sun-Essential-Tips" element= {<PostPage/>} />
            <Route path="/Healthy-Lifestyle-10-Essential-Habits-for-Healthy-Life" element= {<PostPage2/>} />
            <Route path="/Constipation-Causes-Symptoms-and–Treatments" element = {<PostPage3/>} />
            <Route path="/10-Best-Summer-Foods-to-Stay-Cool" element ={ <PostPage4/>} />
            <Route path="/Diabetes-symptoms-and-causes" element ={ <PostPage5/>} />
            <Route path="/Painful-Intercoure-Causes-Symptoms-And-Treatments" element ={<PostPage6/>} />
            <Route path="/Sunburn-Causes-Symptoms-Prevention-and-Treatment" element = {<PostPage7/>} />
            <Route path="/10-Ways-to-Treat-Sunburn-at-Home" element = {<PostPage8/>} />
            <Route path="/Instant-Home-Remedies-For-Migraine" element = {<PostPage9/>} />
            <Route path="/Contact-Us" element = {<ContactUs/>} />
            <Route path ="/ContactUs" element = {<AboutUs/>} />
            <Route path ="/AboutUS" element ={<OurServices/>} />
            <Route path ="/OurService" element ={<Service/>} />
            <Route path ="/video-consultation-services" element ={<VideoConsultationPage/>}/>
            <Route path ="/Doctor-At-Home-Page" element = {<DoctorAtHomePage/>} />
            <Route path="/Clinic-Appointment-page" element = {<ClinicAppointmentpage/>} />
            
            <Route path="/Online-Doctor-Consultation" element = {<NewVideoConsultation/>} />
            <Route path="/Book-An-Appointment" element = {<NewClinicAppointment/>} />
            <Route path="/Doctor-Home-Visit" element = {<NewDoctorVisitHome/>} />
            <Route path="/Team_Member" element = {<Team/>} />
          </Routes>

          <AuthModal closeModal={onCloseModal} label={authOpen}>
            <div>
              <AuthUI activeState={authOpen} />
            </div>
          </AuthModal>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { Container, SectionHeading } from "../commonComponents";
import { CustomContainer, SliderSection } from "../OurServices/OurServicesElements"; // Updated import path
import ReactSlider from "../ReactSlider/ReactSlider";
import CustomerReviewBox from "./CustomerReviewBox"; // Assuming you have a CustomerReviewBox component

// Sample data for customer reviews
const customerReviews = [
  { id: 1,
    name: "Amarya Mittal ",
    age: 25, rating: 4, 
    review: "So helpful. It is so easy to book an appointment with doctors online. And so easy to use it with your phone. Thank you Healthko for making things simple. ",
    image: "john.jpg",
    place: "South Delhi",
   },
  // Add more reviews here
  { id: 2,
    name: "Abhay Jain",
    age: 27, rating: 5, 
    review: "Best part is that they offer healthcare at home. Their Professional healthcare at home service is so efficient and reliable, Highly recommended for Anyone who is seeking healthcare from their home. ", 
    image: "jane.jpg" ,
    place: "South Delhi",
  },
  { id: 3, 
    name: "Himshika Singh", 
    age: 35, rating: 4, 
    review: "Very nice app. So easy for booking/rescheduling/cancelling any appointments. So easy to access past medical history. HealthKo is the future of Healthcare for sure.", 
    image: "alice.jpg" ,
    place: "South Delhi",
  },
  { id: 4, 
    name: "Vikas Sharma", 
    age: 28, rating: 4, 
    review: "Very easy to access it with a phone and the doctors are so professional and had a good experience.", 
    image: "bob.jpg" ,
    place: "South Delhi",
  },
  { id: 5, 
    name: "Mayank Jain", 
    age: 32, rating: 5, 
    review: "I booked a regular health check for my grandfather from HealthKo using their service Doctor at home. The process was so smooth and easy. And he provides us the reports on the same day.", 
    image: "emily.jpg" ,
    place: "South Delhi",
  },
  { id: 6, 
    name: "Avik Rajoriya ", 
    age: 30, rating: 4, 
    review: "Best experience to book a healthcare professional at home without going out of my house.Highly recommended.", 
    image: "john.jpg" ,
    place: "East Delhi",
  },
  // Add more reviews here
  { id: 7, 
    name: "Akansha Mittal", 
    age: 25, rating: 5, 
    review: "They serve within specified deadlines and they ensure safety and privacy. And company help and support provides help quickly not only in person but also on their website and via phone.", 
    image: "jane.jpg",
    place: "South Delhi",
   },
  { id: 8, 
    name: "Sharad Singh", 
    age: 35, rating: 4, 
    review: "Healthko physiotherapist has been a great help to get rid of my shoulder pain. A Couple of days ago I got an injury in my shoulder muscle in the gym. I was so worried then I saw their services and booked a consultation and they really helped me out. I’ll highly recommend Healthko to everyone.", 
    image: "alice.jpg" ,
    place: "North Delhi",
  },
  { id: 9, 
    name: "Tushar Saraf", 
    age: 28, rating: 3, 
    review: "We booked a clinic appointment with Healthko and when I reached the clinic the best part was there the nurse with us and helped us throughout the visit with a positive attitude. That was so helpful, thank you Healthko.", 
    image: "bob.jpg" ,
    place: "South Delhi",
  },
  { id: 10, 
    name: "Aman Sharma", 
    age: 32, rating: 5, 
    review: "Suddenly one evening my mom got ill and I was in the office when one of my colleagues suggested to me about Healthko. I booked a healthcare professional from Healthko and the nurse immediately came and checked my mom. Thank You Healthko you guys are doing commedible job.", 
    image: "emily.jpg" ,
    place: "East Delhi",
  },
];

// Define slider settings here
const sliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  // autoplaySpeed: 1500,
  responsive: [
    {
      breakpoint: 1865,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
  
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

const StyledSliderSection = styled(SliderSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* Adjust margin top as needed */
`;

const StyledReactSlider = styled(ReactSlider)`
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
`;

const CustomerReview = () => {
  return (
    <Container id="contact">
      <CustomContainer>
        <SectionHeading>Patient Reviews</SectionHeading>
        <SliderSection>
          <StyledReactSlider settings={sliderSettings}>
            {customerReviews.map((review) => (
              <CustomerReviewBox key={review.id} review={review} />
            ))}
          </StyledReactSlider>
        </SliderSection>
      </CustomContainer>
    </Container>
  );
};

export default CustomerReview;

import React from "react";
import styled from "styled-components";
import { Container, SectionHeading } from "../../commonComponents";
import { CustomContainer, SliderSection } from "./SepecialistElements";
import ReactSlider from "../../ReactSlider/ReactSlider";
import SpecialistCard from "./SpecialistCard";
import clinicimage from '../../../images/v2/Cliniccard.jpg';
import { IoIosArrowForward } from "react-icons/io";

// Sample data for customer reviews
const SpecialSpecialities = [
  { 
    id: 1,
    image: clinicimage,
    name: "Sunrise Clinic",
    money: "₹ 99",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
   
 
  },
  // Add more reviews here
  { 
    id: 2,
    image: clinicimage,
    name: "Sunrise Clinic",
    money: "₹ 99",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
 
   
  },
  { 
    id: 3,
    image: clinicimage,
    name: "Sunrise Clinic",
    money: "₹ 99",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
   
  
  },
  { 
    id: 4,
    image: clinicimage,
    name: "Sunrise Clinic",
    money: "₹ 99",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
   
   
  },
  { 
    id: 5,
    image: clinicimage,
    name: "Sunrise Clinic",
    money: "₹ 99",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
   

  },
  { 
    id: 6,
    image: clinicimage,
    name: "Sunrise Clinic",
    money: "₹ 99",
    link: "https://play.google.com/store/apps/details?id=com.ahsclient.app",
    buttonSymbol: <IoIosArrowForward />,
  
  
  },
 
 
];

// Define slider settings here
const sliderSettings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 1500,
  responsive: [
    {
      breakpoint: 1865,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1285,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
  
    {
      breakpoint: 1044,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 788,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 483,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};



const StyledReactSlider = styled(ReactSlider)`
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
`;

const SpecialistService = () => {
  return (
    <Container id="contact">
      <CustomContainer>
        <SliderSection>
          <StyledReactSlider settings={sliderSettings}>
            {SpecialSpecialities.map((review) => (
              <SpecialistCard key={review.id} review={review} />
            ))}
          </StyledReactSlider>
        </SliderSection>
      </CustomContainer>
    </Container>
  );
};

export default SpecialistService;

// src/components/AdminPanel.js

import React, { useState } from "react";
import Sidebar from "./Sidebar";
import DoctorList from "./DoctorList";
import { doctors } from "./mockData"; // Assuming you have doctor data in mockData.js
import styled from "styled-components";

const AdminPanelWrapper = styled.div`
  display: flex;
`;

const SidebarWrapper = styled.div`
  width: 200px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
`;

const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState("doctors");

  const handleDeleteDoctor = (id) => {
    // Implement delete doctor functionality
  };

  const handleApproveDoctor = (id) => {
    // Implement approve doctor functionality
  };

  return (
    <AdminPanelWrapper>
      <SidebarWrapper>
        <Sidebar setActiveTab={setActiveTab} />
      </SidebarWrapper>
      <ContentWrapper>
        {activeTab === "doctors" && (
          <DoctorList
            doctors={doctors}
            onDeleteDoctor={handleDeleteDoctor}
            onApproveDoctor={handleApproveDoctor}
          />
        )}
        {/* Add other tabs and their corresponding components here */}
      </ContentWrapper>
    </AdminPanelWrapper>
  );
};

export default AdminPanel;

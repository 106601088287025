import React from "react";
import styled from "styled-components";
import { Container, SectionHeading } from "../commonComponents";
import { CustomContainer, SliderSection } from "../OurServices/OurServicesElements"; // Updated import path
import ReactSlider from "../ReactSlider/ReactSlider";
import DoctorReviewBox from "./DoctorReviewBox"; // Assuming you have a CustomerReviewBox component

// Sample data for customer reviews
const DoctorReviews = [
  { id: 1,
    name: "Dr. Shivangi",
    age: 'Gynaecologist' , rating: 4, 
    review: "They are just amazing!! I am a gynaecologist and I can understand that many young patients have doubts and hesitations to consult with a gynaecologist. Now they can use online consultation which helps them to consult more comfortably. Keep it up Healthko",
    image: "john.jpg"
   },
  // Add more reviews here
  { id: 2,
    name: "Dr. Sanjeev Kumar",
    age: 'Dermatologist' , rating: 5, 
    review: "As a dermatologist specialist i can say It has been such a wonderful experience using Healthko. Their executives are so responsive in their service. And I can easily manage consultations with their app and website. Looking forward to working further with Healthko", 
    image: "jane.jpg" 
  },
  { id: 3, 
    name: "Dr. Ashish Khattar	", 
    age: 'Doctor Consultation', rating: 4, 
    review: "It's a revolution in the healthcare field. Keep it up guys I’m really looking forward to work with you and to be a part of the healthcare revolution. ", 
    image: "alice.jpg" 
  },
  { id: 4, 
    name: "Dr. Dheeraj Setia", 
    age: 'Online consultation', rating: 4, 
    review: "Online consultation is the future of healthcare. I'm a doctor and I can't assist patients beyond my geographical limits. That's where Healthko comes, there are many patients who seek help from small villages and towns. We can give consultation to them also  ", 
    image: "bob.jpg" 
  },
  { id: 5, 
    name: "Dr. Dlip Kumar", 
    age: 'Doctor Consultation', rating: 5, 
    review: "As a nurse, I know quite well there are  some specific problems that the elderly patients have when it comes to healthcare. Healthko online platform has managed to overcome these barriers for the patients thus bringing the diagnostic care directly to the doorstep of the patients with the help of their doctor consultation at home. The platform has a friendly and professional support team. Makes it easy to use", 
    image: "emily.jpg" 
  },
  { id: 6, 
    name: "Dr. Rohan Hinduja", 
    age: 'Visit at their Home', rating: 4, 
    review: "Healthko makes it easier for everyone to select a time that works best for them for a consultation visit at their home. The healthcare professionals can then arrive at the patient’s residence with self-accountability and professionalism. The interface is simple to use and the support staff's service is great in reputation for quick response to any issues or inquiries. I value the convenience of good medical care that can be achieved without going to a clinic. Thanks to Healthko", 
    image: "john.jpg" 
  },
  { id: 7, 
    name: "Dr. Vikram Saha", 
    age: 'Urgent Consultations', rating: 4, 
    review: "The home health services have transformed the game with door-to-door treatment permits me to present personalised care to my patients in their dwellings. In the end, I am certain that all my patients will receive their appropriate amount of medical attention both in routine check-ups and urgent consultations.Highly recommended for fellow doctors looking to enhance their practice!", 
    image: "john.jpg" 
  },
  // Add more reviews here

];

// Define slider settings here
const sliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  // autoplaySpeed: 1500,
  responsive: [
    {
      breakpoint: 1865,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
  
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

const StyledSliderSection = styled(SliderSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* Adjust margin top as needed */
`;

const StyledReactSlider = styled(ReactSlider)`
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
`;

const DoctorReview = () => {
  return (
    <Container id="contact">
      <CustomContainer>
        <SectionHeading>Doctor Reviews</SectionHeading>
        <StyledSliderSection>
          <StyledReactSlider settings={sliderSettings}>
            {DoctorReviews.map((review) => (
              <DoctorReviewBox key={review.id} review={review} />
            ))}
          </StyledReactSlider>
        </StyledSliderSection>
      </CustomContainer>
    </Container>
  );
};

export default DoctorReview;

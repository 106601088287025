import React from 'react';
import ModalComponent from 'react-modal';
import { IoMdCloseCircle } from 'react-icons/io';
import { CloseBtn } from './ModalElements';
import { useAuthContext } from '../../hooks/AuthContextHook';

function Modal({ closeModal, label, children, isOpen }) {
  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={label}
      className='Modal'
      shouldCloseOnOverlayClick={false}
      overlayClassName='Overlay'
    >
      <div>
        <CloseBtn onClick={closeModal}>
          <IoMdCloseCircle />
        </CloseBtn>

        {children}
      </div>
    </ModalComponent>
  );
}

export default Modal;

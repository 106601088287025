export function generateNextNDays(startDate, N) {
  const nextNDays = [];
  const currentDate = new Date(startDate);

  for (let i = 1; i < N; i++) {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + i);

    nextNDays.push(nextDate.toDateString());
  }
  return nextNDays;
}

export function getMonth(date) {
  return date.toLocaleString('en-US', { month: 'long' });
}

export function getCurrentDay(currentDate) {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  // Get the day of the week as a number (0-6)
  const dayNumber = currentDate.getDay();

  // Use the day number as an index to get the day name
  const dayName = daysOfWeek[dayNumber];

  return dayName;
}

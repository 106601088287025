import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import {
  StyledHeading,
  FAQContainer,
  Question,
  QuestionContent,
  DropdownIcon,
  Answer
} from '../NewClinicAppointment/NewServicesElements.js';

const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

const FAQ = () => {
  return (
    <>
      <StyledHeading>Frequently Asked Questions</StyledHeading>
      <FAQContainer>
        <DropdownItem
          question="Why to Choose HealthKo. ?"
          answer="Healthko is a leading healthcare service provider who offers advanced technology and quality healthcare for its patients with personalized healthcare  that too with prioritizing the data security. "
        />
        <DropdownItem
          question="How can I join HealthKo as a healthcare provider?"
          answer="Healthcare professionals can join HealthKo by registering on our platform as a   verified doctor or Healthcare professional . Please download our Doctor side app and register over there providing  required documentation."
        />
         <DropdownItem
          question="What is HealthKo and  What are its services ?"
          answer={
            <>
             <p style={{ marginBottom: '1rem' }}>HealthKo is a technology-driven healthcare platform that helps  patients to connect with professional  healthcare providers . Here are the HealthKo services :</p>  

              <ol>
               <ul>●	Home visit doctors</ul>
               <ul>●	Online or video consultations</ul>
               <ul>●	 Clinic slot Booking</ul>
              </ol>
            </>
          }
        />
         <DropdownItem
          question="How many A.I are being used by Healthko ?"
          answer={
            <>
            <p style={{ marginBottom: '1rem' }}> There are several kinds of A.I which are being used by Healthko some of them are :  patients to connect with professional  healthcare providers . Here are the HealthKo services :</p>  

              <ol>
               <ul>●	AI-Powered Prescriptions </ul>
               <ul>●	AI-Enhanced Doctor Matching </ul>
               <ul>●	AI Doctor for General Queries </ul>
               <ul>●	AI Chatbot for Booking and 24/7 Support </ul>
              </ol>
            </>
          }
        />
         <DropdownItem
          question="What A.I are used in Home healthcare service ?"
          answer={
            <>
             <p style={{ marginBottom: '1rem' }}>AI-Enhanced Doctor Matching : HealthKo utilizes an advanced AI filter that analyzes extensive doctor data to match patients with the most suitable healthcare provider.</p>
             <p style={{ marginBottom: '1rem' }}>7-days monitoring AI : The A.I monitor’s on the patient for 7 days from the day of home visit , AI checks if the patient is making progress or not , it also reminds the patient to take medicines on time .</p>
            </>
          }
        />
         <DropdownItem
          question="“AI Doctor for General Queries” what is that ?"
          answer="HealthKo has designed a AI Doctor to address general medical queries
          This AI Doctor is trained on Millions data to provide accurate and reliable answers to common health questions with immediate assistance."
        />
         <DropdownItem
          question="What A.I are used in Online or video consultations services ?"
          answer={
            <>
            <p style={{ marginBottom: '1rem' }}>AI-Enhanced Doctor Matching : HealthKo utilizes an advanced AI filter that analyzes extensive doctor data to match patients with the most suitable healthcare provider. It helps HealthKo to enhance the quality of care.</p>
            <p style={{ marginBottom: '1rem' }}>AI Chatbot : HealthKo's AI Chatbot offers seamless round-the-clock support. It can also help the user to connect with the doctor in any of the three ways: Home visit doctors , Online or video consultations, clinic slot booking.</p>
            <p style={{ marginBottom: '1rem' }}>3-days monitoring AI : The A.I monitor’s on the patient for 3 days from the day of Online or video consultations , AI checks if the patient is making progress or not , it also reminds the patient to take medicines  on time .</p>
            </>
          }
        />
         <DropdownItem
          question="Is my personal health information safe with HealthKo?"
          answer="Yes, HealthKo prioritizes the security and confidentiality of your personal health information. We adhere to strict privacy standards and regulations to ensure your data is protected."
        />
      </FAQContainer>
    </>
  );
};

export default FAQ;

import { styled } from "styled-components";
import { Paragraph } from "../commonComponents";

export const ServiceContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: fit-content;
    margin-top: -1rem;
  }
`;

export const ServiceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0px 0px 19px 0px rgba(126, 126, 126, 0.13);
  // transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }

  width: 235px;
  height: 320px;
  flex-shrink: 0;

  @media screen and (max-width: 768px) {
    width: 390px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ServiceIcon = styled.img`
  height: 300px;
  width: 109px;
  margin-bottom: 5px;
  padding-top: 10px;

  // &:hover {
  //   padding-top: 0;
  //   padding-bottom: 40px;
  // }
`;

export const ServiceH2 = styled.h2`
  font-size: 1.05rem;
  margin-bottom: -25px;
  text-align: center;
  margin-top: 0.5rem;
`;

export const SectionParagraph = styled(Paragraph)`
  margin-bottom: -1rem;
  font-size: 0.7rem;
  line-height: 1.2;     /* Adjust the line height */

  margin-left: auto;  /* Centering the component horizontally */
  margin-right: auto; /* Centering the component horizontally */
  text-align: center; /* Centering the text within the component */
`;


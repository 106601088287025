import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import {
  Image,
  ModalContent,
  SessionContainer,
  SessionHeading,
} from '../commonComponents';
import {
  Btn,
  Btn1,
  CustomModalHead,
  Flex,
  ImagContainer,
  ModalBody,
  ProgressBarContainer,
  SessionBox,
  SessionParagraph1,
  SlotModalHead,
  Sub,
  TestBox,
  Text,
  TimeH3,
  TimeSlot,
  TimeSlotContainer,
} from './DoctorAtHomeElements';
import {
  CustomLink,
  CustomModalHead as CustomModalHead1,
  Flex as Flex1,
} from '../VideoConsultation/VideoConsultationElements';
import calandarIconBlue from '../../images/v1/calandar-icon-blue.svg';
import calandarIconWhite from '../../images/v1/calandar-icon-white.svg';
import homeVistIconBlue from '../../images/v1/home-visit-icon-blue.svg';
import homeVistIconWhite from '../../images/v1/home-visit-icon-white.svg';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import DateSlider from './DateSlots';
import { TimeSlots } from '../../constants/constants';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
  BtnStyle,
  ConsultationBody,
  CustomDoctorsDetails,
  DoctorImageDetails,
  DoctorName,
  DoctorsImage,
  Experince,
  FlexBody,
  FlexImageContainer,
  LeftIcon,
  Paragraph1,
  SliderHeading,
} from '../VideoConsultation/VideoConsultationElements';
import doctorImg from '../../images/v1/doctor1.jpg';
import useConsultationHook from '../../hooks/ConsultationHook';
import { useAuthContext } from '../../hooks/AuthContextHook';

function DoctorAtHome() {
  const navigate = useNavigate();
  const { auth } = useAuthContext();

  const { getDoctors, createSlots, doctor } = useConsultationHook();
  const [step, setStep] = useState(1);
  const [isHovering, setIsHovering] = useState({ first: false, second: false });
  const [isOpen, setIsOpen] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState(TimeSlots[0]);
  const [prgress, setProgress] = useState(0);

  const handleProgress = () => {
    let counter = 0;
    const intervalId = setInterval(() => {
      counter += 1;
      setProgress(counter);
      if (counter === 100) {
        clearInterval(intervalId);
      }
    }, 10);
  };

  const handleCloseModal = () => {
    navigate(-1);
    setIsOpen(false);
  };

  const handleTimeSelect = (value) => {
    setSelectedTime(value);
  };

  const handleSubmitSlot = () => {
    setStep(3);
    handleProgress();
    createSlots(
      {
        slotTime: selectedTime,
        type: 'home visit',
        totalAmount: 499,
      },
      setStep
    );
  };

  useEffect(() => {
    if (auth.isLogged) getDoctors('homeVisit');
  }, [auth.isLogged]);

  if (auth.isLogged)
    return (
      <SessionContainer>
        <Modal isOpen={isOpen} closeModal={handleCloseModal}>
          <ModalContent padding='2rem'>
            {step === 1 ? (
              <>
                <CustomModalHead>
                  <SessionHeading>Select specialist</SessionHeading>
                  <SessionParagraph1>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque in etiam
                    tempor amet sit sit gravida elit. Imperdiet eget euismod sit
                    tempor. Enim consectetur senectus placerat aliquet varius
                    commodo faucibus. Ac accumsa.
                  </SessionParagraph1>
                </CustomModalHead>
                <ModalBody>
                  <SessionBox
                    onMouseEnter={() => setIsHovering({ first: true })}
                    onMouseLeave={() => setIsHovering({ first: false })}
                    onClick={() => setStep(2)}
                  >
                    <Flex>
                      <ImagContainer>
                        <Image
                          src={
                            isHovering.first
                              ? calandarIconWhite
                              : calandarIconBlue
                          }
                        />
                      </ImagContainer>
                      <Text>Home visit by slot</Text>
                    </Flex>
                    <FaChevronRight />
                  </SessionBox>
                  <SessionBox
                    onMouseEnter={() => setIsHovering({ second: true })}
                    onMouseLeave={() => setIsHovering({ second: false })}
                  >
                    <Flex>
                      <ImagContainer>
                        <Image
                          src={
                            isHovering.second
                              ? homeVistIconWhite
                              : homeVistIconBlue
                          }
                        />
                      </ImagContainer>
                      <Text>Home visit immediate</Text>
                    </Flex>
                    <FaChevronRight />
                  </SessionBox>
                
                </ModalBody>
              </>
            ) : step === 2 ? (
              <>
                <SlotModalHead>
                  <SessionHeading>Select slot</SessionHeading>
                  <SessionParagraph1>
                    Well assign a doctor after you select the slot
                  </SessionParagraph1>
                </SlotModalHead>

                <ModalBody border>
                  <TestBox>
                    <TimeH3>Select Date</TimeH3>
                    <Sub>{selectedMonth}</Sub>
                  </TestBox>
                  <DateSlider
                    setSelectedMonth={setSelectedMonth}
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                    setSelectedDay={setSelectedDay}
                  />
                  <TestBox>
                    <TimeH3>Select a time</TimeH3>
                    <Sub>{selectedDay}</Sub>
                  </TestBox>
                  <TimeSlotContainer>
                    {TimeSlots.map((i) => {
                      let style = {};
                      if (i === selectedTime)
                        style = {
                          color: '#fff',
                          backgroundColor: '#009FFD',
                        };

                      return (
                        <TimeSlot
                          style={style}
                          key={i}
                          onClick={() => handleTimeSelect(i)}
                        >
                          {i}
                        </TimeSlot>
                      );
                    })}
                  </TimeSlotContainer>

                  <Btn onClick={handleSubmitSlot}>Reserve Slot</Btn>
                </ModalBody>
              </>
            ) : step === 3 ? (
              <>
                <CustomModalHead>
                  <SessionHeading>Searching doctors</SessionHeading>
                  <TimeH3 style={{ marginTop: '2rem' }}>
                    We will assign you a top doctor immediately and notify you!
                  </TimeH3>
                  <ProgressBarContainer>
                    <CircularProgressbar
                      styles={buildStyles({
                        pathColor: '#0275FF',
                        trailColor: 'rgba(2, 117, 255, 0.2)',
                      })}
                      strokeWidth={10}
                      value={prgress}
                    />
                  </ProgressBarContainer>
                  <Btn onClick={() => setStep(4)} disabled={prgress !== 100}>
                    Done
                  </Btn>
                </CustomModalHead>
              </>
            ) : (
              <>
                <CustomModalHead1 color='transparent'>
                  <LeftIcon onClick={() => setStep(step - 1)}>
                    <FaChevronLeft />
                  </LeftIcon>
                  <SessionHeading>Consultant Doctor</SessionHeading>
                </CustomModalHead1>
                <ConsultationBody topMargin>
                  <DoctorImageDetails>
                    <FlexImageContainer>
                      <DoctorsImage src={doctorImg} alt='p' />
                    </FlexImageContainer>
                    <FlexBody>
                      <DoctorName>{doctor.name}</DoctorName>
                      <Paragraph1>Surgical superident</Paragraph1>
                      <CustomDoctorsDetails>
                        Graduated from forman christian medical college in 2023
                      </CustomDoctorsDetails>
                      <Flex1>
                        <Experince>
                          <BtnStyle black>Patients</BtnStyle>
                          <Paragraph1 center>5K</Paragraph1>
                        </Experince>
                        <Experince>
                          <BtnStyle black>Reviews</BtnStyle>
                          <Paragraph1 center>300K</Paragraph1>
                        </Experince>
                        <Experince>
                          <BtnStyle black>Experience</BtnStyle>
                          <Paragraph1 center>5 years</Paragraph1>
                        </Experince>
                      </Flex1>
                    </FlexBody>
                  </DoctorImageDetails>
                  <DoctorImageDetails border>
                    <div>
                      <SliderHeading>About</SliderHeading>
                      <CustomDoctorsDetails>
                        I am a dedicated and compassionate healthcare
                        professional who has undergone extensive medical
                        training and education. As a doctor, my primary mission
                        is to diagnose, treat, and care for patients' physical
                        and emotional well-being.
                      </CustomDoctorsDetails>
                      <SliderHeading style={{ marginTop: '1rem' }}>
                        Appointment
                      </SliderHeading>
                      <CustomDoctorsDetails>
                        {selectedDate + ', ' + selectedTime}
                      </CustomDoctorsDetails>
                      <DoctorImageDetails paddingTop='3rem'>
                        <Btn1 onClick={() => navigate(-1)}>
                          <BtnStyle>Done</BtnStyle>
                        </Btn1>
                      </DoctorImageDetails>
                    </div>
                  </DoctorImageDetails>
                </ConsultationBody>
              </>
            )}
          </ModalContent>
        </Modal>
      </SessionContainer>
    );
  return <></>;
}

export default DoctorAtHome;

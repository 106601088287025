import styled from 'styled-components';
import { ModalHead } from '../VideoConsultation/VideoConsultationElements';
import { PrimaryBtn, SessionParagraph } from '../commonComponents';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export const SessionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: 1.5rem;
  border-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  cursor: pointer;
  padding-left: 3rem;
  color: ${(props) => props.theme.colors.primary};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
`;

export const ImagContainer = styled.div`
  width: 30px;
  height: 30px;
`;

export const Text = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 96% */
`;

export const CustomModalHead = styled(ModalHead)`
  width: 600px;
`;

export const SlotModalHead = styled(ModalHead)`
  max-width: 100%;
  border-bottom: 1px solid;
  border-color: #cbcbcb;
  padding-bottom: 1rem;
  margin: 0 auto;
`;

export const SessionParagraph1 = styled(SessionParagraph)`
  max-width: 100%;
  width: 100%;
`;

export const HorizondalLine = styled.hr`
  border-color: pink;
  outline-color: pink;
`;

export const TimeH3 = styled.h3`
  color: #2e2e2e;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 82.759% */
  letter-spacing: 0.53px;
`;

export const Sub = styled.p`
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 114.286% */
  letter-spacing: 0.53px;
  text-transform: capitalize;
`;

export const TestBox = styled.div`
  margin: 0 3rem;
`;

export const TimeSlot = styled.div`
  width: 100px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #b9b9b9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
`;

export const TimeSlotContainer = styled.div`
  margin: 0 3rem;
  display: flex;
  gap: 1rem;
`;

export const Btn = styled(PrimaryBtn)`
  margin: 0 3rem;
  max-width: fit-content;
  height: fit-content;
  min-width: 200px;
`;

export const Btn1 = styled(Btn)`
  margin: 0;
  margin-top: -2rem;
`;

export const ProgressBarContainer = styled.div`
  max-width: 180px;
  margin: 2rem;
`;

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  StyledBlogPage,
  BlogContent,
  BlogItem,
  BlogDetails,
  BlogAuthor,
  SearchBarWrapper,
  SearchInput,
  SearchBtn,
  ServiceCard,
  ServiceH2,
  ServiceIcon,
} from "./BlogsELements";
import { SectionHeading } from '../commonComponents';
import HealthKoBlogs from "../../images/blogsimage/HealthKoBlogs.jpg";
import HealthKoBlogs2 from "../../images/blogsimage/BlogsPost2.png"
import HealthKoBlogs3 from "../../images/blogsimage/HealthKoBlogs3.jpg";
import HealthKoBlogs4 from "../../images/blogsimage/HealthKoBlogs4.jpg";
import HealthKoBlogs5 from "../../images/blogsimage/HealthKoBlogs5.jpg";
import HealthKoBlogs6 from "../../images/blogsimage/HealthKoBlogs6.jpg";
import HealthKoBlogs7 from "../../images/blogsimage/HealthKoBlogs7.jpg";
import HealthKoBlogs8 from "../../images/blogsimage/HealthKoBlogs8.jpg";
import HealthKoBlogs9 from "../../images/blogsimage/HealthKoBlogs9.jpg";
import Footer from "../Footer/Footer";

const BlogPage = () => {
  const navigate = useNavigate();

  const blogContentData = [
    {
      id: 9,
      imageUrl: HealthKoBlogs9,
      title: "Instant Migraine Relief at Home: Powerful Home Remedies",
      subtitle: "Don't suffer! Find instant migraine relief at home with powerful natural remedies. Explore effective methods like cool compresses, ginger, & stress management.",
      destination: '/Instant-Home-Remedies-For-Migraine',
    },
    {
      id: 8,
      imageUrl: HealthKoBlogs8,
      title: "10 Effective Ways to Treat Sunburn at Home | Sunburn Relief Tips",
      subtitle: " Discover 10 home remedies to treat sunburn effectively. Learn how to soothe, heal, and relieve sunburn pain with these simple, dermatologist-approved tips.",
      destination: '/10-Ways-to-Treat-Sunburn-at-Home',
    },
    {
      id: 7,
      imageUrl: HealthKoBlogs7,
      title: "Sunburn Causes and Symptoms: Prevention and Treatment Tips",
      subtitle: " Discover the causes and symptoms of sunburn, along with prevention strategies and treatment options. Learn how to protect your skin and manage sunburn effectively.",
      destination: '/Sunburn-Causes-Symptoms-Prevention-and-Treatment',
    },
    {
      id: 6,
      imageUrl: HealthKoBlogs6,
      title: "Painful intercourse (dyspareunia) - Causes,Symptoms and Treatment",
      subtitle: "Experiencing pain during sex? Learn about the causes, symptoms, and treatments of dyspareunia. Find relief and improve your intimate life.",
      destination: '/Painful-Intercoure-Causes-Symptoms-And-Treatments',
    },
    {
      id: 5,
      imageUrl: HealthKoBlogs5,
      title: "Understanding Diabetes: Symptoms, Causes, Types, Treatments, and Prevention",
      subtitle: "Diabetes is a long-term condition affecting the way the body processes blood sugar or glucose that is gotten from food. Studies show that more than 400 million people around the globe have diabetes and this is a very big issue.",
      destination: '/Diabetes-symptoms-and-causes',
    },
    {
      id: 4,
      imageUrl: HealthKoBlogs4,
      title: " 10 Best Summer Foods to Stay Cool and Hydrated ",
      subtitle: " Discover 10 best summer foods that help you stay cool and hydrated. Beat the heat with these refreshing fruits and vegetables. ",
      destination: '/10-Best-Summer-Foods-to-Stay-Cool',
    },
    {
      id: 3,
      imageUrl: HealthKoBlogs3,
      title: " Constipation: Causes, Symptoms, and Effective Treatments",
      subtitle: " Discover the causes, symptoms, and effective treatments for constipation. Learn how dietary and lifestyle changes can help manage and prevent this common issue. ",
      destination: '/Constipation-Causes-Symptoms-and–Treatments',
    },
    {
      id: 2,
      imageUrl: HealthKoBlogs2,
      title: " Healthy Lifestyle: 10 Essential Habits for Healthy Life",
      subtitle: " Discover the top 10 essential habits for maintaining a healthy lifestyle. From exercise to nutrition, learn how to master wellness for a happier, healthier you.We'll delve into 10 essential habits that form a healthy lifestyle, each contributing its unique essence to well-being. ",
      destination: '/Healthy-Lifestyle-10-Essential-Habits-for-Healthy-Life',
    },
    {
      id: 1,
      imageUrl: HealthKoBlogs,
      title: "How to Protect Your Skin from the Sun: Essential Tips",
      subtitle: "Description: Learn effective ways to protect your skin from the sun with sun protection tips, sunscreen advice, and protective clothing recommendations.",
      destination: '/How-to-Protect-Your-Skin-from-the-Sun-Essential-Tips',
    },
   
   
  


   
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    console.log("Search query:", searchQuery);
  };

  const handleNavigation = (destination) => {
    navigate(destination); 
  };

  return (
    <StyledBlogPage>
      <SectionHeading className="blogs">Healthy Lifestyle</SectionHeading>
      <SearchBarWrapper>
        <SearchInput
          type="text"
          placeholder="Search by Doctors or Speciality"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <SearchBtn onClick={handleSearchSubmit}>Search</SearchBtn>
      </SearchBarWrapper>
      <BlogContent>
        {blogContentData.map((item) => (
          <ServiceCard key={item.id} onClick={() => handleNavigation(item.destination)}>
            <BlogItem>
              <ServiceIcon src={item.imageUrl} alt={`Blog Image ${item.id}`} />
              <BlogDetails>
                <ServiceH2>{item.title}</ServiceH2>
                <BlogAuthor>{item.subtitle}</BlogAuthor>
              </BlogDetails>
            </BlogItem>
          </ServiceCard>
        ))}
      </BlogContent>
      <Footer/>
    </StyledBlogPage>
  );
};

export default BlogPage;



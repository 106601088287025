import React from 'react';
import DoctorAtHome from '../components/DoctorAtHome/DoctorAtHome';
import { useAuthContext } from '../hooks/AuthContextHook';
import { useEffect } from 'react';

function DoctorAtHomePage() {
  const { auth, updateAuth } = useAuthContext();

  useEffect(() => {
    if (!auth.isLogged) updateAuth({ ...auth, isAuthModalOpen: true });
    return () => {
      updateAuth({ ...auth, isAuthModalOpen: false });
    };
  }, []);

  return <DoctorAtHome />;
}

export default DoctorAtHomePage;

import styled from "styled-components";
import { Container as BaseContainer } from "../../commonComponents";
import { IoIosArrowForward } from "react-icons/io";

export const Container = styled(BaseContainer)`
  height: max-content;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 2.5rem 1rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    max-width: 100%;
  }
`;

export const CustomContainer = styled(Container)`
  text-align: center;
  padding-bottom: 5rem;
  width: 100%;
  margin: 0 auto;
  transition: 0.2s;
  cursor: pointer;

  
  @media screen and (max-width: 768px) {
    padding-bottom: 3rem;
    padding-left: 40px;
    padding-right:50px;
  }
  @media screen and (max-width: 508px) {
    padding-bottom: 3rem;
    padding-left: 60px;
    padding-right:60px;
  }
`;

export const SliderSection = styled.div`
  display: block !important;
`;

export const CardContainer = styled.div`
  width: 202px;
  height: 306px;
  padding-top: 10px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(74, 77, 77, 0.1);
  background-color:rgba(255, 255, 255, 1)

;

   /* &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246);
  }  */
  @media screen and (max-width: 788px) {
    width: 260px;
  height: 306px;
  padding-top: 10px;
  text-align: center;
  border-radius: 12px;
  }
  @media screen and (max-width: 508px) {
    width: 269px;
  height: 306px;
  padding-top: 10px;
  text-align: center;
  border-radius: 12px;
  }
`;



export const ProfileImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to bottom, ${props => props.color1}, ${props => props.color2}, ${props => props.color3});  */
  background: linear-gradient(to bottom, ${props => props.color1}, ${props => props.color2});
  img {
    width: 90px;
    height: 90px;

  }
`;

export const Name = styled.div`
  font-size: 20px;
  color: #1B263B;
  font-weight: bold;
  font-weight: 600;
  margin-top: 20px;
  line-height: 23.44px;
  margin-bottom: 10px;
`;

export const Money = styled.div`
  font-size: 20px;
  line-height: 23.44px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const ConsultButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: #CAF0F8;
  color: #1B263B;
  font-size: 17px;
  font-weight: 700;
  font-style: Roboto;
  line-height: 21.09px;
  align-items: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;


  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }
`;
export const StyledArrowIcon = styled(IoIosArrowForward)`

  color: #1B263B; /* Example color */

margin-top: 4px;
width: 15px;
height: 12px;

`;

import styled from "styled-components";
import { Container, ImageContainer, PrimaryBtn } from "../commonComponents";
import { HeroHeading } from "../HeroSection/HeroSectionElements";

export const SectionSubHeading = styled(HeroHeading)`
  color: ${(props) => props.theme.colors.heading};
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  margin-top: 2rem;
`;
export const SlideBody = styled.div`
  max-width: 80% !important;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 99% !important; /* Adjust the max-width for tablets and smaller screens */
    text-align: center;
    font-size: 40px;
    font-weight: 400;
  }

  @media (max-width: 480px) {
    max-width: 100% !important; /* Adjust the max-width for mobile screens */
    text-align: center;
    font-size: 10px;
    line-height: 10.09px;
    font-weight: 300;
  }
`;

export const SlideContainer = styled.div`
  justify-content: center;
  padding-bottom: 2rem;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-bottom: 1rem;
  }
`;

export const CustomPrimaryBtn = styled(PrimaryBtn)`
  margin-top: 2rem;
`;

export const SliderSection = styled.div`
  display: block !important;
`;
export const CustomContainer = styled(Container)`
  text-align: center;
  padding-bottom: 5rem;
  width: 95%;
  margin: 0 auto;
  transition: 0.2s;
  cursor: pointer;
  // &:hover {
  //   box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  // }
  @media screen and (max-width: 768px) {
    padding-bottom: 3rem;
  }
`;
export const CustomImage = styled.img`
  border-radius: 12px;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
`;
import React from "react";
import styled from "styled-components";

import defaultImage from "../../images/v1/defaultReview.png";

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  padding-top: 10px;
`;

const Name = styled.h3`
  margin: 0;
`;

const Age = styled.p`
  margin: 5px 0;
`;

const Place = styled.p`
  margin: 5px 0;
  font-style: italic;
`;

const StarRating = styled.div`
  margin-bottom: 10px;
  span {
    color: #FFF000;
    font-size: 26px; /* Adjust font size for larger stars */
  }
`;

const ReviewText = styled.p`
  font-size: 12px;
  @media screen and (max-width: 592px) {
    font-size: 14px;
 
  }
`;

const CustomerReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Add padding to prevent overlap */
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0px 0px 19px 0px rgba(126, 126, 126, 0.13);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246);
  }

  width: 250px;
  height: 400px;
  flex-shrink: 0;
  margin-top: 25px;

  
  @media screen and (max-width: 1865px) {
    width: 250px;
  height: 390px;
  flex-shrink: 0;
  margin-top: 25px; /* Adjust width for smaller screens */
  }
  @media screen and (max-width: 1500px) {
    width: 250px;
  height: 390px;
  flex-shrink: 0;
  margin-top: 25px; 
  } 
  @media screen and (max-width: 1100px) {
    width: 250px;
  height: 390px;
  flex-shrink: 0;
  margin-top: 25px; 
  }
  @media screen and (max-width: 770px) {
    width: 250px;
  height: 390px;
  flex-shrink: 0;
  margin-top: 25px; /* Adjust margin */
  }

  @media screen and (max-width: 592px) {
    justify-items: center;
    width: 270px;
  height: 400px;
  flex-shrink: 0;
  margin-top: 25px;
  }
  @media screen and (max-width: 592px) {
    width: 100%;
  flex-shrink: 0;
  margin-top: 25px; 
 
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomerReviewBox = ({ review }) => {
  const { name, age, place, rating, review: reviewText, image } = review;

  return (
    <CustomerReviewCard>
      <ContentWrapper>
        <Image src={defaultImage} alt="" />
        <Name>{name || "Anonymous"}</Name>
        <Age>{age || "Age not provided"} years old</Age>
        {place && <Place>{place}</Place>}
        <StarRating>
          {[...Array(rating)].map((_, index) => (
            <span key={index}>&#9733;</span>
          ))}
        </StarRating>
        <ReviewText>{reviewText}</ReviewText>
      </ContentWrapper>
    </CustomerReviewCard>
  );
};

export default CustomerReviewBox;

import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs2 from "../../images/blogsimage/BlogsPost2.png";

const PostPage2 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    document.title = 'Healthy Lifestyle: 10 Essential Habits for Healthy Life';

    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };

    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };

    // Set meta tags for SEO
    setMetaTag('description', 'Discover the top 10 essential habits for maintaining a healthy lifestyle. From exercise to nutrition, learn how to master wellness for a happier, healthier you.');
    setMetaTag('keywords', 'healthy lifestyle, wellness, nutrition, exercise, self-care, mental health, fitness, healthy habits');
    setMetaTag('author', 'HealthKo');

    // Set Open Graph tags for better social media sharing
    setMetaTag('og:title', 'Healthy Lifestyle: 10 Essential Habits for Healthy Life');
    setMetaTag('og:description', 'Discover the top 10 essential habits for maintaining a healthy lifestyle. From exercise to nutrition, learn how to master wellness for a happier, healthier you.');
    setMetaTag('og:image', HealthKoBlogs2);
    setMetaTag('og:url', window.location.href);

    // Set canonical link
    setLinkTag('canonical', 'https://www.healthko.in/Healthy-Lifestyle-10-Essential-Habits-for-Healthy-Life');
  }, []);

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>10 Essential Habits for Maintaining a Healthy Lifestyle</h1>
        <img src={HealthKoBlogs2} alt="Healthy Lifestyle " />
        <p>In today's fast-paced world, pursuit of a healthy lifestyle can sometimes feel like navigating through a maze without a map. This blog serves as a guiding light, illuminating the path towards a healthier, happier lifestyle. We'll delve into 10 essential habits that form a healthy lifestyle, each contributing its unique essence to well-being. From the exercises to the nurturing embrace of self-care, let's embark on a journey to unlock the secrets of a healthy lifestyle together.</p>

        <h2>Healthy lifestyle habits for optimal well-being.</h2>
        <p>Gone are the days when visiting a doctor’s office was the only option for seeking medical advice. With the rise of the internet and smartphones, people now have the convenience of consulting a doctor from the comfort of their own homes. Online doctor consultations have become increasingly popular, offering patients immediate access to medical professionals without the need for physical appointments.</p>

        <h2>1. Regular Exercise:</h2>
        <p>Exercise is the key to a healthy lifestyle. From reducing the risk of chronic diseases to improving mood and boosting energy levels, regular physical activity is non-negotiable. Incorporate a mix of <a href="https://www.healthline.com/health/cardio-exercises-at-home#beginner" target="_blank" rel="noopener noreferrer">cardiovascular exercises</a>, strength training, and flexibility exercises into your routine is so beneficial.</p>
        <h2>2. Balanced Nutrition:</h2>
        <p>A balanced diet is key to supporting overall health. Focus on consuming a variety of nutrient-dense foods, including fruits, vegetables, whole grains, lean proteins, and healthy fats. Limit your intake of <a href="https://www.nhs.uk/live-well/eat-well/how-to-eat-a-balanced-diet/what-are-processed-foods/#:~:text=A%20processed%20food%20is%20any,canning" target="_blank" rel="noopener noreferrer">processed foods</a>, sugary snacks, and excess sodium. Remember, food is the Key to a healthy lifestyle so nourish your body with the good nutrients.</p>
        <h2>3. Adequate Sleep:</h2>
        <p>Quality sleep is essential for overall health. Aim for 7-9 hours of uninterrupted sleep each night to allow your body and mind to recharge. Create a relaxing bedtime routine, optimise your sleep environment, and prioritise consistency to improve the quality and duration of your sleep.</p>
        <h2>4. Stress Management:</h2>
        <p>Chronic stress can take a toll on both your physical and mental health. Incorporate stress-reducing activities such as <a href="https://www.apa.org/topics/mindfulness/meditation" target="_blank" rel="noopener noreferrer">mindfulness meditation</a>, deep breathing exercises, or <a href="https://www.mea.gov.in/search-result.htm?25096/Yoga:_su_origen,_historia_y_desarrollo" target="_blank" rel="noopener noreferrer">yoga </a>.yoga into your daily routine. Prioritise self-care and make time for activities that bring you joy and relaxation to keep stress levels in check.</p>
        <h2>5. Hydration:</h2>
        <p>Staying hydrated is essential for supporting bodily functions, regulating temperature, and flushing out toxins. Aim to drink at least 8 glasses of water per day, and adjust your intake. Having hydrating foods such as fruits and vegetables into your diet to maintain optimal hydration levels.</p>
        <h2>6. Regular Health Screenings:</h2>
        <p>Regular health screenings are vital for detecting potential health issues early on when they are most treatable. Schedule routine check-ups with your healthcare provider to recommend screening guidelines based on your age, gender, and family history. Prioritise preventive care to stay ahead of any potential health concerns.</p>
        <h2>7. Mindful Eating:</h2>
        <p>Mindful eating involves paying attention to your body's hunger and fullness cues, as well as the taste, texture, and aroma of your food. Avoid distractions such as screens or multitasking while eating, and savour each bite. By fostering a healthy relationship with food, you can promote better digestion, prevent overeating, and enhance overall well-being.</p>
        <h2>8. Social Connections:</h2>
        <p>Strong social connections are essential for emotional support, reducing feelings of loneliness, and fostering a sense of belonging. Make time for regular social activities with friends, family, and community members. Prioritise meaningful connections and invest in nurturing your relationships to support your overall health and happiness.</p>
        <h2>9. Sun Protection: </h2>
        <p>Protecting your skin from the sun's harmful UV rays is crucial for preventing skin damage and reducing the risk of <a href="https://www.cdc.gov/cancer/skin/basic_info/index.htm" target="_blank" rel="noopener noreferrer">skin cancer</a>. Wear sunscreen with SPF 30 or higher, protective clothing, and sunglasses when outdoors, even on cloudy days. Practice sun safety habits year-round to protect your skin and maintain its health and vitality.</p>
        <h2>10. Self-Care:</h2>
        <p>Self-care is essential for maintaining balance and preventing burnout in our fast-paced world. Make time for activities that nourish your body, mind, and soul, whether it's, practising gratitude journaling, or simply taking a walk in nature. Prioritise self-care as a non-negotiable aspect of your daily routine to support your overall health and well-being.</p>
        <h1>Conclusion,</h1>
        <p>Mastering the art of healthy lifestyle requires dedication, commitment, and consistency. By incorporating these 10 essential habits into your daily routine, you can cultivate a vibrant lifestyle that promotes optimal health, happiness, and longevity. Remember, small changes can lead to significant results. Embrace these habits with intention and mindfulness, and watch as they transform your life for the better. If you need assistance you can go for online doctor consultation with <a href="https://www.healthko.in/" target="_blank" rel="noopener noreferrer">HealthKo</a>.</p>
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage2;

import styled from "styled-components";
import { Container, ImageContainer } from "../commonComponents";
import { Link } from "react-router-dom";

export const CustomContainer = styled(Container)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3rem;
  padding-top: 2rem;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
export const SectionContainer = styled.div`
  width: 45%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const CustomImageContainer = styled(ImageContainer)`
  width: 55%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
export const MainHeading = styled.h2`
  color: ${(props) => props.theme.colors.heading};
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 127.419% */
`;
export const SectionHeading = styled.h2`
  width: 70%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
export const BtnContainer = styled.div`
  width: 70%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
export const ContactusLink = styled(Link)`
  background-color: rgb(70, 156, 246); /* Button color */
  min-width: 80px;
  height: 44px;
  color: #fff;
  flex-shrink: 0;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 14px 42px;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }
`;

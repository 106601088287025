import React, { useRef } from 'react';
import Slider from 'react-slick';
import {
  ReactSliderContainer,
  SliderBox,
  SliderBtn,
  SliderBtnNext,
} from './ReactsliderElements';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

function ReactSlider({ settings, children, onPrev, onNext }) {
  const slider = useRef(null);
  const sliderSettings = { ...settings, arrows: false };

  const handlePrevSlide = () => {
    if (onPrev) onPrev();
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    if (onNext) onNext();
    slider.current.slickNext();
  };

  return (
    <ReactSliderContainer>
      <SliderBtn onClick={handlePrevSlide} />
      <SliderBox>
        <Slider ref={slider} {...sliderSettings}>
          {children}
        </Slider>
      </SliderBox>
      <SliderBtnNext onClick={handleNextSlide} />
    </ReactSliderContainer>
  );
}

export default ReactSlider;

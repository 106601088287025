import { useState } from "react";
import { Axios } from "../util/ApiEndpoint";
import { useAuthContext } from "./AuthContextHook";

function useAthHooks() {
  const { auth, updateAuth } = useAuthContext();
  const [formSignup, setFormSignup] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    address: "",
  });
  const [formLogin, setFormLogin] = useState({
    username: "",
    password: "",
  });
  const [signupDataErr, setformSignupErr] = useState({});
  const [formLoginErr, setFormLoginErr] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const emailNumberValidation = (username) => {
    return true;
  };

  const handleSignup = async () => {
    setformSignupErr({});
    let valid = true;
    if (!formSignup.name) {
      setformSignupErr({ ...signupDataErr, name: "Name Required" });
      valid = false;
    }
    if (!formSignup.phone) {
      setformSignupErr({ ...signupDataErr, phone: "Phone Required" });
      valid = false;
    }
    if (!formSignup.email) {
      valid = false;
      setformSignupErr({ ...signupDataErr, username: "Email Required" });
    }
    if (!formSignup.password) {
      valid = false;
      setformSignupErr({ ...signupDataErr, password: "Password Required" });
    }
    if (!formSignup.address) {
      valid = false;
      setformSignupErr({ ...signupDataErr, password: "Address Required" });
    }
    if (!valid) return;

    const res = await Axios.post("/userauth/registration", formSignup);

    if (res.data.status === true) {
      setIsRegistered(true);
      const loginRes = await Axios.post("/userauth/login", {
        email: formSignup.email,
        password: formSignup.password,
      });

      if (loginRes.data.status === true) {
        setIsLogged(true);
        localStorage.setItem("token", loginRes.data.token);
        localStorage.setItem("userId", loginRes.data.success._id);
        updateAuth({ isLogged: true, isAuthModalOpen: false });
      } else if (loginRes.data.message === "User does not exist") {
        setFormLoginErr({ login: loginRes.data.message });
      }
    } else if (res.data.message) {
      setformSignupErr({ signup: res.data.message });
    }
    console.log(res.data);
  };

  const handleLogin = async () => {
    setFormLoginErr({});
    let valid = true;
    if (!formLogin.email) {
      valid = false;
      setFormLoginErr({
        ...formLoginErr,
        email: "Email or Phone Required",
      });
    }
    if (formLogin.email.length && !emailNumberValidation(formLoginErr.email)) {
      valid = false;
      setFormLoginErr({
        ...formLoginErr,
        email: "Enter a valid Email or Phone",
      });
    }
    if (!formLogin.password) {
      valid = false;
      setFormLoginErr({ ...formLoginErr, password: "Password Required" });
    }
    if (!valid) return;

    const res = await Axios.post("/userauth/login", formLogin);

    if (res.data.status === true) {
      setIsLogged(true);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("userId", res.data.success._id);
      updateAuth({ isLogged: true, isAuthModalOpen: false });
    } else if ((res.data.message = "User does not exist")) {
      setFormLoginErr({ login: res.data.message });
    }
  };

  return {
    isLogged,
    signupDataErr,
    formSignup,
    formLogin,
    formLoginErr,
    isRegistered,
    setFormLogin,
    setFormSignup,
    handleSignup,
    handleLogin,
  };
}

export default useAthHooks;

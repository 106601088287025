import { GrNext, GrPrevious } from "react-icons/gr";
import menu1 from "../images/v1/menu1.png";
import menu2 from "../images/v1/menu2.png";
import menu3 from "../images/v1/menu3.png";
import menu4 from "../images/v1/menu4.png";
import menu5 from "../images/v1/menu5.png";
import menu6 from "../images/v1/menu6.png";
import menu7 from "../images/v1/menu7.png";
import menu8 from "../images/v1/menu8.png";
import menu9 from "../images/v1/menu9.png";
import menu10 from "../images/v1/menu10.png";
import menu11 from "../images/v1/menu11.png";
import menu12 from "../images/v1/menu12.png";
import menu13 from "../images/v1/menu13.png";
import menu14 from "../images/v1/menu14.png";
import gynacology from "../images/v1/gynacology.jpg";
import sexology from "../images/v1/sexology.jpg";
import dermatology from "../images/v1/dermatology.jpg";
import pediatrics from "../images/v1/pediatrics.jpg";
import psychiatry from "../images/v1/psychiatry.jpg";
import doctor1 from "../images/v1/doctor1.jpg";
import doctor2 from "../images/v1/doctor2.jpg";
import doctor3 from "../images/v1/doctor3.jpg";
import doctor4 from "../images/v1/doctor4.jpg";
import image1 from "../images/HomepageBanner/Homepage-online-consultant.jpg";
import image2 from "../images/HomepageBanner/Homepage-home-visit.jpg";
import image3 from "../images/HomepageBanner/Homepage-clinic-booking.jpg";
export const slides = [
  {
    title: "Video Consultation",
    body: 'HealthKo assists in offering the access to the top qualified doctors through online doctor consultations, with the help of AI technology, in the easiest and simplest way of healthcare. You can easily connect with a qualified medical professional through a simple click of a button from your own home. The AI technology we have developed makes our platform more stable and easy to use thus providing a one-to-one healthcare experience.',
    link: "/video-consultation-services",
    image: image1,
  },
  {
    title: "Doctor at Home",
    body: 'HealthKo is a company where convenience and well-being are the core. Elimination of stress for scheduling of the appointments and rushing to the doctor\'s office is a goodbye. Thus, it is better to go to health care that is according to your schedule, your comfort and your peace of mind. The objective is to smoothen every stage of your recuperation and make it as stress-free as possible, so that you can devote your time and energy to what is important to you for getting better.',
    link: "/Doctor-At-Home-Page",
    image: image2,
  },
  {
    title: "Clinic Appointment",
    body: 'The old times of countless phoneless and long waiting are over. Through HealthKo, you can finally eliminate the administrative hassle that usually accompanies the booking of appointments. The issue of the logistics is taken care of, so you can devote yourself to what really matters to you, which is your health.',
    link: "/Clinic-Appointment-page",
    image: image3,
  },
 
];

export const menuItems = [
  {
    name: "My appointments",
    Icon: menu1,
  },
  {
    name: "My profile",
    Icon: menu2,
  },
  // {
  //   name: 'Orders',
  //   Icon: menu3,
  // },
  // {
  //   name: 'My bookings',
  //   Icon: menu4,
  // },
  // {
  //   name: 'Consultations',
  //   Icon: menu5,
  // },
  // {
  //   name: 'Test bookings',
  //   Icon: menu6,
  // },
  // {
  //   name: 'My doctors',
  //   Icon: menu7,
  // },
  {
    name: "Medical records",
    Icon: menu8,
  },
  // {
  //   name: 'Insurance policy',
  //   Icon: menu9,
  // },
  // {
  //   name: 'Reminders',
  //   Icon: menu10,
  // },
  // {
  //   name: 'Payments & health cash',
  //   Icon: menu11,
  // },
  {
    name: "Settings",
    Icon: menu12,
  },
  {
    name: "Help center",
    Icon: menu13,
  },
  // {
  //   name: 'Rate us',
  //   Icon: menu14,
  // },
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return <GrNext className={className} style={style} onClick={onClick} />;
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return <GrPrevious className={className} style={style} onClick={onClick} />;
}

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,

  autoplay: true,
  autoplaySpeed: 2500,
  cssEase: "linear",
  pauseOnHover: true,

  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export const multipleSliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  autoplay: false,
  cssEase: "linear",
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export const clinicalsliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: false,
  cssEase: "linear",
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
export const multipleSlides = [
  {
    department: "Gynacology",
    price: 499,
    image: gynacology,
    id: "1",
  },
  {
    department: "Dermatology",
    price: 499,
    image: dermatology,
    id: "2",
  },
  {
    department: "Sexology",
    price: 499,
    image: sexology,
    id: "3",
  },
  {
    department: "Psychiatry",
    price: 499,
    image: psychiatry,
    id: "4",
  },
  {
    department: "Pediatrics",
    price: 499,
    image: pediatrics,
    id: "5",
  },
  {
    department: "Pulmonology",
    price: 499,
    image: sexology,
    id: "7",
  },
  {
    department: "Hematology",
    price: 499,
    image: dermatology,
    id: "8",
  },
];

export const doctorDetails = [
  //call get doctor api to get doctor with specific specialization
  {
    image: doctor1,
    name: "Dr. Williams",
    department: "Dermatologist",
    experience: "3 year experience",
    id: "1",
  },
  {
    image: doctor2,
    name: "Dr. Smith",
    department: "Dermatologist",
    experience: "2 year experience",
    id: "2",
  },
  {
    image: doctor3,
    name: "Dr. Lee",
    department: "Dermatologist",
    experience: "5 year experience",
    id: "3",
  },
  {
    image: doctor4,
    name: "Dr. Taylor",
    department: "Dermatologist",
    experience: "4 year experience",
    id: "4",
  },
];

export const TimeSlots = [
  "9:30 AM",
  "11:30 AM",
  "01:30 PM",
  "04:30 PM",
  "06:30 PM",
];

export const clinicalSlide = [
  {
    name: "Sunrise health clinic",
    address: "123 main street sunnyville, CA 98765",
    rating: 3.5,
    fees: "549",
    id: 1,
  },
  {
    name: "Sunrise health clinic1",
    address: "123 main street sunnyville, CA 98765",
    rating: 3.5,
    fees: "549",
    id: 2,
  },
  {
    name: "Sunrise health clinic2",
    address: "123 main street sunnyville, CA 98765",
    rating: 3.5,
    fees: "549",
    id: 3,
  },
  {
    name: "Sunrise health clinic3",
    address: "123 main street sunnyville, CA 98765",
    rating: 3.5,
    fees: "549",
    id: 4,
  },
];

import React from 'react';
import styled from 'styled-components';
import checklogo from '../../images/v2/check.png';
import Consultimage from '../../images/v2/Consult.png'
import followimage from '../../images/v2/follow.png'
import Safeimage from '../../images/v2/safe.png'
import accebilityimage from '../../images/v2/accessibility.png'
import Convenientimage from '../../images/v2/Convenient.png'
import CostEffectiveimage from '../../images/v2/cost-effective.png'

const CardContainer = styled.div`
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(#CAF0F8, #D8F3DC);
  padding: 40px 20px; /* Adjust padding */
  margin-bottom: 40px;
  h1 {
    padding-bottom: 40px;
    font-size: 36px;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: -2%;
    line-height: 42.19px;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const IconBox = styled.div`
  border-radius: 12px;
  padding: 20px;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const IconImage = styled.div`
  /* position: relative;
  width: 100px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
    z-index: 1; */
  }

  img {
    width: 60px;
    height: 60px;
    z-index: 2;
  }
`;

const IconText = styled.div`
  h2 {
    color: #00AFFE;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 28.13px;
    font-family: Roboto;
  }
  p {
    color: #353535;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 21.09px;
    font-family: Roboto;
    letter-spacing: -2%;
  }
`;

const BenifitOnlineConsultation = () => {
  return (
    <CardContainer>
      <h1>Benefits of Online Doctor Consultation</h1>
      <IconContainer>
        <IconBox>
          <IconImage>
            <img src={Consultimage} alt="Icon" />
          </IconImage>
          <IconText>
            <h2>24X 7 Top Doctor Consultation</h2>
            <p>Consult a doctor via audio/video anytime with our cutting edge AI technology for better experience.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage>
            <img src={Convenientimage} alt="Icon" />
          </IconImage>
          <IconText>
            <h2>Convenient And Simple</h2>
            <p>Get medical advice whenever and wherever you choose, saving the time and effort of travelling to a physical clinic.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage>
            <img src={followimage} alt="Icon" />
          </IconImage>
          <IconText>
            <h2>Improved Follow-Up</h2>
            <p>Easily follow up with doctors for ongoing consultation, with the convenience of cutting edge AI technology for prescription and medical history.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage>
            <img src={CostEffectiveimage} alt="Icon" />
          </IconImage>
          <IconText>
            <h2>Cost Effective</h2>
            <p>Get online consultation from top doctors starting from just 99</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage>
            <img src={accebilityimage} alt="Icon" />
          </IconImage>
          <IconText>
            <h2>Accessibility</h2>
            <p>Connect with specialised doctors beyond your geographical limitations, ensuring access to top doctors care regardless of location.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage>
            <img src={Safeimage} alt="Icon" />
          </IconImage>
          <IconText>
            <h2>Privacy And Security</h2>
            <p>Discuss sensitive health issues in the comfort of your own space. And be assured your consultation with doctors will be fully private and secured.</p>
          </IconText>
        </IconBox>
      </IconContainer>
    </CardContainer>
  );
};

export default BenifitOnlineConsultation;

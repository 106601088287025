import styled from 'styled-components';

export const SlideContainer = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.primary};
  padding: 1.5rem;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  height: 100px;
  width: 78px;
  border-radius: 12px;
  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

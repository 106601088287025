
import styled from "styled-components";
import { PrimaryBtn } from "../commonComponents";
import { Link } from "react-router-dom";
import { Paragraph } from "../commonComponents";




export const StyledBlogPage = styled.div`
  .blogs {
    padding-top: 10px;
    color: rgb(70, 156, 246);
    text-align: center; /* Center align all content */
  }
  h6 {
    font-size: 16px;
    color: #333;
    margin-top: 10px;
    padding-left: 25px;
  }
`;

export const BlogContent = styled.div`
 display: flex;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three items in one row */
  grid-gap: 20px;
  margin-top: 20px;
  padding-left: 60px; /* Add padding to the left side */
  padding-right: 110px;
 padding-bottom: 20px;
  @media screen and (max-width: 1408px) {
    display: flex;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Display three items in one row */
  grid-gap: 20px;
  margin-top: 20px;
  padding-left: 60px; /* Add padding to the left side */
  padding-right: 110px;
  
  }

  @media screen and (max-width: 868px) {
    grid-template-columns: 1fr;
    padding-left: 20px; /* Adjust padding for smaller screens */
    padding-right: 20px;
  
  }
`;

export const ReadMoreBtn = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
`;

export const BlogItem = styled.div`
  text-align: left; /* Align title to the left */
`;

export const BlogImage = styled.img`
  width: 100%;
  height: auto;
  
  
  max-height: 300px; /* Increase the maximum height of the image */
`;

export const BlogDetails = styled.div`
  padding: 10px;


`;

export const BlogTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 5px;
`;

export const BlogAuthor = styled.p`
  padding-top: 5px;
  font-size: 14px;
  color: #777;
  margin-bottom: 5px;

`;

export const BlogDateTime = styled.p`
  font-size: 14px;
  color: #777;
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  position: relative;
  padding-left: 30px;
  padding-right: 25px;
  margin-top: 1rem;
  
`;

export const SearchInput = styled.input`
  color: #606060;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 14px 48px 14px 28px; /* Adjust padding to accommodate the search icon and button */
  width: 100%;
  max-width: 500px; /* Limiting the input width */
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
  border: none;

  &:focus {
    outline: 1px solid;
    outline-color: ${(props) => props.theme.colors.primary};
  }
`;

export const SearchBtn = styled(PrimaryBtn)`
  flex-shrink: 0; /* Prevent button from shrinking */
`;

export const ReadMoreLink = styled(Link)`
  display: inline-block;
  color: #rgb(70, 156, 246); /* Text color */
  text-decoration: none;
  transition: background-color 0.3s ease;


`;



export const ServiceContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: fit-content;
    margin-top: -1rem;
  }
`;
export const ServiceWrapper = styled.div`
padding-top: 20px;
  width: 100%;
  display: flex;

  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
`;

// export const ServiceCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
//   padding: 30px;
//   border-radius: 16px;
//   cursor: pointer;
//   box-shadow: 0px 0px 19px 0px rgba(126, 126, 126, 0.13);
//   transition: all 0.2s ease-in-out;


//   &:hover {
//     box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
//   }

//   width: 340px;
//   height: 300px;
//   flex-shrink: 0;

//   @media screen and (max-width: 768px) {
//     width: 390px;
//   }

//   @media screen and (max-width: 500px) {
//     width: 100%;
//   }
// `;

// export const ServiceIcon = styled.img`
//   height: 240px;
//   width: 120px;
//   margin-bottom: 10px;
//   padding-top: 40px;
// `;

// export const ServiceH2 = styled.h2`
//   font-size: 1.2rem;
//   margin-bottom: -30px;
//   text-align: center;
//   margin-top: 1rem;
// `;

export const SectionParagraph = styled(Paragraph)`
  margin-bottom: -1rem;
`;


export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0px 0px 19px 0px rgba(126, 126, 126, 0.13);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246);
  }

  width: 470px;
  height: 400px;
  flex-shrink: 0;
 

  @media screen and (max-width: 768px) {
    width: 390px;
    height: 430px;
   
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: 470px;
  }
`;

export const ServiceIcon = styled.img`
   width: 100%;
  height: auto;
  
  max-height: 200px;
`;

export const ServiceH2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;



import React from "react";
import { SidebarWrapper, TabList, TabItem } from "./SidebarElement";
const Sidebar = ({ setActiveTab }) => {
  return (
    <SidebarWrapper>
      <TabList>
        <TabItem onClick={() => setActiveTab("doctors")}>
          Manage Doctors
        </TabItem>
        <TabItem onClick={() => setActiveTab("users")}>Manage Users</TabItem>
      </TabList>
    </SidebarWrapper>
  );
};

export default Sidebar;

import React from "react";
import {
  Container,
  Image,
  Paragraph,
  PrimaryBtn,
  SectionHeading,
} from "../commonComponents";
import {
  BtnContainer,
  CustomContainer,
  MainHeading,
  MainContainer,
  SectionContainer,
  CustomImageContainer,
  ContactusLink
} from "./AboutUsElements";

import MainImg from "../../images/v1/contactus.png";

function AboutUs() {
  return (
    <Container id="contact">
      <CustomContainer>
        <SectionHeading>Contact Us</SectionHeading>
        <MainContainer>
          <SectionContainer>
            {/* <MainHeading>All health services at your home</MainHeading> */}
            <Paragraph>
            Need assistance or have questions ? 
            Reach out to our dedicated support team at Healthko.
             We're committed to providing you with the best healthcare experience. 
             Whether it's about appointments, consultations, or any other inquiries, 
             we're here to help. Connect with us via phone or email. 
             Your well-being is our priority.
            </Paragraph>
            <BtnContainer>
              <ContactusLink to="/Contact-Us">Contact Us</ContactusLink>
            </BtnContainer>
          </SectionContainer>
          <CustomImageContainer>
            <Image src={MainImg} alt="Contact US" />
          </CustomImageContainer>
        </MainContainer>
      </CustomContainer>
    </Container>
  );
}

export default AboutUs;

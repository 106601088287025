import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs from "../../images/blogsimage/HealthKoBlogs.jpg";

const PostPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    document.title = 'How to Protect Your Skin from the Sun: Essential Tips';

    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };

    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };

    // Set meta tags for SEO
    setMetaTag('description', 'Learn effective ways to protect your skin from the sun with sun protection tips, sunscreen advice, and protective clothing recommendations.');
    setMetaTag('keywords', 'sun protection, sunscreen, UV rays, skin care, sunburn, protective clothing, SPF, sun exposure');
    setMetaTag('author', 'HealthKo');
    
    // Set Open Graph tags for better social media sharing
    setMetaTag('og:title', 'How to Protect Your Skin from the Sun: Essential Tips');
    setMetaTag('og:description', 'Learn effective ways to protect your skin from the sun with sun protection tips, sunscreen advice, and protective clothing recommendations.');
    setMetaTag('og:image', HealthKoBlogs);
    setMetaTag('og:url', window.location.href);

    // Set canonical link
    setLinkTag('canonical', 'https://www.healthko.in/How-to-Protect-Your-Skin-from-the-Sun-Essential-Tips');

  }, []);
  

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
        <BlogPostStyles>
        <h1>How to Protect Your Skin from the Sun: Essential Tips</h1>
        <p>To help you understand why skin protection from the sun is so important, it is useful to consider the long-term effects of sunburn.</p>
        <img src={HealthKoBlogs} alt="Tips To Protect Your Skin from Sun " />
        <p>As we know skin cancer and other skin-related diseases generated by exposure to sunlight, it is highly advisable to take the necessary actions and protect your skin from harmful UV light. It contains various safety methods that will help one to safeguard your skin against the effects of the sun.</p>

        <h1>Understanding the Effects of Sun Exposure</h1>
        <h2>Harmful Effects of UV Rays</h2>
        <p>UV rays are harmful emanating from the sun and can damage skin cells. There are two kinds of UV rays; that is  <a href="https://www.skincancer.org/risk-factors/uv-radiation/#:~:text=Ultraviolet%20A%20(UVA)%20has%20a,is%20associated%20with%20skin%20burning." target="_blank" rel="noopener noreferrer">UVA and UVB rays </a>. UV-A rays get deeper into the skin and cause premature ageing and wrinkles, while UV-B rays lead to sunburn and cause skin cancer mostly. The effects of each type are undesirable and can lead to skin cancer if continued for a long period.</p>

        <h2>Long-Term Skin Damage</h2>
        <p>Skin can get affected if you are in sun exposure without proper sun protection and harmful sun rays. It should also be noted that the effect of the sun’s rays in small durations of time can accumulate thus causing a lot of damage to the skin.</p>
        
        <h1>Daily Sun Protection Tips</h1>
        <h2>Using Sunscreen</h2>
        <p>If you go out without proper sunscreen it leads to a proper skin disease such as sunburn, <a href="https://my.clevelandclinic.org/health/diseases/21885-hyperpigmentation" target="_blank" rel="noopener noreferrer"> hyperpigmentation </a>, and skin cancer. An outstanding method for avoiding sunburn is the use of sunscreen. Look for a sunscreen with at least 30 SPF and that’s broad-spectrum which blocks out both UVA and UVB rays. Put a sufficient amount of sunscreen on all the exposed parts of the body to protect the skin from sun and regularly after every two hours of sun exposure or after swimming or sweating.</p>
        <h2>Wearing Protective Clothing</h2>
        <p>Clothing can be used to prevent exposure to the sun’s rays in its physical form. Such shirts, pants, and skirts should be made out of closely woven fabric. For maximum protection it is advisable to choose garments with an <a href="https://www.skincancer.org/skin-cancer-prevention/sun-protection/sun-protective-clothing/#:~:text=Ultraviolet%20Protection%20Factor%20(UPF)%20indicates,reducing%20your%20exposure%20risk%20significantly." target="_blank" rel="noopener noreferrer">  ultraviolet protection factor (UPF) label </a>.</p>
        <h2>Seeking Shade</h2>
        <p>ou should try to avoid the sun especially at the hours of 10am – 4pm because this exposes one to direct beams of sunlight that contain UV rays. If you’re planning to be outdoors make sure to utilise umbrellas, trees, or other places seeking shade.</p>
        <h2>Wearing Sunglasses and Hats</h2>
        <p>While exposing skin to sunlight, choose sunglasses that can block 100% of UV radiation. Hats with large enough brims ensure that you have further coverage for your face, neck and ears which are usually prone to excessive sun exposure.</p>
        <h1>Choosing the Right Sunscreen</h1>
        <h2>SPF Ratings:</h2><p>SPF stands for Sun Protection Factor – the level of the sun protection a sunscreen gives from the UVB. If the SPF is high this means that it provides a greater level of protection. SPF 30 suits people who are fond of some outdoor activities, but if you plan to spend long periods outside, then higher SPFs are useful.</p>
        <h2>Broad-Spectrum Protection:</h2>
        <p>One should introduce sunscreen products that are labelled as broad-spectrum in order for them to be safe from UVA and UVB rays. It is therefore the comprehensive sun protection required in preventing all types of sun damage.</p>
        <h2>Water-Resistant Formulas:</h2>
        <p> If you are going to wash your face or swim or will work out choose a water resistant sunscreen. These are long lasting formulas when in contact with water or the body is sweating profusely.</p>

        <h1>Protective Clothing and Accessories</h1>
        <h2>Types of Protective Clothing:</h2>
        <p>Consider buying clothes such as beach shirts that provide sun protection. There are a variety of brands that produce suit suits that are fashionable and have strong UPF level products that can protect against over 95% of the UV range. At the end, rash guards, swim shirts, and sun sleeves are great options to consider too.</p>
        <h2>Benefits of Hats and Sunglasses:</h2>
        <p>Clothings such as hats with wide brims cover the face, neck, and ears perfectly, reducing a lot of exposure. Sunglasses defend your eye and some area of ​​the skin around it from the sun, and protect from the appearance of cataracts and <a href="https://www.mayoclinic.org/diseases-conditions/skin-cancer/symptoms-causes/syc-20377605" target="_blank" rel="noopener noreferrer"> skin cancers. </a> </p>

        <h1>Sun Protection for Different Skin Types</h1>
        <h2>Fair Skin:</h2>
        <p>Individuals with fair skin should go for higher SPF as they are prone to sunburn and wear protective clothing and avoid direct exposure to the sun in the afternoon.</p>
        <h2>Medium Skin:</h2>
        <p>Individuals having medium skin do not escape danger from sunburns and long-term damages to the skin. A high SPF is advised together with other skin protective practices.</p>
        <h2>Dark Skin:</h2>
        <p>The type of skin like the dark type has also higher quantities of melanin which can act as a kind of shield yet it is also sensitive to direct sun exposure. Sun protection using sunscreen, hats and long sleeved clothing protects against hyperpigmentation and skin cancers associated with sun exposure.</p>

        <h1>Additional Tips for Skin Care</h1>
        <h2>Hydration:</h2>
        <p>It is important that your skin remains hydrated to retain and protect the skin’s barrier. It is also important to drink plenty of water and moisturise the body regularly with lotions.</p>
        <h2>After-Sun Care:</h2>
        <p>In case you get any sunburns, apply aloe vera or after sun cream or spray on the sunburned areas to ease and recover sunburns. Do not go out into the sunshine until you have healed your skin.</p>
        <h2>Regular Skin Checks:</h2>
        <p> One should also evaluate his or her skin condition for any noticeable changes. This problem could be solved if skin problems are detected earlier so that effective treatment is realised. visit a dermatologist at least once a year for skin checks.</p>

        <h1>Conclusion</h1>
        <p>Sun exposure is a vital factor in proper care and protection of your skin. Some smart behaviours can mitigate the negative effects of sun exposure such as skin burns, ageing, and skin cancer. 
Make sure you apply a wide spectrum sunscreen of at least 30 SPF, reapply frequently and choose a water resistant one if your moments are long. Sunscreen should be accompanied by other means of covering up like long sleeves, long pants, long dresses, wide-brimmed hats, and UV-blocking sunglasses to ensure complete coverage. These things will help you to pursue the activities of the outdoors while protecting your skin.
</p>
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs ?</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage;

import { useState } from 'react';
import { Axios } from '../util/ApiEndpoint';

function useConsultationHook() {
  const [slot, setSlot] = useState({});
  const [doctorArray, setDoctorArray] = useState([]);

  const [doctor, setDoctor] = useState({});
  const authToken = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };

  function getRandomNumber(n) {
    const randomDecimal = Math.random();
    const randomNumber = Math.floor(randomDecimal * (n + 1));
    return randomNumber;
  }

  const createSlots = async (data, setStep) => {
    await getDoctors('homeVisit');
    let doc = '';
    if (doctorArray.length) {
      doc = doctorArray[getRandomNumber(doctorArray.length - 1)];
      setDoctor(doc);
    }
    const body = {
      ...data,
      user: userId,
      doctor: doc?._id,
    };
    const res = await Axios.post('slots/create', body, { headers });
    if (res.success === 200 && res.data._id) {
      setStep(4);
    }
  };

  const getDoctors = async (value) => {
    const body = {
      type: {
        homeVistBySlot: value === 'homeVisit',
        clinicAppointment: value === 'clinic',
        videoConsultancy: value === 'video',
      },
    };
    const res = await Axios.post('doctor/get', body, { headers });
    if (res.data?.data?.length > 0) {
      setDoctorArray(res.data.data);
    }
  };

  return {
    slot,
    getDoctors,
    setSlot,
    createSlots,
    doctorArray,
    doctor,
  };
}

export default useConsultationHook;

import React, { useState, useEffect } from 'react';
import { FaAngleDown, FaSearch } from 'react-icons/fa';
import step1Image from '../../images/v2/steps-1.png';
import step2Image from '../../images/v2/steps-2.png';
import step3Image from '../../images/v2/steps-3.png';
import videoConsultationImage from '../../images/v2/banner-online-consult.png';
import {
  imageStyles,
  ImageContainer,
  StyledButton,
  StyledHeading,
  StyledParagraph,
  StyledParagraphs,
  StepContainer,
  StepImage,
  StepItem,
  StepText,
  Circle,
  SearchBarContainer,
  SearchInput,
  SearchIcon,
  StyledButtons,
  StyledHeadings
} from './NewServicesElements.js';
import {
  Question,
  QuestionContent,
  DropdownIcon,
  Answer,
  FAQContainer
} from "../OurServices/AboususpagesElements.js";
import SpecialistService from './Secialistcard/SpecialistService.jsx.jsx';
import BenifitOnlineConsultation from './BenifitOnlineConsultation.jsx';
import Footer from '../Footer/Footer.jsx';

const SearchBar = () => {
  return (
    <SearchBarContainer>
      <SearchInput type="text" placeholder="Search for specialists..." />
      <SearchIcon />
      <StyledButtons>See All Specialities</StyledButtons>
    </SearchBarContainer>
  );
};

const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  

  return (
    <div>
      <Question onClick={toggleAccordion} isOpen={isOpen}>
        <QuestionContent>{question}</QuestionContent>
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </Question>
      {isOpen && <Answer>{answer}</Answer>}
    </div>
  );
};

const NewVideoConsultation = () => {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleAppointmentClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ahsclient.app', '_blank'); // Opens appointment page
  };

  return (
    <div>
      <ImageContainer>
        <img src={videoConsultationImage} alt="Online Doctor Consultation" />
        <div className="text-overlay">
          <p className="subtitle">Home is where health starts</p>
          <h1>Book Online Doctor Consultation</h1>
          <p>Personalized Care + Priority Access · Starts at just ₹99</p>
          <StyledButton onClick={handleAppointmentClick}>Consult Now</StyledButton>
        </div>
      </ImageContainer>
      <StyledHeading>How it works</StyledHeading>
      <StepContainer>
        {/* Step 1 */}
        <StepItem>
          <Circle>
            <StepImage src={step1Image} alt="Step 1" />
          </Circle>
          <StepText>Select a specialty or symptom</StepText>
        </StepItem>
        {/* Step 2 */}
        <StepItem>
          <Circle>
            <StepImage src={step2Image} alt="Step 2" />
          </Circle>
          <StepText>Audio/video call with a verified doctor</StepText>
        </StepItem>
        {/* Step 3 */}
        <StepItem>
          <Circle>
            <StepImage src={step3Image} alt="Step 3" />
          </Circle>
          <StepText>Get a digital prescription & a free follow-up</StepText>
        </StepItem>
      </StepContainer>
      <StyledHeadings>Select Specialities</StyledHeadings>
      <StyledParagraphs>Consult with top doctors across specialties</StyledParagraphs>
      <SearchBar />
      <SpecialistService />
      <BenifitOnlineConsultation />
      <StyledHeading>Frequently Asked Questions</StyledHeading>
      <FAQContainer>
        <DropdownItem
          question="What is online doctor consultation?"
          answer="Online doctor consultation is a process of obtaining medical advice and treatment via online platforms like phone calls or video calls."
        />
        <DropdownItem
          question="How do I begin an online consultation with doctors on Healthko?"
          answer={
            <>
              Booking an online doctor consultation on HealthKo is very easy. Just follow these four simple steps:

              <ol>
                <li>Contact us by visiting our website or downloading the HealthKo app for online consultation.</li>
                <li>Select your health concern.</li>
                <li>Connect with a doctor in under 2 minutes.</li>
                <li>Discuss your queries with the doctor via audio or video call.</li>
                <li>Receive a valid online doctor prescription.</li>
              </ol>
            </>
          }
        />
        <DropdownItem
          question="How quickly can I connect with a doctor for online Consultation?"
          answer="Healthko ensures easy and quick access for online doctor consultation. You can consult doctor online within 2 minutes of initiating the consultation process."
        />
        <DropdownItem
          question="Can I ask questions during the online consultation?"
          answer="Absolutely! Healthko encourages patients to ask questions and discuss their concerns with the doctor during the online consultation via audio or video call. This ensures a thorough understanding of your health condition and treatment plan."
        />
        <DropdownItem
          question="Will I receive a valid prescription after online medical consultation?"
          answer="Yes, upon completion of online medical consultation, you will receive a valid online doctor prescription from doctors. This prescription can be used to obtain necessary medications from pharmacies."
        />
        <DropdownItem
          question="Is my online medical consultation secure?"
          answer="Healthko prioritises the privacy and security of your information. All online medical consultations are conducted through encrypted channels and are strictly confidential to protect the data."
        />
      </FAQContainer>
      <Footer />
    </div>
  );
}

export default NewVideoConsultation;

import React from 'react';
import styled from 'styled-components';
import checklogo from '../../images/v2/check.png';
import Convenience from '../../images/v2/Convenience-and-Time-Management.png'
import Priority from '../../images/v2/Priority-Access-to-Healthcare.png'
import Personalized from '../../images/v2/Personalized-Care-and-Attention.png'
import Streamlined from '../../images/v2/Streamlined-Healthcare-Experience.png'
import Better from '../../images/v2/Better-Preparation-for-Consultations.png'
import Improved from '../../images/v2/Improved-Patient-Satisfaction.png'

const CardContainer = styled.div`
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient( #CAF0F8, #D8F3DC);
  padding: 40px 20px; /* Adjust padding */
  margin-bottom: 40px;
  h1 {
    padding-bottom: 40px;
    font-size: 36px;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: -2%;
    line-height: 42.19px;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const IconBox = styled.div`
  border-radius: 12px;
  padding: 20px;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const IconImage = styled.img`
  width: 60px;
  height: 60px;
  z-index: 2;
`;

const IconText = styled.div`
  h2 {
    color: #00AFFE;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 28.13px;
    font-family: Roboto;
  }
  p {
    color: #353535;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 21.09px;
    font-family: Roboto;
    letter-spacing: -2%;
  }
`;

const BenifitOnlineConsultation = () => {
  return (
    <CardContainer>
      <h1>Benefits of Clinic Appointment</h1>
      <IconContainer>
        <IconBox>
          <IconImage src={Convenience} alt="Icon" />
          <IconText>
            <h2>Convenience and Time Management</h2>
            <p>Booking a clinic appointment allows you to choose a convenient time slot, saving you time by avoiding long waiting periods.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Priority} alt="Icon" />
          <IconText>
            <h2>Priority Access to Healthcare:</h2>
            <p>By booking an appointment with HealthKo, you ensure priority access to healthcare services, reducing the risk of delays or emergencies.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Personalized} alt="Icon" />
          <IconText>
            <h2>Personalized Care and Attention</h2>
            <p>Scheduled appointments guarantee personalised care and attention from medical professionals, addressing your specific health concerns effectively.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Streamlined} alt="Icon" />
          <IconText>
            <h2>Streamlined Healthcare Experience:</h2>
            <p>Appointment booking streamlines the healthcare process, minimising hassles and ensuring a smooth experience for patients.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Better} alt="Icon" />
          <IconText>
            <h2>Better Preparation for Consultations:</h2>
            <p>With a booked appointment, patients have time to prepare questions and information for more productive consultations with healthcare providers.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Improved} alt="Icon" />
          <IconText>
            <h2>Improved Patient Satisfaction:</h2>
            <p>Booking clinic appointments enhances patient satisfaction by providing a safe and efficient healthcare experience.</p>
          </IconText>
        </IconBox>
      </IconContainer>
    </CardContainer>
  );
};

export default BenifitOnlineConsultation;

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

`;

const COLORS = {
  primary: '#009FFD',
  text: '#676767',
  heading: '#2E2E2E',
};

export const theme = {
  colors: COLORS,
};

export const doctors = [
  {
    id: 1,
    name: "Dr. John Doe",
    specialty: "Cardiologist",
    approved: true,
    idCardUrl: "https://example.com/idCard1.jpg",
  },
  {
    id: 2,
    name: "Dr. Jane Smith",
    specialty: "Dermatologist",
    approved: false,
    idCardUrl: "https://example.com/idCard2.jpg",
  },
  // Add more doctor data as needed
];

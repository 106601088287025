import { styled } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transform: 0.3s ease-in-out;
  opacity: ${({ issidebaropen }) => (issidebaropen === 'true' ? '100%' : '0')};
  top: ${({ issidebaropen }) => (issidebaropen === 'true' ? '0' : '-100%')};

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: #000;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    transition: 0.2s ease-in-out;
  }
`;
export const SidebarsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);

  @media screen and (max-width: 400px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const BtnContainer = styled.div`
padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 2rem 1rem;
`;
export const DropdownItem = styled.div`
  align-items: center;
  padding: 1px;
  h3 {
    padding-left: 20px;
    font-size: 12px; 
   
  }
  img{
    width: 250px;
  }
`;
export const GooglePlayIconContainer = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center; 
  cursor: pointer;
  width: 185px;
`;

export const GooglePlayIconImage = styled.img`
  width: 15%; 
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 40px;
`;

export const IosStoreIconContainer = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center; 
  cursor: pointer;
`;

export const IosStoreIconImage = styled.img`
  width: 15%; 
  padding-right: 25px;
  padding-bottom: 5px;
  padding-left: 35px;
`;

export const IconRow = styled.div`
  display: flex;
  
`;

export const IconContainer = styled.div`
  /* margin-right: 2px;  */

`;
export const DropdownContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

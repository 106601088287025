import React from 'react';
import {
  DoctorContainer,
  DoctorDetails,
  DoctorsDetails,
  DoctorsDetails1,
  DoctorsImage,
  ImageContainer,
  Name,
} from '../VideoConsultationElements';

function DoctorCard({ image, name, department, experience }) {
  return (
    <DoctorContainer>
      <ImageContainer>
        <DoctorsImage src={image} alt={name} />
      </ImageContainer>
      <DoctorDetails>
        <Name>{name}</Name>
        <DoctorsDetails1>{department}</DoctorsDetails1>
        <DoctorsDetails1>{experience}</DoctorsDetails1>
      </DoctorDetails>
    </DoctorContainer>
  );
}

export default DoctorCard;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlineMail, AiFillLinkedin } from 'react-icons/ai';
import CEO from '../../images/v1/CEO.jpg';
import CTO from '../../images/v1/CTO.jpg';
import CPO from '../../images/v1/CPO.jpg';
import VPM from '../../images/v1/VPM.jpg';
import CFO from '../../images/v1/CFO.jpeg'; 
import CMO from '../../images/v1/CMO.jpeg'; 
import Footer from '../Footer/Footer';

const coreTeamMembers = [
  {
    id: 1,
    name: 'AMAR KUMAR',
    roles: ['Founder & CEO'],
    bio: [
      'Previous Experience:-',
      'Managing Director- ARGK Hospital,',
      'Vice President Operations & CFO - Pharma Lead Family Healthcare Business,',
      'Business Advisor at Education Institute in Patna.'
    ],
    education: 'Education:- IIT Roorkee, NSUT Delhi',
    linkedin: 'https://www.linkedin.com/in/amar-kumar23?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    emails: ['amar@healthko.in', 'amar.iitroorkee@gmail.com'],
    image: CEO
  },
  {
    id: 2,
    name: 'PRABHAT KUMAR',
    roles: ['Co-founder & CTO'],
    bio: [
      'Previous Experience:-',
      'Co-founder at IITina Bookwala.'
    ],
    education: 'Education:- B.Tech, IIT Delhi',
    linkedin: 'https://www.linkedin.com/in/prabhat-singh-04b0411b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    image: CTO
  },

 
  {
    id: 5,
    name: 'Dr. Akash Jaiswal',
    roles: ['MBBS, MD (Geriatric Medicine)', 'Chief Medical officer (CMO)'],
    bio: [
      'Previous Experience:- ',
      ' Junior Resident(Non Acad) in AIIMS Delhi, Senior Resident (AIIMS Delhi), Consultant Geriatric Medicine at Fortis Healthcare.'
    ],
    education: 'Education:- MBBS & MD in Geriatric Medicine (AIIMS Delhi)',
    image: CMO
  },
  {
    id: 6,
    name: 'SAMRIDDHI RAMAWAT',
    roles: ['Marketing Lead'],
    bio: [
      'Previous Experience:-',
      'Head of Digital Marketing - Frimline Private Limited,',
      'Emcure Pharmaceutical,',
      'Lendingkart,',
      'ICICI Bank,',
      'KPMG.'
    ],
    education: 'Education:- IIM Lucknow',
    image: VPM
  },
];

const adviserTeamMembers = [
  {
    id: 3,
    name: 'RAJ KUMAR ASH',
    roles: ['Product Advisor'],
    bio: [
      'Previous Experience:-',
      'Director Product - Arthmate Gurgaon,',
      'Sr Product Manager - OFICEA Technologies Gurgaon,',
      'Sr Product Manager - SCA Technologies Gurgaon,',
      'AVP- FinIQ Consulting India Pvt Ltd Pune.'
    ],
    education: 'Education:- B.Tech, IIT-BHU, MBA-IIM Ahmadabad, MBA- Catolica Lisbon School of Business & Economic, Lisbon, Portugal.',
    image: CPO
  },
  {
    id: 4,
    name: 'PRAAMOD KUMAR',
    roles: ['Business Advisor'],
    bio: [
      'Previous Experience:- 19+ years',
      'BLACKBOX NETWORK SERVICES PRIVATE LIMITED (AVP Finance),',
      'UNITED BREWERIES LIMITED (DGM, Supply Chain Business Partnering and Central FPNA),',
      'FLIPKART INTERNET PVT LTD (Manager, Finance),',
      'ONMOBILE GLOBAL LIMITED (Senior Financial Analyst),',
      'BOSCH LTD (Senior Officer, Corporate Accounting and Controlling Department),',
      'SIMPLEX INFRASTRUCTURE LTD. (Senior Officer),',
      'J GUPTA & COMPANY (Manager, Finance)'
    ],
    education: 'Education:- Chartered Accountant',
    image: CFO
  },

];

const TeamContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const TeamHeading = styled.h1`
  font-size: 2em;
  margin-bottom: 35px;
`;

const TeamGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
`;

const TeamCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 100%;
  max-width: 800px;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 720px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TeamImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 20px;

  @media (max-width: 720px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TeamName = styled.h2`
  font-size: 1.5em;
  margin: 10px 0;
`;

const TeamRoles = styled.div`
  display: flex;
  flex-direction: column;
`;

const TeamRole = styled.h3`
  font-size: 1.2em;
  color: gray;
`;

const TeamEducation = styled.h4`
  font-size: 1.1em;
  color: darkslategray;
  margin: 10px 0;
`;

const TeamBio = styled.div`
  font-size: 1em;
  color: darkgray;
`;

const TeamBioItem = styled.p`
  margin: 5px 0;
`;

const TeamLinkedIn = styled.a`
  font-size: 1em;
  color: dodgerblue;
  text-decoration: none;
  margin-top: 5px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkedInIcon = styled(AiFillLinkedin)`
  margin-right: 5px;
`;

const TeamEmail = styled.a`
  font-size: 1em;
  color: darkslategray;
  margin-top: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const EmailIcon = styled(AiOutlineMail)`
  margin-right: 5px;
`;

const TeamSection = ({ title, members }) => (
  <>
    <TeamHeading>{title}</TeamHeading>
    <TeamGrid>
      {members.map((member) => (
        <TeamCard key={member.id}>
          <TeamImage src={member.image} alt={member.name} />
          <TeamDetails>
            <TeamName>{member.name}</TeamName>
            <TeamRoles>
              {member.roles.map((role, index) => (
                <TeamRole key={index}>{role}</TeamRole>
              ))}
            </TeamRoles>
            <TeamEducation>{member.education}</TeamEducation>
            <TeamBio>
              {member.bio.map((line, index) => (
                <TeamBioItem key={index}>{line}</TeamBioItem>
              ))}
            </TeamBio>
            {member.linkedin && (
              <TeamLinkedIn href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <LinkedInIcon /> LinkedIn
              </TeamLinkedIn>
            )}
            {member.emails && (
              <div>
                {member.emails.map((email, index) => (
                  <TeamEmail key={index} href={`mailto:${email}`}>
                    <EmailIcon /> {email}
                  </TeamEmail>
                ))}
              </div>
            )}
          </TeamDetails>
        </TeamCard>
      ))}
    </TeamGrid>
  </>
);

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TeamContainer>
      <TeamHeading>Meet Our Team: The Minds Behind HealthKo.</TeamHeading>
      <TeamSection title="Meet Our Core Team" members={coreTeamMembers} />
      <TeamSection title="Meet Our Advisors" members={adviserTeamMembers} />
      <Footer />
    </TeamContainer>
  );
};

export default Team;

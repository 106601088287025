import React from 'react';
import styled from 'styled-components';
import Convenience from '../../images/v2/Convenience-and-Comfort.png';
import Elderly from '../../images/v2/Elderly-and-Immobilised-Accessibility.png'
import Reduced from '../../images/v2/Reduced-Risk-of-Infections.png'
import Enhanced from '../../images/v2/Enhanced-Communication.png'
import Family from '../../images/v2/Family-Involvement.png'
import Savings from '../../images/v2/Convenience-and-Time-Management.png'
import Cost from '../../images/v2/cost-effective.png'

const CardContainer = styled.div`
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient( #CAF0F8, #D8F3DC);
  padding: 40px 20px; /* Adjust padding */
  margin-bottom: 40px;
  h1 {
    padding-bottom: 40px;
    font-size: 36px;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: -2%;
    line-height: 42.19px;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const IconBox = styled.div`
  border-radius: 12px;
  padding: 8px;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const IconImage = styled.img`
  width: 60px;
  height: 60px;
  z-index: 2;
`;

const IconText = styled.div`
  h2 {
    color: #00AFFE;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 28.13px;
    font-family: Roboto;
  }
  p {
    color: #353535;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 21.09px;
    font-family: Roboto;
    letter-spacing: -2%;
  }
`;

const BenifitOnlineConsultation = () => {
  return (
    <CardContainer>
      <h1>Benefits of Doctor Visit At Home</h1>
      <IconContainer>
        <IconBox>
          <IconImage src={Convenience} alt="Icon" />
          <IconText>
            <h2>Convenience and Comfort </h2>
            <p>You can receive medical care in the comfort of your own home, avoiding the hassle of travelling to a clinic or hospital.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Elderly} alt="Icon" />
          <IconText>
          <h2>Elderly and Immobilised Accessibility</h2>
            <p>For elderly or immobilised patients, home visits provide essential medical care without the challenges of transportation.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Reduced} alt="Icon" />
          <IconText>
          <h2>Reduced Risk of Infections</h2>
            <p>By avoiding crowded waiting rooms, patients lower their risk of exposure to contagious illnesses commonly found in healthcare facilities.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Enhanced} alt="Icon" />
          <IconText>
          <h2>Enhanced Communication</h2>
            <p>In a familiar setting, patients may feel more at ease communicating with their doctor, leading to better understanding and compliance with treatment plans.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Family} alt="Icon" />
          <IconText>
          <h2>Family Involvement</h2>
            <p>Family members can actively participate in the patient's care and gain a better understanding of their condition and treatment.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Savings} alt="Icon" />
          <IconText>
          <h2>Time Savings</h2>
            <p>Home visits eliminate the need for travel time and waiting room delays, allowing patients to spend more time on their recovery or daily activities.</p>
          </IconText>
        </IconBox>
        <IconBox>
          <IconImage src={Cost} alt="Icon" />
          <IconText>
          <h2>Cost-Effectiveness</h2>
            <p>Home visits can reduce overall healthcare costs by preventing unnecessary heavy bills of emergency room visits and hospitalisations.</p>
          </IconText>
        </IconBox>
      </IconContainer>
    </CardContainer>
  );
};

export default BenifitOnlineConsultation;

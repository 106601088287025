import styled from "styled-components";
import { FaArrowRight } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';

export const imageStyles = {
  display: 'flex',
  maxWidth: '100%',
  height: '100%',
  marginBottom: '20px',
};

export const StyledHeading = styled.h1`
  color: #1B263B;
  font-size: 36px;
  font-weight: 400;
  font-family: Roboto;
  line-height: 42.19px;
  letter-spacing: -2%;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
`;

export const StyledParagraph = styled.p`
  color: #696969;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  padding: 10px 30px 20px 30px;
`;
export const StyledParagraphs = styled.p`
  color: #696969;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  letter-spacing: -2%;
  text-align: center;
  padding: 5px 30px 30px 30px;
`;
export const StyledHeadings = styled.h1`
  color: #1B263B;
  font-size: 36px;
  font-weight: 400;
  font-family: Roboto;
  line-height: 42.19px;
  letter-spacing: -2%;
  margin-top: 30px;
 margin-bottom: 10px;
  text-align: center;
`;

export const Question = styled.div`
  background-color: ${props => props.isOpen ? '#C4E8F9' : '#fff'};
  cursor: pointer;
  border: 1px solid #C6C6C6; 
  border-radius: 5px;
  margin-bottom: 20px; 
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 14px ; 
  box-sizing: border-box;
`;
export const QuestionContent = styled.div`
   padding: 0 20px 0 20px;
`;

export const DropdownIcon = styled.div`
  color: #555; /* Color of the dropdown icon */
`;

export const Answer = styled.div`
  color: #696969;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  padding: 10px 30px 20px 30px;
`;
export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column; 
  width: 100%;
  max-width: 75%; 
  margin: 0 auto;
  padding: 20px; 

  @media screen and (max-width: 500px) {
    display: flex;
  flex-direction: column; 
  width: 100%;
  max-width: 95%; 
  margin: 0 auto;
  padding: 20px; 
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  width: 100%;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    background-color: #CAF0F8;
    width: 2px;
    top: 50px;
    bottom: 100px;
    margin: auto;
    z-index: -1; /* Ensure line is behind the circles */
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  

    &::before {
      height: 2px;
      width: calc(70% - 100px); /* Adjust based on padding and circle size */
      left: 48%;
      top: 43%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* For pseudo-element positioning */
  margin: 20px 0; /* Increased margin for better spacing */

  @media (min-width: 768px) {
    margin: 0; /* Reset margin for horizontal layout */
  }
`;

export const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CAF0F8;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StepImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const StepText = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  line-height: 18.75px;
  letter-spacing: -2%;

  @media (min-width: 768px) {
    margin-top: 10px;
  }
`;

// export const SearchBarContainer = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   padding-left: 60px;
//   width: auto;
//   max-width: 95%; /* Allow the container to expand */
//   margin-right: 10px; /* Add margin to the right side of the container */
// `;

// export const SearchInput = styled.input`
//   width: 50%;
//   height: 40px;
//   padding: 0 50px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   margin-right: 5px; /* Add margin between the input and the button */
// `;

// export const SearchIcon = styled(FaSearch)`
//   position: absolute;
//   left: 75px;
//   top: 50%;
//   transform: translateY(-50%);
//   color: #00AFFE;
// `;

// export const StyledButtons = styled.button`
//   height: 40px;
//   width: 200px;
//   background-color: #fff;
//   font-size: 13px;
//   font-weight: 500;
//   font-style: Roboto;
//   border: 1px solid #C6C6C6;
//   border-radius: 8px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
//   margin-left: auto; /* Push the button to the right side */
//   &:hover {
//     box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
//   }
// `;

export const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 60px;
  width: auto;
  max-width: 95%; /* Allow the container to expand */
  margin-right: 10px; /* Add margin to the right side of the container */
  
  @media (max-width: 568px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
  }
`;

export const SearchInput = styled.input`
  width: 50%;
  height: 40px;
  padding: 0 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px; /* Add margin between the input and the button */
  
  @media (max-width: 568px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px; /* Add margin below the input in column layout */
  }
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  left: 75px;
  top: 50%;
  transform: translateY(-50%);
  color: #00AFFE;
  
  @media (max-width: 568px) {
    left: 35px;
    top: 12px;
    transform: none;
  }
`;

export const StyledButtons = styled.button`
  height: 40px;
  width: 200px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 21.09px;
  align-items: center;
  letter-spacing: -2%;
  font-style: Roboto;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto; /* Push the button to the right side */
  
  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }

  @media (max-width: 568px) {
    width: 100%;
    margin-left: 0; /* Remove margin left in column layout */
  }
`;


export const StyledButton = styled.button`
  /* Define your button styles here */
  background-color: #007bff;
  font-size: 14px;
  margin-top: 20px;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }

  @media screen and (max-width: 580px) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;



export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 10%;
    display: block;
    /* object-fit: contain; */
  }

  .text-overlay {
    position: absolute;
    top: 40%;
    left: 30%;
    transform: translate(-50%, -50%);
    color: #262c2f;
    text-align: start;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
 
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.4rem;
    font-family: bold;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1256px) {
    .text-overlay {
      padding: 10px;
      max-width: 90%;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 994px) {
    .text-overlay {
      padding: 5px;
      max-width: 95%;
    }

    h1 {
      font-size: 1.4rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 580px) {
    .text-overlay {
      padding: 5px;
      max-width: 95%;
    }

    h1 {
      font-size: 0.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.3rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 0.5rem;
      margin-bottom: 5px;
    }
  }
`;


import React, { useEffect, useState } from "react";
import {
  HomeContainer,
  SearchInput,
  SearchContainer,
  SearchBtn,
  HeroHeading,
  HeroSubHeading,
  HeroContainer,
} from "./HeroSectionElements";
import {
  Container,
  HeroParagraph,
  Image,
  ImageContainer,
} from "../commonComponents";
import heroImg from "../../images/v1/Banner-Image.png";
const HomeSection = () => {
  const [hover, setHover] = useState(false);
  const [searchPlaceholderText, setSearchPlaceholderText] = useState("");
  const onHover = () => {
    setHover(!hover);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setSearchPlaceholderText("Search hospitals, doctors, region");
    } else {
      setSearchPlaceholderText(
        "Search hospitals, doctors, clinic | Search Region"
      );
    }
  });

  return (
    <Container>
      <HeroContainer>
        <HomeContainer>
          <HeroHeading>Welcome to healthKo.</HeroHeading>
          <HeroSubHeading>
            Your Partner in Personalized Healthcare
          </HeroSubHeading>
          <HeroParagraph>
            We strive to provide a seamless experience, offering personalized
            care that transcends traditional boundaries. Discover a new era of
            accessible and compassionate healthcare with HealthKo.
          </HeroParagraph>
          <SearchContainer>
            <SearchInput type="text" placeholder={searchPlaceholderText} />
            <SearchBtn>Search</SearchBtn>
          </SearchContainer>
        </HomeContainer>
        <ImageContainer>
          <Image src={heroImg} alt="HealthKo" />
        </ImageContainer>
      </HeroContainer>
    </Container>
  );
};

export default HomeSection;

import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs5 from "../../images/blogsimage/HealthKoBlogs5.jpg";

const PostPage5 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    document.title = ' Diabetes: Symptoms, Causes, Treatments & Types';

    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };
  
    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };
  
    setLinkTag('blogs', 'https://www.healthko.in/Diabetes-symptoms-and-causes');

    setMetaTag('description', ' Learn more about diabetes symptoms in men and women, causes, types, prevention and treatments to manage and reduce your risk of this chronic condition.');

  }, []);

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>Understanding Diabetes: Symptoms, Causes, Types, Treatments, and Prevention</h1>
        <p>Diabetes is a long-term condition affecting the way the body processes blood sugar or glucose that is gotten from food. Studies show that more than 400 million people around the globe have diabetes and this is a very big issue.</p>
        <img src={HealthKoBlogs5} alt="Diabetes: Symptoms, Causes, Types, Treatments, and Prevention" />
        <p>Therefore, it is crucial to identify the nature of symptoms, causes, and kinds of diabetes to control or avoid this disease.</p>
      
        <h1>Symptoms of Diabetes</h1>
        <h2>Common Symptoms in Men</h2>
        <p> ●  Erectile dysfunction</p>
        <p> ●  Reduced muscle mass</p>
        <p> ●   <a href="https://www.medicalnewstoday.com/articles/324861"target="_blank" rel="noopener noreferrer">Decreased libido</a></p>
       

        <h2>Common Symptoms in Women</h2>
        <p>●  Vaginal infections</p>
        <p>●  Polycystic ovarian syndrome (PCOS)</p>
        <p>●  Urinary tract infections</p>

        <p>Several of the symptoms are clearly distinct with both genders having the right to persistently feel thirsty, urinate more than usual, lose weight without reasons, feel constantly weak and have problems with their vision</p>
        
        <h1>Causes of Diabetes</h1>
        <h2>Genetic Factors</h2>
        <p>In this case, genetics have been noted to influence the development of diabetes in many ways. The occurrence of diabetes is also determined by factors such as family history; if you are a victim of this gene, then it means that you are at a higher risk of having diabetes.</p>

        <h2>Lifestyle Factors</h2>
        <p>Diet and lack of exercise or physical inactivity and obesity are some of the leading causes of diabetes. Consuming excessive sugar and fats contribute to the development of insulin resistance, a primary characteristic of the second type of diabetes.</p>
      
        <h2>Other Contributing Factors</h2>
        <p>● Age:The impact of the risks also barns with age.</p>
        <p>● Ethnicity: But some ethnic groups are more likely to get it than others.</p>
        <p>● Medical History: Such diseases as high blood pressure and abnormal cholesterol levels are some of the conditions that may lead to an increase in the risk.</p>

        <h1>Types of Diabetes</h1>
        <h2>Type 1 Diabetes</h2>
        <p><a href="https://www.niddk.nih.gov/health-information/diabetes/overview/what-is-diabetes/type-1-diabetes"target="_blank" rel="noopener noreferrer">  Type 1 diabetes </a> is a chronic disease that affects the insulin-releasing cells of the pancreas through a destruction by the body’s immune system. This type of cancer is normally found in children and young people up to the age of 40 years.</p>

        <h2>Type 2 Diabetes</h2>
        <p>Type 2 diabetes involves the body not responding appropriately to insulin or insulin-producing cells in the pancreas not secreting enough insulin. More prevalent in adults, it is more frequently diagnosed in children and teenagers nowadays.</p>
        
        
        <h2>Gestational Diabetes</h2>
        <p>This type forms during pregnancy and is mostly seen in women who have recently given birth. However, it causes the individual to become vulnerable to <a href="https://www.nhs.uk/conditions/type-2-diabetes/"target="_blank" rel="noopener noreferrer">type 2 diabetes</a> when he or she is in the middle of his or her age.</p>
        <h2>Other Types of Diabetes</h2>
        <p>There are also several other forms of diabetes which are relatively rare and include;<a href="https://www.niddk.nih.gov/health-information/diabetes/overview/what-is-diabetes/monogenic-neonatal-mellitus-mody#:~:text=Some%20rare%20forms%20of%20diabetes,from%20one%20or%20both%20parents."target="_blank" rel="noopener noreferrer">monogenic diabetes </a>  and <a href="https://www.mayoclinic.org/diseases-conditions/cystic-fibrosis/symptoms-causes/syc-20353700"target="_blank" rel="noopener noreferrer"> cystic fibrosis</a> related diabetes</p>
       

        <h1>Treatment Options</h1>
        <h2>Medications</h2>
        <p>There are various medications that can be used as treatment for diabetes like insulin injections, <a href="https://www.drugs.com/metformin.html"target="_blank" rel="noopener noreferrer">  metformin</a> and other oral or injectable medications. Your doctor will determine the best medication to treat your diabetes depending on what type of diabetes you have and other needs you may have.</p>

        <h2>Lifestyle Changes</h2>
        <p>Adopting a healthy lifestyle is crucial for managing diabetes:</p>
        <p>● Healthy Diet: Should focus on whole grains, fruits and vegetables and lean proteins.</p>
        <p>● Regular Exercise: Aim to perform at least 150 minutes of moderate aerobic exercises per week.</p>
        <p>● Weight Management: By maintaining a healthy weight, insulin resistance can be improved.</p>
        
      
        <h2>Alternative Treatments</h2>
        <p>Some patients try other therapies such as<a href="https://www.medicalnewstoday.com/articles/156488"target="_blank" rel="noopener noreferrer"> acupuncture</a> , herbal therapy or meditation. Before trying any other therapy, it is important to talk with a doctor.</p>
        
        <h1>Prevention of Diabetes</h1>
        <h2>Best Options for Summer</h2>
        <p>Following a healthy eating plan that is rich in fibre, wholegrains and unsaturated fats can prevent diabetes, while sugary drinks and processed carbs should be avoided.</p>


        <h2>Physical Activity</h2>
        <p>Being physically active most of the time assists in weight control and enhances the sensitivity of cells to insulin. Walking, cycling or swimming daily can contribute to preventing diabetes.</p>
  
        
        <h2>Regular Screenings</h2>
        <p>Regular screening can enable the early treatment of diabetes complications and diabetes-related diseases, such as hypertension and <a href="https://en.wikipedia.org/wiki/Hyperlipidemia"target="_blank" rel="noopener noreferrer"> hyperlipidaemia</a>. Talk to your doctor about screening if you are frequently at risk.</p>

       
        <h1>Conclusion,</h1>
        <p>Diabetes is a serious condition, but it can be managed and even prevented in many cases with proper knowledge and lifestyle changes. If you have symptoms or risk factors for diabetes, don’t ignore it – make sure to <a href="https://www.healthko.in/Online-Doctor-Consultation"target="_blank" rel="noopener noreferrer">speak to your doctor </a> about proper diagnosis and treatment. For everyone else, taking preventative measures can help you maintain a healthier lifestyle and avoid the complications of diabetes. </p>
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage5;

import styled from "styled-components";

export const imageStyles = {
  display: 'flex',
  maxWidth: '100%',
  height: '100%',
  marginBottom: '20px',
};

export const StyledHeading = styled.h1`
  color: #262C2F;
  font-size: 30px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 29px;
`;

export const StyledParagraph = styled.p`
  color: #353535;
  font-size: 18px;
  font-family: Roboto;
  line-height: 21.09px;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px 45px 2px 50px;
 
  
`;

export const Question = styled.div`
  background-color: ${props => props.isOpen ? '#C4E8F9' : '#fff'};
  cursor: pointer;
  border: 1px solid #C6C6C6; 
  border-radius: 5px;
  margin-bottom: 20px; 
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 14px ; 
  box-sizing: border-box;
`;
export const QuestionContent = styled.div`
   padding: 0 20px 0 20px;
`;

export const DropdownIcon = styled.div`
  color: #555; /* Color of the dropdown icon */
`;

export const Answer = styled.div`
color: #696969;
font-size: 18px;
font-family: Roboto;
line-height: 21.09px;
margin-bottom: 20px;
text-align: start;
padding: 10px 30px 20px 30px;
letter-spacing: -2%;

ol {
  margin-left: 20px;
  padding-left: 20px;
  margin-top: 15px
 
}

li {
  margin-bottom: 10px;
  font-family: Roboto;
}
`;
export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column; 
  width: 100%;
  max-width: 75%; 
  margin: 0 auto;
  justify-content: space-between;
  padding: 18px, 20px, 18px, 20px;
  @media screen and (max-width: 500px) {
    display: flex;
  flex-direction: column; 
  width: 100%;
  max-width: 95%; 
  margin: 0 auto;
  padding: 20px; 
  }

`;


export const ImageContainers = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 10%;
    display: block;
    /* object-fit: contain; */
  }

  .text-overlay {
    position: absolute;
    top: 40%;
    left: 34%;
    transform: translate(-50%, -50%);
    color: #262c2f;
    text-align: start;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
 
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.4rem;
    font-family: bold;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1256px) {
    .text-overlay {
      padding: 10px;
      max-width: 90%;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 994px) {
    .text-overlay {
      padding: 5px;
      max-width: 95%;
    }

    h1 {
      font-size: 1.4rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 580px) {
    .text-overlay {
      padding: 5px;
      max-width: 95%;
    }

    h1 {
      font-size: 0.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.3rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 0.5rem;
      margin-bottom: 5px;
    }
  }
`;


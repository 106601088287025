// components/AdminLoginPage.js

import React from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
`;

const LoginFormContainer = styled.div`
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 400px; /* Adjust width as needed */
`;

const AdminLoginPage = ({ onLogin }) => {
  return (
    <PageContainer>
      <LoginFormContainer>
        <h2>Admin Login</h2>
        <LoginForm onLogin={onLogin} />
      </LoginFormContainer>
    </PageContainer>
  );
};

export default AdminLoginPage;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContainer,
  ProfileImage,
  Name,
  Money,
  ConsultButton,
  StyledArrowIcon
} from './SepecialistElements';
import { useAuthContext } from '../../../hooks/AuthContextHook';

const SpecialistCard = ({ review }) => {
  const { name, image, money, link } = review;
  const navigate = useNavigate();
  
  // const handleConsultClick = (e) => {
  //   e.preventDefault();
  //   navigate(link);
  // };

  const handleConsultClick = (e) => {
    e.preventDefault();
    if (link.startsWith("http")) {
      window.open(link, "_blank", "noopener,noreferrer");
    } else {
      navigate(link);
    }
  };

  return (
    <CardContainer>
      <ProfileImage >
        <img src={image} alt={name} />
      </ProfileImage>
      <Name>{name}</Name>
      <Money>{money}</Money>
      <ConsultButton onClick={handleConsultClick}>
        Book Appointment <StyledArrowIcon /> {/* Render the button symbol */}
      </ConsultButton>
    </CardContainer>
  );
};

export default SpecialistCard;

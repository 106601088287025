import styled from 'styled-components';
import { MenuItem } from './NavbarElements';

export const Item = styled.div`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 220px;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: inline-block;
  width: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CustomMenuItem = styled(MenuItem)`
  font-weight: 400;
  line-height: 26px; /* 113.043% */

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const SettingsContainer = styled.div`
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
`;

import styled from 'styled-components';
import {
  OutlinedBtn,
  Paragraph,
  PrimaryBtn,
  SessionParagraph,
} from '../commonComponents';
import { CheckBox } from '../AuthUI/AuthUIElements';
import { Link } from 'react-router-dom';

export const SlideBox = styled.div`
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1rem;
  flex-direction: column;
  margin: 0.5rem 0.25rem;
`;

export const SlideImageContainer = styled.div`
  width: 100%;
  height: 120px;
  overflow: hidden;
`;

export const SlideImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 16px;
`;

export const SliderHeading = styled.h5`
  color: ${(props) => props.theme.colors.heading};
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px; /* 96% */
  text-transform: capitalize;
  padding-bottom: 1rem;
`;

export const Price = styled.p`
  color: #868686;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 109.091% */
  text-transform: capitalize;
`;

export const CustomOutlinedBtn = styled(OutlinedBtn)`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 1rem;
  transition: all 0.25s ease-in-out;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  height: fit-content;
  &:hover {
    color: #fff;
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const ModalHead = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomModalHead = styled(ModalHead)`
  border-bottom: 1px solid;
  border-color: ${(props) => props.color};
  padding: 3rem;
  padding-top: 2rem;
  padding-bottom: 0;
`;

export const ConsultationBody = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.topMargin ? '2rem' : 0)};
`;

export const DoctorList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  row-gap: 1rem;
  padding: 0.5rem 3rem 1rem 3rem;
  border-right: 1px solid #e1e1e1;
`;

export const DoctorContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #c5c5c5;
  background: #fff;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  width: 500px;
`;

export const ImageContainer = styled.div`
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 100%;
`;
export const DoctorDetails = styled.div`
  margin-left: 1rem;
  text-align: left;
`;
export const DoctorsImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
export const Name = styled.p`
  margin: 8px 0;
  line-height: 18px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.heading};
`;

export const DoctorsDetails1 = styled(SessionParagraph)`
  margin: 4px 0;
  width: fit-content;
  max-width: fit-content;
  line-height: 16px;
  font-size: 16px;
  color: #888080;
`;
export const DoctorsDetails = styled(SessionParagraph)`
  width: fit-content;
  line-height: 16px;
  font-size: 16px;
  color: #888080;
  line-height: 22px;
`;

export const ConsultationForm = styled.form`
  flex: 1;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Label = styled.label`
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 126.087% */
`;

export const FormContainer = styled(DoctorContainer)`
  justify-content: space-between;
  height: fit-content;
`;

export const CustomCheckBox = styled(CheckBox)`
  width: 25px;
  height: 25px;
`;

export const CustomLabel = styled.label`
  font-weight: bold;
  color: ${(props) => props.theme.colors.heading};
  font-style: normal;
  font-weight: 500;
  line-height: 29px; /* 126.087% */
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SessionParagraph1 = styled(SessionParagraph)`
  margin: 0;
  line-height: normal;
  margin-top: 8px;
`;

export const CustomLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  text-transform: capitalize;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const BtnWrapepr = styled(PrimaryBtn)`
  max-width: fit-content;
`;

export const LeftIcon = styled.div`
  position: absolute;
  font-size: 28px;
  left: 2.5rem;
  color: ${(props) => props.theme.colors.text};
  padding: 8px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.heading};
  }
`;
export const DoctorImageDetails = styled.div`
  display: flex;
  border-left: 1px solid;
  border-color: ${(props) => (props.border ? '#e1e1e1' : 'transparent')};
  width: ${(props) => (props.border ? '60%' : '100%')};
  padding-bottom: ${(props) => (props.paddingTop ? 0 : '3rem')};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 0)};
  padding-left: ${(props) => (props.paddingTop ? 0 : '3rem')};
  padding-right: ${(props) => (props.paddingTop ? 0 : '3rem')};
`;

export const FlexImageContainer = styled.div`
  width: 300px;
  height: 300px;
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
  border-radius: 1rem;
  flex: 1;
`;

export const CustomDoctorsDetails = styled(DoctorsDetails)`
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
`;

export const ChatButton = styled(PrimaryBtn)`
  min-width: fit-content;
  height: fit-content;
  padding: 0.75rem;
  font-size: 26px;
  padding: 1rem;
`;

export const Btn = styled(PrimaryBtn)`
  min-width: fit-content;
  height: fit-content;
  font-size: 20px;
  flex: 1;
  margin-left: 1rem;
  padding-top: 19px;
  padding-bottom: 19px;
`;

export const BtnStyle = styled.div`
  color: ${(props) => (props.black ? props.theme.colors.heading : 'white')};
  display: ${(props) => (props.black ? 'block' : 'flex')};
  align-items: center;
  font-weight: 700;
  justify-content: center;
`;

export const FlexBody = styled.div`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '0')};
  flex: 1;
  padding: 1rem 0rem 0rem 3rem;
`;

export const DoctorName = styled.h3`
  color: ${(props) => props.theme.colors.heading};
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 68.571% */
  text-transform: capitalize;
`;

export const Paragraph1 = styled(Paragraph)`
  margin: 0.7rem 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  text-transform: capitalize;
  margin-top: 5rem;
`;

export const Experince = styled.div`
  width: fit-content;
`;

// export const Paragraph1 = styled(Paragraph)`
//   margin: 0.7rem 0;
// `;

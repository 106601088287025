import styled from "styled-components";
import bg from "../images/v1/video-consultation.png";

export const PrimaryBtn = styled.button`
  min-width: 80px;
  height: 44px;
  color: #fff;
  flex-shrink: 0;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 14px 42px;
  border-radius: 16px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 900px) {
    padding: 12px 24px;
    min-width: fit-content;
  }
`;

export const OutlinedBtn = styled(PrimaryBtn)`
  color: ${(props) => props.theme.colors.heading};
  background: transparent;

  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }

`;

export const Container = styled.div`
  height: max-content;
  width: 100%;
  max-width: 85%;
  margin: 0 auto;
  padding: 2.5rem 1rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    max-width: 100%;
  }
`;

export const HeroParagraph = styled.p`
  color: ${(props) => props.theme.colors.text};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 154.545% */
  margin: 2rem 0;
`;

export const Paragraph = styled(HeroParagraph)`
  line-height: 38px; /* 154.545% */
  text-align: justify;
`;

export const ImageContainer = styled.div`
  display: inline-block;
  width: 40%;
  height: 100%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 400px) {
    margin-bottom: 0rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const SectionHeading = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;

  @media screen and (max-width: 400px) {
    margin-top: 1rem;
  }
`;

export const Error = styled.label`
  color: red;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.5rem 0;
  font-size: 14px;
  text-transform: capitalize;
`;

export const SessionHeading = styled(SectionHeading)`
  font-size: 30px;
`;

export const SessionContainer = styled.div`
  height: calc(100vh - 100px);
  width: 100%;
  background: url(${bg}) lightgray bottom/cover no-repeat;
`;

export const ModalContent = styled.div`
  padding: ${(props) => props.padding};
`;

export const SessionParagraph = styled(Paragraph)`
  font-size: 14px;
  max-width: 85%;
  line-height: 29px;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderConatiner = styled.div`
  max-width: 92%;
  margin: 0 auto;
`;

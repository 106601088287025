import React from "react";
import {
  CustomMenuItem,
  ImageContainer,
  Item,
  SettingsContainer,
} from "./SettingsModalElements";
import { Image } from "../commonComponents";
import settings1 from "../../images/v1/settings1.png";
import settings2 from "../../images/v1/settings2.png";
import settings3 from "../../images/v1/settings3.png";
import settings4 from "../../images/v1/settings4.png";
import settings5 from "../../images/v1/settings5.png";
import settings6 from "../../images/v1/settings6.png";
import settings7 from "../../images/v1/settings7.png";
import settings8 from "../../images/v1/settings8.png";
import settings9 from "../../images/v1/settings9.png";
import settings10 from "../../images/v1/settings10.png";
import settings11 from "../../images/v1/settings11.png";
import settings12 from "../../images/v1/settings12.png";
import { useAuthContext } from "../../hooks/AuthContextHook";
import axios from "axios";

const handleDeleteAccount = () => {
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("token");
  const apiUrl = `${process.env.REACT_APP_SERVER}users/${userId}`;
  axios
    .delete(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => {
      console.log("User deleted successfully:", response.data);
    })
    .catch((error) => {
      console.error("There was a problem with the request:", error);
    });
};

const settingsMenu = [
  { name: "Notification settings", icon: settings1 },
  { name: "Reminder volume", icon: settings2 },
  { name: "Vibrate", icon: settings3 },
  { name: "Snooze duration", icon: settings4 },
  { name: "Popup Notification", icon: settings5 },
  { name: "Privacy policy", icon: settings6 },
  { name: "Help and support", icon: settings7 },
  { name: "About HealthKO", icon: settings8 },
  { name: "Rate healthKO", icon: settings9 },
  { name: "Share with friends", icon: settings10 },
  { name: "Logout", icon: settings11 },
  { name: "Delete Account", icon: settings12 },
];

function SettingsModal({ handleModalClose }) {
  const { updateAuth } = useAuthContext();
  return (
    <SettingsContainer>
      {settingsMenu.map((setting) => {
        return (
          <Item
            key={setting.name}
            onClick={() => {
              if (setting.name === "Logout") {
                localStorage.clear();
                updateAuth({
                  isLogged: false,
                  isAuthModalOpen: false,
                });
              }
              if (setting.name === "Delete Account") {
                handleDeleteAccount();
                localStorage.clear();
                updateAuth({
                  isLogged: false,
                  isAuthModalOpen: false,
                });
              }
              handleModalClose();
            }}
          >
            <ImageContainer>
              <Image src={setting.icon} />
            </ImageContainer>
            <CustomMenuItem>{setting.name}</CustomMenuItem>
          </Item>
        );
      })}
    </SettingsContainer>
  );
}

export default SettingsModal;

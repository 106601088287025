import styled from 'styled-components';

export const CloseBtn = styled.div`
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: 42px;
  border-radius: 999px;
  position: absolute;
  top: 0;
  right: -1rem;
  top: -1rem;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

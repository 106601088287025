import React, { useEffect, useState } from 'react';
import {
  ModalContent,
  SessionContainer,
  SessionHeading,
  SliderConatiner,
} from '../commonComponents';
import Modal from '../Modal/Modal';
import { useNavigate } from 'react-router-dom';
import {
  clinicalSlide,
  clinicalsliderSettings,
  multipleSliderSettings,
  multipleSlides,
} from '../../constants/constants';

import ReactSlider from '../ReactSlider/ReactSlider';
import {
  ModalHead,
  SessionParagraph1,
} from '../VideoConsultation/VideoConsultationElements';
import SessionCard from '../VideoConsultation/SessionCard/SessionCard';
import ClinicCard from './ClinicCard/ClinicCard';
import useConsultationHook from '../../hooks/ConsultationHook';
import { useAuthContext } from '../../hooks/AuthContextHook';

function ClinicAppointment() {
  const navigate = useNavigate();
  const { auth } = useAuthContext();

  const { setConsultationData, getDoctors, setSlot, consultationData } =
    useConsultationHook();
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseModal = () => {
    navigate(-1);
    setIsOpen(false);
  };

  const handleDepartmentSelection = () => {
    setStep(2);
  };

  useEffect(() => {
    if (auth.isLogged) getDoctors('clinic');
  }, [auth.isLogged]);

  if (auth.isLogged)
    return (
      <SessionContainer>
        <Modal isOpen={isOpen} closeModal={handleCloseModal}>
          <ModalContent padding='3rem'>
            {step === 1 ? (
              <>
                <ModalHead>
                  <SessionHeading>Select services</SessionHeading>
                  <SessionParagraph1>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque in etiam
                    tempor amet sit sit gravida elit. Imperdiet eget euismod sit
                    tempor. Enim consectetur senectus placerat aliquet varius
                    commodo faucibus. Ac accumsa.
                  </SessionParagraph1>
                </ModalHead>
                <SliderConatiner>
                  <ReactSlider settings={multipleSliderSettings}>
                    {multipleSlides.map((slide) => {
                      return (
                        <div key={slide.id}>
                          <SessionCard
                            slide={slide}
                            handleDepartmentSelection={
                              handleDepartmentSelection
                            }
                          />
                        </div>
                      );
                    })}
                  </ReactSlider>
                </SliderConatiner>
              </>
            ) : (
              <>
                <ModalHead>
                  <SessionHeading>Select clinic</SessionHeading>
                  <SessionParagraph1>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque in etiam
                    tempor amet sit sit gravida elit. Imperdiet eget euismod sit
                    tempor. Enim consectetur senectus placerat aliquet varius
                    commodo faucibus. Ac accumsa.
                  </SessionParagraph1>
                </ModalHead>
                <SliderConatiner>
                  <ReactSlider settings={clinicalsliderSettings}>
                    {clinicalSlide.map((slide) => {
                      return (
                        <div key={slide.id}>
                          <ClinicCard
                            onClickHandler={handleCloseModal}
                            slide={slide}
                          />
                        </div>
                      );
                    })}
                  </ReactSlider>
                </SliderConatiner>
              </>
            )}
          </ModalContent>
        </Modal>
      </SessionContainer>
    );
  return <></>;
}

export default ClinicAppointment;

import React, { useState , useEffect} from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import
 {
  BlogPostStyles,
  StyledPostPage,
  CommentForm,
  SocialMediaContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionForm,
  SubscriptionTitle
 }
from "./PostElement";
import HealthKoBlogs7 from "../../images/blogsimage/HealthKoBlogs7.jpg";


const PostPage7 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
    saveDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: val
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData); // For demonstration purposes
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`, "_blank");
  };
 
  useEffect(() => {
    document.title = 'Sunburn Causes and Symptoms: Prevention and Treatment Tips';

    const setMetaTag = (name, content) => {
      let tag = document.querySelector(`meta[name="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };
  
    const setLinkTag = (rel, href) => {
      let tag = document.querySelector(`link[rel="${rel}"]`);
      if (!tag) {
        tag = document.createElement('link');
        tag.setAttribute('rel', rel);
        document.head.appendChild(tag);
      }
      tag.setAttribute('href', href);
    };
  
    setLinkTag('blogs', 'https://www.healthko.in/Sunburn-Causes-Symptoms-Prevention-and-Treatment');

    setMetaTag('description', ' Discover the causes and symptoms of sunburn, along with prevention strategies and treatment options. Learn how to protect your skin and manage sunburn effectively.');

  }, []);

  return (
    <div style={{ display: "flex" }}>
    <StyledPostPage>
    <SocialMediaContainer>
          <FaFacebook size={30} onClick={shareOnFacebook} />
          <FaTwitter size={30} onClick={shareOnTwitter} />
          <FaLinkedin size={30} onClick={shareOnLinkedIn} />
        </SocialMediaContainer>
   
      <BlogPostStyles>
        <h1>Sunburn: Causes, Symptom, Prevention and Treatments</h1>
        <img src={HealthKoBlogs7} alt="Sunburn: Causes, Symptoms and Prevention and Treatments" />
        
        <p>Sunburn is a skin reaction that occurs after excessive exposure to the skin with ultraviolet (UV) radiation, commonly through sunlight. This may result in different reactions being experienced from inflammation to forming of blisters on the skin. Namely, knowledge of the risk factors and signs of sunburn is vital in order to prevent it and to treat it properly.</p>

        <h1>Causes of Sunburn</h1>
        <h2>Ultraviolet (UV) Radiation</h2>
        <p>UV rays, which include <a href="https://pubmed.ncbi.nlm.nih.gov/10907526/"target="_blank" rel="noopener noreferrer">UVA and UVB radiation</a>, are known to be the main reasons behind sunburn. UVB rays are stronger and cause sunburn, while UVA light rays are able to penetrate deeper into the skin resulting in premature ageing and skin cancer. Besides, when the skin is exposed to excessive UV radiation, the skin begins to inflame and lead to something we commonly call sunburn.</p>
        
        <h2>Individual Factors</h2>
        <p>Some reasons can make a person more susceptible to getting sunburn mainly due to individual traits. Blondes, red-heads and Caucasians with lighter eyes and lighter skin are most vulnerable because <a href="https://en.wikipedia.org/wiki/Melanin"target="_blank" rel="noopener noreferrer">melanin</a> – the skin’s natural shield against the ultraviolet rays is low. Further, the use of certain drugs including antibiotics and <a href="https://www.ncbi.nlm.nih.gov/books/NBK538188/#:~:text=Antihistamines%20are%20a%20pharmaceutical%20class,treat%20allergies%20and%20allergic%20rhinitis."target="_blank" rel="noopener noreferrer">antihistamines</a> can lead to increased sun reactivity.</p>

        <h2>Environmental Factors</h2>
        <p>Staying outside for long also increases the chances of getting sunburned and this partially depends on the environment. Standing in the sun for a very long time and during hours when UV rays are most intense between 10 in the morning and 4 in the afternoon is dangerous. Easily mirrored surfaces such as water, sand or even snow can also increase the chances of <a href="https://www.who.int/health-topics/ultraviolet-radiation#tab=tab_1"target="_blank" rel="noopener noreferrer">UV radiations</a> thus increasing the risks of sunburn.</p>
       
       <h1>Symptoms of Sunburn</h1>
       <h2>Immediate Symptoms</h2>
       <p>Sunburn symptoms typically appear within a few hours of exposure and can include:</p>
        <p>● Redness and warmth of the skin</p>
        <p>● Pain and tenderness</p>
        <p>● Swelling</p>
        <p>● Itching</p>
    
        <h2>Delayed Symptoms</h2>
        <p>Some symptoms may not appear until a few days after the initial exposure, such as:</p>
        <p>● <a href="https://dictionary.cambridge.org/dictionary/english/blistering"target="_blank" rel="noopener noreferrer">Blistering</a></p>
        <p>● Peeling of the skin</p>
        <p>● Severe pain</p>
        <p>● Nausea and fever (in severe cases)</p>
        <p>● Dehydration</p>

        <h1>Prevention and Treatment</h1>
        <h2>Preventive Measures</h2>
        <p>Prolonged exposure also takes its toll on the skin and thus the best policy is prevention of sunburn.</p>
        <p>● Use Sunscreen: Put on water-resistant sunscreen with an SPF of 30 or higher and again at least every two hours and if you swim or perspire..</p>
        <p>● Wear Protective Clothing: Carry and use hats, sunglasses and long sleeve clothing to avoid exposing skin areas.</p>
        <p>● Seek Shade: This means staying out of direct sunlight particularly between 10am and 3pm when rays are strongest.</p>
        <p>● Stay Hydrated: A lot of water should be consumed to prevent dehydration and to maintain the skin in healthy condition.</p>


        <h2>Immediate Treatment</h2>
        <p>If you do get sunburned, here are some immediate treatment steps:</p>
        <p>● Cool the Skin: Take a bath with water that is cool or use cool compressions on the affected skin.</p>
        <p>● Hydrate: Take loads of water and other fluids to avoid getting dehydrated during the exercises.</p>
        <p>● Moisturise: There are creams produced from aloe vera or gentle moisturiser which can be applied on the affected areas.</p>
        <p>● Avoid Further Sun Exposure: Avoid going out in the sun as long as your skin has not healed completely.</p>
        <p>● Pain Relief: Paracetamol may be used to alleviate pain and inflammation if they are present caused by a blood clot in veins or arteries.</p>
      

        <h2>When to Seek Medical Attention</h2>
        <p>It is possible to treat minor sunburns on your own, but in some instances it is better to seek professional help.</p>
        <p>● If you experience peeling with large areas of blisters.</p>
        <p>● Fever, chills, or confusion</p>
        <p>● Symptoms, which signal the infection like, pain, redness, yellowish discharge, or red lines radiating from the sores.</p>
        <p>● Persistent pain and swelling</p>
     

        <h1>Conclusion,</h1>
        <p>It is important to know the signs and causes of sunburn to look for to prevent them and to take control of them if they occur. As a result, through proper preventive measures, you can shield your skin from dangerous UV rays and reduce the possibility of burns appearing on your skin. In case you suffer from a severe sunburn which manifests itself by displaying the above symptoms, you should <a href="https://www.healthko.in/Online-Doctor-Consultation"target="_blank" rel="noopener noreferrer"> Consult a doctor</a> for a proper treatment.</p>
       
      </BlogPostStyles>

      <CommentForm onSubmit={handleSubmit}>
        <h3>Leave a Comment</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <input
          type="url"
          name="website"
          value={formData.website}
          placeholder="Your Website (optional)"
          onChange={handleChange}
        />
        <textarea
          name="comment"
          value={formData.comment}
          placeholder="Your Comment"
          onChange={handleChange}
          style={{ height: "150px" }}
          required
        />
        <button type="submit">Post Comment</button>
      </CommentForm>
    </StyledPostPage>
    <SubscriptionContainer>
        <SubscriptionContent>
          <SubscriptionTitle>Love Reading Our Blogs</SubscriptionTitle>
          <p>Stay up-to-date with our latest blog posts.</p>
          <SubscriptionForm>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Enter your email address"
              onChange={handleChange}
              required
            />
            <button type="submit">Subscribe</button>
          </SubscriptionForm>
        </SubscriptionContent>
      </SubscriptionContainer>
</div>
  );
};



export default PostPage7;

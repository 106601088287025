import styled from 'styled-components';
import { PrimaryBtn } from '../commonComponents';

export const AuthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const Tabs = styled.div`
  color: ${(props) => props.theme.colors.heading};
  cursor: pointer;
  border-bottom: 3px solid;
  border-color: ${(props) =>
    props.$active ? props.theme.colors.primary : 'transparent'};
  padding: 1rem;
  padding-bottom: 8px;
  transition: border 0.3s ease-in;
  width: 120px;
  max-width: 100%;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 1rem;
  padding-bottom: 0;

  @media screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  font-size: 16px;

  @media screen and (max-width: 900px) {
    padding: 1rem;
    padding-bottom: 2rem;
  }
`;

export const Label = styled.label`
  color: #5b5b5b;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: .5rem 0;
`;

export const Terms = styled.label`
  color: #2e2e2e;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: .5rem 0;
`;

export const Input = styled.input`
  padding: 1rem;
  border-radius: 12px;
  background: #fff;
  width: 468px;
  max-width: 100%;
  height: 50px;
  flex-shrink: 0;
  font-size: 16px;
  outline-color: none;
  border: 1px solid #8d8d8d;

  &:hover {
    outline-color: ${(props) => props.theme.colors.primary};
  }

  &:focus {
    outline-color: ${(props) => props.theme.colors.primary};
  }

  @media screen and (max-width: 900px) {
    padding: 0.5rem 1rem;
    height: 50px;
    max-width: 85vw;
  }

  @media screen and (max-width: 400px) {
    padding: 0.5rem 1rem;
    height: 50px;
    max-width: 85vw;
  }
`;

export const CheckBox = styled.input`
  border-radius: 16px;
  width: 20px;
  height: 20px;
  background: #fff;
`;

export const TermsSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding-top: 0.5rem;
`;

export const CustomPrimaryBtn = styled(PrimaryBtn)`
  font-size: 18px;
  padding: 1rem 2rem;
  height: 50px;
  margin-top: 2rem;

  &:hover {
    box-shadow: 0px 0px 20px rgb(70, 156, 246); /* Change to the color you want on hover */
  }

  @media screen and (max-width: 900px) {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 400px) {
    margin-top: 0rem;
    padding: 1rem;
    height: 45px;
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  @media screen and (max-width: 900px) {
    padding: 1rem;
    width: 60%;
  }

  @media screen and (max-width: 400px) {
    padding-top: 1rem;
    width: 40%;
  }
`;
